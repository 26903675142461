import { Component } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { NotificationService } from '../../_services/notification.service';
import { SupportLimparTelaVendedorService } from './limpar-tela-vendedor.service';

import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { Globals } from '../../_models/globals';
import { SupportGridview, SupportGridviewTableBodyCell } from '../_models/supportGridview';
import { SupportMaintenanceGetCleanSellerScreenResponseModel } from './_models/supportMaintenanceGetCleanSellerScreenResponseModel';

@Component({ selector: 'app-limpar-tela-vendedor', templateUrl: './limpar-tela-vendedor.component.html', styleUrls: ['./limpar-tela-vendedor.component.scss'] })
export class SupportLimparTelaVendedorComponent {
    public sellerId: number;

    public config: SupportGridview = new SupportGridview();

    constructor(
        private notificationService: NotificationService,
        private limparTelaVendedorService: SupportLimparTelaVendedorService,
        private globals: Globals,
        private ngxLoader: NgxUiLoaderService) {
            this.config.set({
                actions: {
                    edit: { enable: false },
                    remove: { confirmationText: 'support.manutencao.limpar-tela-vendedor.mensagens.confirm.limpar-tela', disabledCondition: (item) => item.totalCotacoes <= 0, enable: true, labelText: 'support.manutencao.limpar-tela-vendedor.botao-limpar', type: 'button' }
                },
                emptyText: { isHtml: false, value: 'support.manutencao.limpar-tela-vendedor.busque-vendedor' },
                list: [],
                table: {
                    body: [
                        new SupportGridviewTableBodyCell('col text-center', 'sellerId'),
                        new SupportGridviewTableBodyCell('col-2 truncate-gridview', 'nome'),
                        new SupportGridviewTableBodyCell('col-2 truncate-gridview', 'fornecedor'),
                        new SupportGridviewTableBodyCell('col text-center', 'totalCotacoes')
                    ],
                    header: [
                        { className: 'col text-center', value: 'support.manutencao.limpar-tela-vendedor.tabela.seller-id' },
                        { className: 'col-2', value: 'support.manutencao.limpar-tela-vendedor.tabela.nome' },
                        { className: 'col-2', value: 'support.manutencao.limpar-tela-vendedor.tabela.fornecedor' },
                        { className: 'col text-center', value: 'support.manutencao.limpar-tela-vendedor.tabela.total-cotacoes' },
                        { className: 'col-0-5 text-center', value: '' }
                    ]
                }
            });
        }

    private cleanPage(nAttempts: number) {
        this.ngxLoader.startLoader('loader-principal');

        this.limparTelaVendedorService.clearQuotationsFromSellersDashboard(this.sellerId).subscribe({
            next: () => {
                this.getSeller(this.sellerId, 0);

                this.notificationService.showSuccessToastr(this.globals.translate('support.manutencao.limpar-tela-vendedor.mensagens.sucesso.limpar-tela.corpo'));
                this.ngxLoader.stopLoader('loader-principal');
            },
            error: (error) => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.ngxLoader.stopLoader('loader-principal');
                    this.notificationService.showErrorToastr(this.globals.translate('support.manutencao.limpar-tela-vendedor.mensagens.erro.limpar-tela.corpo'));
                    return;
                }

                this.cleanPage(++nAttempts);
            }
        });
    }

    getSeller(sellerId: any, nAttempts: number) {
        this.sellerId = sellerId;

        this.ngxLoader.startLoader('loader-principal');
        this.config.emptyText.value = 'support.manutencao.limpar-tela-vendedor.nenhum-vendedor-encontrado';

        this.limparTelaVendedorService.getSellerClearPageData(sellerId).subscribe({
            next: (response: ApiResponseModel<SupportMaintenanceGetCleanSellerScreenResponseModel>) => {
                this.config.list = [];

                if (response != null && response.success)
                    this.config.list.push(response.result.seller);

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.ngxLoader.stopLoader('loader-principal');
                    this.notificationService.showErrorToastr(this.globals.translate('support.manutencao.limpar-tela-vendedor.mensagens.erro.carregamento.corpo'));
                    return;
                }

                this.getSeller(sellerId, ++nAttempts);
            }
        });
    }

    onCleanPage($event: any) {
        console.log($event);

        this.cleanPage(0);
    }
}
