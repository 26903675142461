import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { NotificationService } from 'app/_services/notification.service';
import { SupportGridview, SupportGridviewTemplateCell } from 'app/support/_models/supportGridview';

declare const sort_by: any;

@Component({ selector: 'app-support-gridview', templateUrl: './gridview.component.html', styleUrls: ['./gridview.component.scss'] })
export class SupportGridviewComponent {
    @Input() config: SupportGridview = new SupportGridview();
    @Input() templates: SupportGridviewTemplateCell[] = [];
    @Output() onEdit = new EventEmitter();
    @Output() onRemove = new EventEmitter();
    @Output() onSort = new EventEmitter();

    constructor(private notificationService: NotificationService) { }

    async edit(item: any) {
        let confirm = true;

        if (this.config.actions.edit.confirmationText)
            confirm = await this.notificationService.showConfirmationDialog(this.config.actions.edit.confirmationText);

        if (confirm)
            this.onEdit.emit(item);
    }

    async remove(index: number, item: any) {
        if (!this.config.actions.remove.enable)
            return;

        const confirm = await this.notificationService.showConfirmationDialog(this.config.actions.remove.confirmationText);

        if (confirm)
            this.onRemove.emit({ index, item });
    }

    getTemplate(templateName: string): TemplateRef<any> {
        const templateObj = this.templates.find(t => t.name === templateName);
        return templateObj?.template;
    }

    sortTableBy(column: string) {
        if (!this.config.sort.enabled)
            return;

        if (this.config.sort.sortedBy === column)
            this.config.sort.isReverse = !this.config.sort.isReverse;
        else {
            this.config.sort.sortedBy = column;
            this.config.sort.isReverse = false;
        }

        this.config.list.sort(sort_by([{ name: column, reverse: this.config.sort.isReverse }]));

        this.onSort.emit({ enabled: true, sortedBy: column, isReverse: this.config.sort.isReverse });
    }
}