import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { NgxUiLoaderService } from 'ngx-ui-loader';

import { GeneralService } from 'app/_services/general.service';
import { NotificationService } from 'app/_services/notification.service';
import { SupportPedidosService } from './pedidos.service';

import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { Globals } from 'app/_models/globals';
import { SupportAdminGetCarregarPedidosResponseModel } from './_models/supportAdminGetCarregarPedidosResponseModel';
import { WhatsAppMessage } from 'app/_models/whatsAppMessage';
import { FilterOption } from 'app/_componentes/header/_models/filterOption';

declare const isEmpty: any;
declare const sort_by: any;

@Component({ selector: 'app-pedidos', templateUrl: './pedidos.component.html', styleUrls: ['./pedidos.component.scss'] })
export class SupportPedidosComponent implements OnInit {
    active: number;
    atrasados: any;
    pendentes: any;
    _perfil: number;

    loading_ctr: number;
    loading: Subject<boolean> = new Subject();

    orderByFieldAtrasado: string;
    orderByFieldPendente: string;
    reverseSortAtrasado: boolean;
    reverseSortPendente: boolean;

    filterOptions: FilterOption[] = [];

    constructor(
        private generalService: GeneralService,
        private notificationService: NotificationService,
        private pedidosService: SupportPedidosService,
        private globals: Globals,
        private ngxLoader: NgxUiLoaderService) { }

    ngOnInit(): void {
        this.perfil = 3;
        this.active = 1;
        this.atrasados = [];
        this.pendentes = [];

        this.reverseSortAtrasado = true;
        this.reverseSortPendente = true;
        this.orderByFieldAtrasado = 'data_pedido';
        this.orderByFieldPendente = 'data_pedido';

        this.carregarTela();

        this.filterOptions = [
            { value: '3', label: this.globals.translate('support.perfil.central') },
            { value: '2', label: this.globals.translate('support.perfil.v8') },
            { value: '1', label: this.globals.translate('support.perfil.popular') }
        ]
    }

    get perfil(): number {
        return this._perfil;
    }

    set perfil(value: number) {
        this._perfil = value;
        this.carregarGeral(0);
    }

    atualizar() {
        this.carregarTela();
    }

    carregarGeral(nAttempts: number) {
        this.pedidosService.getOrdersReport(this.perfil).subscribe({
            next: (response: ApiResponseModel<SupportAdminGetCarregarPedidosResponseModel>) => {
                if (response && response.success) {
                    this.pendentes = response.result.pedidoSuporteResult.pendentes;
                    this.atrasados = response.result.pedidoSuporteResult.atrasados;
                    this.ngxLoader.stopLoader('loader-principal');
                }
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.ngxLoader.stopLoader('loader-principal');
                    this.notificationService.showErrorToastr(this.globals.translate('support.pedidos.mensagens.erro.carregamento.corpo'));
                    return;
                }

                this.carregarGeral(++nAttempts);
            }
        });
    }

    carregarTela() {
        this.ngxLoader.startLoader('loader-principal');
        this.carregarGeral(0);
    }

    ordenar(coluna, direcao, pendente) {
        if (pendente) {
            this.reverseSortPendente = (this.orderByFieldPendente === coluna) ? !this.reverseSortPendente : direcao;
            this.orderByFieldPendente = coluna;
            this.sortList();
        }
        else {
            this.reverseSortAtrasado = (this.orderByFieldAtrasado === coluna) ? !this.reverseSortAtrasado : direcao;
            this.orderByFieldAtrasado = coluna;
            this.sortList();
        }
    }

    sortList() {
        if (this.active == 1) {
            if (this.pendentes === null || this.pendentes === undefined)
                return;

            this.pendentes.sort(sort_by([{ name: this.orderByFieldPendente, reverse: this.reverseSortPendente }]));
        } else if (this.active == 2) {
            if (this.atrasados === null || this.atrasados === undefined)
                return;

            this.atrasados.sort(sort_by([{ name: this.orderByFieldAtrasado, reverse: this.reverseSortAtrasado }]));
        }
    }

    whatsapp_enviar(pedido) {
        let urlPartsfy = this.generalService.getPartsfyUrl();
        let urlCotacao = urlPartsfy + '/vendedor/visao-geral/confirmar-pedido/' + pedido.quotation_id;
        let oficina = pedido.repairer_name;
        let veiculo = pedido.brand + ' - ' + pedido.veiculo;
        let whatsapp = pedido.seller_whatsapp;
        let vendedor_email = pedido.seller_email;
        let vendedor_id = pedido.seller_id;
        let status = pedido.status_id;

        let whatsapp_message: WhatsAppMessage = { is_from_support: true, chassi: '', oficina: oficina, urlPartsfy: urlPartsfy, urlCTA: urlCotacao, veiculo: veiculo, vendedor_whatsapp: whatsapp, vendedor_email, vendedor_id, min_status: status }

        this.generalService.whatsapp_enviar_aguardando_confirmacao(whatsapp_message, 0);
    }
}
