import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { NotificationService } from '../../_services/notification.service';
import { SupportOficinaSemDocumentacaoService } from './oficina-sem-documentacao.service';

import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { Globals } from '../../_models/globals';
import { OficinaSemDocumentacao } from './_models/oficinaSemDocumentacao';
import { SupportGridview, SupportGridviewTableBodyCell } from '../_models/supportGridview';
import { SupportMaintenancesGetHoldingsWithoutDocumentationResponseModel } from './_models/supportMaintenancesGetHoldingsWithoutDocumentationResponseModel';

declare const compararStringContemString: any;
declare const getOnlyNumbers: any;
declare const isEmpty: any;

@Component({ selector: 'app-oficina-sem-documentacao', templateUrl: './oficina-sem-documentacao.component.html', styleUrls: ['./oficina-sem-documentacao.component.scss'] })
export class SupportOficinaSemDocumentacaoComponent implements OnInit {
    public oficinas: OficinaSemDocumentacao[] = [];
    public filter: string = '';

    public config: SupportGridview = new SupportGridview();

    constructor(private notificationService: NotificationService,
        private oficinaSemDocumentacaoService: SupportOficinaSemDocumentacaoService,
        private globals: Globals,
        private ngxLoader: NgxUiLoaderService) {
            this.config.set({
                actions: {
                    edit: { enable: false },
                    remove: { enable: false }
                },
                emptyText: { isHtml: false, value: 'support.manutencao.oficina-sem-documentacao.vazio' },
                list: [],
                table: {
                    body: [
                        new SupportGridviewTableBodyCell('col text-center', 'holding_id'),
                        new SupportGridviewTableBodyCell('col text-center', 'company_id'),
                        new SupportGridviewTableBodyCell('col-2 truncate-gridview', 'oficina'),
                        new SupportGridviewTableBodyCell('col text-center', 'cnpj')
                    ],
                    header: [
                        { className: 'col text-center', value: 'support.manutencao.oficina-sem-documentacao.tabela.holding-id' },
                        { className: 'col text-center', value: 'support.manutencao.oficina-sem-documentacao.tabela.oficina-id' },
                        { className: 'col-2', value: 'support.manutencao.oficina-sem-documentacao.tabela.oficina' },
                        { className: 'col text-center', value: 'support.manutencao.oficina-sem-documentacao.tabela.cnpj' }
                    ]
                }
            });
        }

    ngOnInit(): void {
        this.getAll(0);
    }

    getAll(nAttempts: number) {
        this.ngxLoader.startLoader('loader-principal');

        this.oficinaSemDocumentacaoService.getHoldingsWithoutDocumentation().subscribe({
            next: (response: ApiResponseModel<SupportMaintenancesGetHoldingsWithoutDocumentationResponseModel>) => {
                if (response && response.success) {
                    this.oficinas = response.result.oficinasSemDocumentacao;
                    this.filtrarOficinas('');
                }

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.ngxLoader.stopLoader('loader-principal');
                    this.notificationService.showErrorToastr(this.globals.translate('support.manutencao.oficina-sem-documentacao.mensagens.erro.carregamento.corpo'));
                    return;
                }

                this.getAll(++nAttempts);
            }
        });
    }

    filtrarOficinas($event: any): void {
        if (isEmpty(this.oficinas))
            this.config.list = [];

        var term = $event.toLowerCase();

        const filtered = this.oficinas.filter(o =>
            compararStringContemString(o.oficina.toLowerCase(), term) ||
            compararStringContemString(getOnlyNumbers(o.cnpj), getOnlyNumbers(term), true) ||
            o.holding_id.toString().indexOf(term) >= 0 || o.company_id.toString().indexOf(term) >= 0);

        this.config.list = filtered;
    }
}
