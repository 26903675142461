import { Component, OnInit } from "@angular/core";

import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Globals } from '../../_models/globals';

import { NotificationService } from '../../_services/notification.service';
import { SupportMultiloginService } from "./multilogin.service";

import { ApiResponseModel } from "app/_models/apiResponseModel";
import { HoldingModel } from "./_models/holdingModel";
import { MultiloginUserData } from "./_models/multiloginUserModel";
import { SupportGridview, SupportGridviewTableBodyCell } from "../_models/supportGridview";
import { SupportMultiLoginGetHoldingByIdResponseModel } from "./_models/supportMultiLoginGetHoldingByIdResponseModel";
import { SupportMultiLoginGetHoldingPerTypeResponseModel } from "./_models/supportMultiLoginGetHoldingPerTypeResponseModel";
import { SupportMultiLoginGetUsersPerCompanyResponseModel } from "./_models/supportMultiLoginGetUsersPerCompanyResponseModel";
import { SupportMultiLoginGetUsersPerGroupResponseModel } from "./_models/supportMultiLoginGetUsersPerGroupResponseModel";
import { UserModel } from "./_models/userModel";

declare const $: any
declare const sort_by: any
declare const waitForElm: any

@Component({ selector: 'app-multilogin-suporte', templateUrl: './multilogin.component.html', styleUrls: ['./multilogin.component.scss'] })
export class SupportMultiloginComponent implements OnInit {
    selectedType: any;

    selectedHolding: {id: number, name: string};
    selectedCompany: {id: number, name: string};
    selectedUser: {id: number, name: string, user_group_id: string};

    originalState = [];

    companiesList = [];
    holdingsList: HoldingModel[] = [];
    loading: boolean = false;
    usersList: UserModel[] = [];
    isUserSelected = false;
    isUserWithMultiloginSelected = false;

    private _multiloginList: MultiloginUserData[] = [];

    get multiloginList(): MultiloginUserData[] {
        return this._multiloginList;
    }
    set multiloginList(value: MultiloginUserData[]) {
        this._multiloginList = value;
        this.afterChangeMultiloginList();
    }

    isSaveButtonDisabled = true;

    public config: SupportGridview = new SupportGridview();

    constructor(
        private multiloginService: SupportMultiloginService,
        private notificationService: NotificationService,
        private ngxLoader: NgxUiLoaderService,
        private globals: Globals) {
            this.config.set({
                actions: {
                    edit: { enable: false },
                    remove: { confirmationText: 'support.multilogin.confirmacao.remover', enable: true }
                },
                emptyText: { isHtml: true, value: 'support.multilogin.aviso.nenhum-selecionado' },
                list: [],
                table: {
                    body: [
                        new SupportGridviewTableBodyCell('col-2', 'holding'),
                        new SupportGridviewTableBodyCell('col-2', 'company'),
                        new SupportGridviewTableBodyCell('col-2', 'name')
                    ],
                    bodyClassName: 'multilogin',
                    header: [
                        { className: 'col-2', value: 'support.multilogin.labels.holding' },
                        { className: 'col-2', value: 'support.multilogin.labels.empresa' },
                        { className: 'col-2', value: 'support.multilogin.labels.usuario' },
                        { className: 'col-0-75', value: '' }
                    ]
                }
            });
        }

    ngOnInit(): void {
        this.handleDropdownConfiguration()
    }

    private addToMultiloginList(item: MultiloginUserData) {
        this._multiloginList.push(item);
        this.afterChangeMultiloginList();
    }

    private afterChangeMultiloginList() {
        this.orderSelectedList();

        this.config.list = this._multiloginList;

        this.showHideRemoveButton(this._multiloginList.length > 1);
    }

    public addUserToMultilogin() {
        if (this.selectedUser.user_group_id && !this.isUserWithMultiloginSelected) {
            this.ngxLoader.startLoader('loader-users-selected');

            this.multiloginService.getUsersByUserGroup(this.selectedUser.user_group_id).subscribe({
                next: (response: ApiResponseModel<SupportMultiLoginGetUsersPerGroupResponseModel>) => {
                    this.originalState = [];

                    response.result.users.forEach((user) => {
                        let userTemp: MultiloginUserData = {
                            holding: user.holdingName,
                            company: user.companyName,
                            name: user.name,
                            id: user.id,
                            userGroupId: user.userGroupId
                        };

                        if (!this.multiloginList.some((u) => u.id === user.id))
                            this.addToMultiloginList(userTemp);

                        this.originalState.push(userTemp);
                    });

                    this.isUserWithMultiloginSelected = true;
                    this.ngxLoader.stopLoader('loader-users-selected');

                },
                error: (error) => {
                    console.log(error);
                    this.ngxLoader.stopLoader('loader-users-selected');
                    this.notificationService.showErrorToastr(this.globals.translate('support.multilogin.mensagem.erro.adicionar-usuario'));
                }
            });
        } else {
            this.addToMultiloginList({
                holding: this.selectedHolding.name,
                company: this.selectedCompany.name,
                name: this.selectedUser.name,
                id: this.selectedUser.id
            });
        }

        this.isSaveButtonDisabled = false;
        this.resetUsers(false);
    }

    public async cleanSelection() {
        if (this.isDifferent(this.multiloginList, this.originalState) && !await this.notificationService.showConfirmationDialog('support.multilogin.confirmacao.limpar'))
            return;

        this.resetScreen();
    }

    public async deleteMultilogin() {
        if (!await this.notificationService.showConfirmationDialog('support.multilogin.confirmacao.apagar'))
            return;

        this.ngxLoader.startLoader('loader-users-selected');

        const userGroupId = this.multiloginList.map((u) => u.userGroupId)[0];

        this.multiloginService.removeUserGroup(userGroupId).subscribe({
            next: (response: ApiResponseModel<boolean>) => {
                if (response.result) {
                    this.resetScreen()
                    this.ngxLoader.stopLoader('loader-users-selected');
                    this.notificationService.showSuccessToastr(this.globals.translate('support.multilogin.mensagem.sucesso.apagar'));
                }
            },
            error: error => {
                console.log(error);
                this.ngxLoader.stopLoader('loader-users-selected');
                this.notificationService.showErrorToastr(this.globals.translate('support.multilogin.mensagem.erro.apagar'));
            }
        });
    }

    private handleDropdownConfiguration() {
        const global = this;
        this.handleSingleDropdownConfiguration('.search-type', 'support.multilogin.labels.tipo', (value) => global.selectType(value))
        this.handleSingleDropdownConfiguration('.search-holding', 'support.multilogin.labels.holding', (value) => global.selectHolding(value))
        this.handleSingleDropdownConfiguration('.search-company', 'support.multilogin.labels.empresa', (value) => global.selectCompany(value))
        this.handleSingleDropdownConfiguration('.search-user', 'support.multilogin.labels.usuario', (value) => global.selectUser(value))
    }

    private handleSingleDropdownConfiguration(className: string, translateName: string, callback: any) {
        waitForElm(className).then(() => {
            $(className).select2({
                language: "pt-BR",
                placeholder:  this.globals.translate(translateName)
            });

            $(className).off('select2:select');
            $(className).on('select2:select', function () { callback($(className).val()); });
            $(document).on('select2:open', () => { $('.select2-search__field').focus().select(); });
        });
    }

    private isDifferent(array1, array2) {
        return array1.length != array2.length || array1.filter((x) => !array2.includes(x)).length > 0;
    }

    public isDisabled(user) {
        return this.multiloginList.some((u) => u.id === user.id) || (this.isUserWithMultiloginSelected && user.user_group_id && !this.originalState.some((u) => u.id === user.id));
    }

    private orderSelectedList() {
        this._multiloginList.sort(sort_by([{ name: 'holding' }, { name: 'company' }, { name: 'name' }]));
    }

    public progressWillBeLost() {
        if (this.multiloginList.length === 0)
            return false;

        if (this.isDifferent(this.multiloginList, this.originalState))
            return true;
    }

    private resetCompanies() {
        this.companiesList = [];
        this.resetUsers();
    }

    public removeUserFromList($event: any) {
        const index = $event.index;
        this._multiloginList.splice(index, 1);
        this.isUserWithMultiloginSelected = this.multiloginList.some((u) => u.userGroupId);
        
        this.afterChangeMultiloginList();
    }

    private resetHoldings() {
        this.holdingsList = [];
        this.selectedHolding = { id: null, name: null };
        this.resetCompanies();
    }

    private resetScreen() {
        this.resetHoldings();
        $('.search-type').val(null).trigger('change');
        this.multiloginList = [];
        this.originalState = [];
        this.isSaveButtonDisabled = true;
        this.isUserWithMultiloginSelected = false;
    }

    private resetUsers(shouldResetUserList = true) {
        shouldResetUserList && (this.usersList = [])

        this.selectedUser = { id: null, name: null, user_group_id: null };
        $('.search-user').val(null).trigger('change');
        this.isUserSelected = false;
    }

    public saveMultilogin() {
        this.ngxLoader.startLoader('loader-users-selected');

        const userGroupId = this.multiloginList.filter(u => u.userGroupId)[0]?.userGroupId;
        const usersIds = this.multiloginList.map((user) => user.id);

        this.multiloginService.saveMultilogin(userGroupId, usersIds).subscribe({
            next: () => {
                this.resetScreen()
                this.ngxLoader.stopLoader('loader-users-selected');
                this.notificationService.showSuccessToastr(this.globals.translate('support.multilogin.mensagem.sucesso.salvar'));
            },
            error: (error) => {
                console.log(error)
                this.ngxLoader.stopLoader('loader-users-selected');
                this.notificationService.showErrorToastr(this.globals.translate('support.multilogin.mensagem.erro.salvar'));
            }
        })
    }

    public selectType(type) {
        this.loading = true;

        this.selectedType = type;

        this.multiloginService.getHoldingsByType(type == "oficina").subscribe({
            next: (response: ApiResponseModel<SupportMultiLoginGetHoldingPerTypeResponseModel>) => {
                this.resetHoldings();
                this.holdingsList = response.result.holdings;
                this.loading = false;
            },
            error: (error) => {
                console.log(error);
                this.loading = false;
                this.notificationService.showErrorToastr(this.globals.translate('support.multilogin.mensagem.erro.buscar.holding'));
            }
        })
    }

    public selectHolding(holdingId) {
        this.loading = true;

        this.multiloginService.getCompaniesByHolding(holdingId).subscribe({
            next: (response: ApiResponseModel<SupportMultiLoginGetHoldingByIdResponseModel>) => {
                this.selectedHolding = this.holdingsList.find((holding) => holding.id == holdingId);
                this.resetCompanies();
                this.companiesList = response.result.companies;
                this.loading = false;
            },
            error: (error) => {
                console.log(error);
                this.loading = false;
                this.notificationService.showErrorToastr(this.globals.translate('support.multilogin.mensagem.erro.buscar.empresa'));
            }
        });
    }

    public selectCompany(companyId) {
        this.loading = true;

        this.selectedCompany = this.companiesList.find((company) => company.id == companyId);

        this.multiloginService.getUsersByCompany(companyId).subscribe({
            next: (response: ApiResponseModel<SupportMultiLoginGetUsersPerCompanyResponseModel>) => {
                this.resetUsers();
                this.usersList = response.result.users;
                this.loading = false;
            },
            error: (error) => {
                console.log(error);
                this.loading = false;
                this.notificationService.showErrorToastr(this.globals.translate('support.multilogin.mensagem.erro.buscar.usuario'));
            }
        });
    }

    public selectUser(userId) {
        this.selectedUser = this.usersList.find((user) => user.id == userId);
        this.isUserSelected = true;
    }

    public showHideRemoveButton(show: boolean) {
        waitForElm('.gridview .remove').then(() => {
            if (show)
                $('.gridview .remove').show();
            else
                $('.gridview .remove').hide();
        });
    }
}
