import { Component, OnInit, ViewChild } from '@angular/core';

import { NgxUiLoaderService } from 'ngx-ui-loader';

import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { Globals } from 'app/_models/globals';
import { Holding } from './_models/holding';
import { SupportManageSellersGetHoldingsResponseModel } from './_models/supportManageSellersGetHoldingsResponseModel';

import { SupportGerenciarVendedoresService } from './gerenciar-vendedores.service';
import { SupportGerenciarVendedoresInternoComponent } from '../_componentes/gerenciar-vendedores/gerenciar-vendedores-interno.component';
import { NotificationService } from 'app/_services/notification.service';

@Component({ selector: 'app-gerenciar-vendedores', templateUrl: './gerenciar-vendedores.component.html', styleUrls: ['./gerenciar-vendedores.component.scss'] })
export class SupportGerenciarVendedoresComponent implements OnInit {
    @ViewChild(SupportGerenciarVendedoresInternoComponent) interno: SupportGerenciarVendedoresInternoComponent;

    holdingSelecionadaId: any;
    holdingSelecionada: any;
    holdings: Holding[];

    keyword = 'name';
    notFoundText = '';
    placeHolder = '';

    constructor(
        private notificationService: NotificationService,
        private supportGerenciarVendedoresService: SupportGerenciarVendedoresService,
        private globals: Globals,
        private ngxLoader: NgxUiLoaderService)
    { }

    ngOnInit(): void {
        this.holdingSelecionada = {};
        this.holdingSelecionadaId = '';
        this.holdings = [];

        this.carregarTela();
        this.notFoundText = this.globals.translate('support.gerenciar-vendedores.notFound');
        this.placeHolder = this.globals.translate('support.gerenciar-vendedores.selecione_fornecedor');
    }

    getAll(nAttempts: number) {
        this.supportGerenciarVendedoresService.getAllHoldings().subscribe({
            next: (response: ApiResponseModel<SupportManageSellersGetHoldingsResponseModel>) => {
                if (response && response.success) {
                    this.holdings = response.result.holdings;
                    this.ngxLoader.stopLoader('loader-principal');
                }
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.ngxLoader.stopLoader('loader-principal');
                    this.notificationService.showErrorToastr(this.globals.translate('support.gerenciar-vendedores.mensagens.erro.carregamento.corpo'));
                    return;
                }

                this.getAll(++nAttempts);
            }
        });
    }

    carregarTela() {
        this.ngxLoader.startLoader('loader-principal');
        this.getAll(0);
    }

    exportarCSV() {
        this.interno.exportarCSV();
    }

    holdingsSelectEvent(item) {
        this.holdingSelecionada = item;
        this.holdingSelecionadaId = item.id;
        this.listarEmpresasAssociadas(0);
    }

    listarEmpresasAssociadas(holding_id: number) {
        let is_billable = this.holdingSelecionada.status_pagamento <= 2;
        this.interno.listarEmpresasAssociadas(holding_id, is_billable);
    }
}
