<div class="gridview">
    @if (!config.list || config.list.length == 0 || !config.table) {
        <div class="text-center vazio">
            @if (!config.emptyText.isHtml) {
                <p class="mb0">{{config.emptyText.value | translate}}</p>
            } @else {
                <p [innerHTML]="config.emptyText.value | translate"></p>
            }
        </div>
    } @else {
        <section class="d-flex flex-wrap striped hover">
            <header>
                @for (col of config.table.header; track col) {
                    @if (config.sort.enabled && col.sortName) {
                        <div (click)="sortTableBy(col.sortName)" [ngClass]="col.className" class="cursor-pointer">
                            {{ col.value | translate}}
                            @if (config.sort.sortedBy === col.sortName) {
                                <span><em class="fa fa-caret-{{config.sort.isReverse ? 'down' : 'up'}}"></em></span>
                            }
                        </div>
                    } @else {
                        <div [ngClass]="col.className">
                            {{ col.value | translate}}
                        </div>
                    }
                }
            </header>
            <ng-scrollbar class="corpo" [ngClass]="config.table.bodyClassName">
                @for (item of config.list; track item; let itemIndex = $index) {
                    <div class="linha" >
                        @for (col of config.table.body; track col; let i = $index) {
                            <div class="col" [ngClass]="col.className">
                                @if (col.type === 'string') {
                                    <span>{{ item[col.value] }}</span>
                                }
                                @if (col.type === 'date') {
                                    <span>{{ item[col.value] | date:col.format }}</span>
                                }
                                @if (col.type === 'number') {
                                    <span>{{ item[col.value] | number:col.format }}</span>
                                }
                                @if (col.type === 'html' && item) {
                                    <ng-container *ngTemplateOutlet="getTemplate(col.templateName); context: {$implicit: item}"></ng-container>
                                }
                                @if (col.type === 'boolean' && item[col.value]) {
                                    <i-feather name="check"></i-feather>
                                }
                                @if (col.type === 'boolean' && !item[col.value]) {
                                    <i-feather name="x"></i-feather>
                                }
                            </div>
                        }
                        @if (config.actions.edit.enable || config.actions.remove.enable) {
                            <div class="col col-0-5 icons">
                                @if (config.actions.edit.enable && config.actions.edit.type === 'icon') {
                                    <i class="fa fa-1x fa-edit cursor-pointer mt-1" tooltip="{{'support.cotacoes.vendedores.atualizar.tooltip-perfil' | translate}}" (click)="edit(item)" [attr.disabled]="config.actions.edit.disabledCondition?.(item)"></i>
                                }
                                @if (config.actions.remove.enable && config.actions.remove.type === 'icon') {
                                    <i class="fa fa-1x fa-trash cursor-pointer" tooltip="{{'support.cotacoes.vendedores.atualizar.tooltip-excluir' | translate}}" (click)="remove(itemIndex, item)" [attr.disabled]="config.actions.remove.disabledCondition?.(item)"></i>
                                }
                                @if (config.actions.edit.enable && config.actions.edit.type === 'button') {
                                    <button type="button" class="btn btn-primary btn-sm" [disabled]="config.actions.edit.disabledCondition?.(item)" (click)="edit(item)">{{ (config.actions.edit.labelText ?? 'elemento.generico-botao.editar') | translate}}</button>
                                }
                                @if (config.actions.remove.enable && config.actions.remove.type === 'button') {
                                    <button type="button" class="btn btn-light btn-sm" [disabled]="config.actions.remove.disabledCondition?.(item)" (click)="remove(itemIndex, item)">{{ (config.actions.remove.labelText ?? 'elemento.generico-botao.remover') | translate}}</button>
                                }
                            </div>
                        }
                    </div>
                }
            </ng-scrollbar>
        </section>
    }
</div>