import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Globals } from '../../_models/globals';
import { NotificationService } from '../../_services/notification.service';
import { CadastroCNPJService } from './cadastro-cnpj.service';
import { ManageCompanyCnpj } from './_models/company-model';
import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { SupportCadastroCnpjGetAllResponseModel } from './_models/supportCadastroCnpjGetAllResponseModel';

declare const compararStringContemString: any;
declare const isEmpty: any;
declare const getOnlyNumbers: any;

@Component({ selector: 'app-cadastro-cnpj', templateUrl: './cadastro-cnpj.component.html', styleUrls: ['./cadastro-cnpj.component.scss'] })
export class SupportCadastroCnpjComponent implements OnInit {
    is_listagem: boolean = true;
    manageCompanyCnpjSelecionado: ManageCompanyCnpj;
    companiesCnpjList: ManageCompanyCnpj[] = [];
    companiesCnpjListFiltered: ManageCompanyCnpj[] = [];

    public filter: string = '';

    constructor(private notificationService: NotificationService, private cadastroCnpjService: CadastroCNPJService, private globals: Globals, private route: ActivatedRoute, private ngxLoader: NgxUiLoaderService,
        private router: Router) { }

    ngOnInit(): void {
        var param_id = this.route.snapshot.params.id;
        this.is_listagem = true;
        this.manageCompanyCnpjSelecionado = null;
        this.companiesCnpjList = [];

        this.carregarTela(0, param_id);
    }

    acao_alterarTela(param) {
        this.selecionarCompanyCnpj(param.company_id);
        this.is_listagem = param.is_listagem;

        if (isEmpty(param.company_id))
        {
            if (param.atualizar)
                this.carregarTela(0, null);
            else
                this.router.navigate(['/suporte/manutencao/cadastro-cnpj-oficina/']);
        }
        else
            this.router.navigate(['/suporte/manutencao/cadastro-cnpj-oficina/' + param.company_id]);
    }

    back() {
        this.manageCompanyCnpjSelecionado = null;
        this.acao_alterarTela({ is_listagem: true, company_id: null });
    }

    createNew() {
        this.manageCompanyCnpjSelecionado = null;
        this.acao_alterarTela({ is_listagem: false, company_id: 0 });
    }

    carregarTela(nAttempts: number, company_id?: number) {
        this.ngxLoader.startLoader('loader-principal');

        this.cadastroCnpjService.getAll().subscribe({
            next: (response: ApiResponseModel<SupportCadastroCnpjGetAllResponseModel>) => {
                if (response !== null && (response.result.companiesCnpj.length > 0 || (company_id && company_id == 0))) {
                    this.companiesCnpjList = this.companiesCnpjListFiltered = response.result.companiesCnpj;
                    this.acao_alterarTela({ is_listagem: isEmpty(company_id), company_id: company_id });
                }
                else if (company_id && company_id > 0) {
                    this.notificationService.showErrorToastr(this.globals.translate('support.manutencao.cadastro-cnpj.mensagens.oficina-nao-encontrada.erro.corpo'));
                    this.back();
                }

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5)
                {
                    this.ngxLoader.stopLoader('loader-principal');
                    this.notificationService.showErrorToastr(this.globals.translate('support.manutencao.cadastro-cnpj.mensagens.listar.erro.corpo'));
                    return;
                }

                this.carregarTela(++nAttempts, company_id);
            }
        });
    }

    onSearch($event: string) {
        if (isEmpty(this.companiesCnpjList))
            return [];

        const term = $event.toLowerCase();
        const cnpjTerm = getOnlyNumbers(term);

        this.companiesCnpjListFiltered = this.companiesCnpjList.filter(o =>
            compararStringContemString(o.fantasyName.toLowerCase(), term)
            || (!isEmpty(cnpjTerm) && (getOnlyNumbers(o.cnpj).indexOf(cnpjTerm) > -1 || o.companiesCnpjs.some(c => getOnlyNumbers(c.cnpj).indexOf(cnpjTerm) > -1)))
        );
    }

    selecionarCompanyCnpj(companyCnpj_id: number) {
        this.manageCompanyCnpjSelecionado = this.companiesCnpjList.find(u => u.id == companyCnpj_id);

        if (isEmpty(this.manageCompanyCnpjSelecionado))
            this.manageCompanyCnpjSelecionado = new ManageCompanyCnpj({id: 0, name: '', cnpj: '', fantasyName: '', companiesCnpjs: [] });
    }
}
