import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { NgxUiLoaderService } from 'ngx-ui-loader';

import { CadastroCNPJService } from '../../cadastro-cnpj.service';
import { NotificationService } from 'app/_services/notification.service';

import { Globals } from 'app/_models/globals';
import { ManageCompanyCnpj } from '../../_models/company-model';
import { SupportGridview, SupportGridviewTableBodyCell } from 'app/support/_models/supportGridview';

@Component({ selector: 'app-cadastro-cnpj-list', templateUrl: './list.component.html', styleUrls: ['./list.component.scss'] })
export class SupportCadastroCnpjListComponent {
    @Output() onAlterarTela = new EventEmitter<any>();
    public filter: string = '';
    public config: SupportGridview = new SupportGridview();

    @Input() get companiesCnpjList(): ManageCompanyCnpj[] {
        return this.config.list;
    }

    set companiesCnpjList(value: ManageCompanyCnpj[]) {
        this.config.list = value;
    }

    constructor(private globals: Globals,
        protected router: Router,
        private cadastroCnpjService: CadastroCNPJService,
        private ngxLoader: NgxUiLoaderService,
        private notificationService: NotificationService) {
            this.config.set({
                actions: {
                    edit: { enable: true },
                    remove: { confirmationText: 'support.manutencao.cadastro-cnpj.mensagens.remover.confirm', enable: true }
                },
                emptyText: { isHtml: false, value: 'support.manutencao.oficina-sem-documentacao.vazio' },
                list: [],
                table: {
                    body: [
                        new SupportGridviewTableBodyCell('col text-center', 'id'),
                        new SupportGridviewTableBodyCell('col-2 truncate-gridview', 'fantasyName'),
                        new SupportGridviewTableBodyCell('col-2 truncate-gridview', 'name'),
                        new SupportGridviewTableBodyCell('col-2 text-center', 'cnpj')
                    ],
                    header: [
                        { className: 'col text-center', value: 'support.manutencao.cadastro-cnpj.tabela.cabecalho.id' },
                        { className: 'col-2', value: 'support.manutencao.cadastro-cnpj.tabela.cabecalho.nome-fantasia' },
                        { className: 'col-2', value: 'support.manutencao.cadastro-cnpj.tabela.cabecalho.razao-social' },
                        { className: 'col-2 text-center', value: 'support.manutencao.cadastro-cnpj.tabela.cabecalho.CNPJ' },
                        { className: 'col-0-5 text-center', value: 'support.manutencao.cadastro-cnpj.tabela.cabecalho.editar' }
                    ]
                }
            });
        }

    onEdit(company: ManageCompanyCnpj) {
        this.onAlterarTela.emit({ is_listagem: false, company_id: company.id });
    }

    onRemove($event: any) {
        const company = $event.item;

        this.ngxLoader.startLoader('loader-principal');

        this.cadastroCnpjService.delete(company.id).subscribe({
            next: () => {
                this.onAlterarTela.emit({ atualizar: true, is_listagem: true });
                this.notificationService.showSuccessToastr(this.globals.translate('support.manutencao.cadastro-cnpj.mensagens.remover.sucesso.corpo'));
                this.ngxLoader.stopLoader('loader-principal');
            },
            error: () => {
                this.notificationService.showErrorToastr(this.globals.translate('support.manutencao.cadastro-cnpj.mensagens.remover.erro.corpo'));
                this.ngxLoader.stopLoader('loader-principal');
                return;
            }
        });
    }
}
