<div class="suporte-cotacoes" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
    <app-header
        filterLabel="Perfil"
        [filterInput]="role"
        [filterOptions]="filterOptions"
        [hasBackButton]="false"
        [hasSearchButton]="false"
        [searchInput]="searchTerm"
        [searchPlaceholder]="'support.cotacoes.cabecalho.placeholder' | translate"
        (onFilter)="role = $event"
        (onSearch)="searchTerm = $event"
    >
        <button class="btn btn-primary btn-sm" (click)="carregarCotacoes(0)"><i class="fa fa-refresh"></i> {{'support.cotacoes.cabecalho.atualizar' | translate}}</button>
    </app-header>
    <div class="row pt0">
        <div class="cotacoes col-md-5">
            <ng-scrollbar #psCotacoes class="quotations-scrollable">
                @if ((cotacoesFiltradas)?.length == 0) {
                    <div class="text-center vazio">
                        <p class="mb0">{{'support.cotacoes.mensagens.nenhuma-cotacao-encontrada' | translate}}</p>
                    </div>
                } @else {
                    <table class="table table-striped table-hover table-responsible">
                        <thead>
                            <tr>
                                <th style="width: 20%;">{{'support.cotacoes.colunas.oficina' | translate}}</th>
                                <th style="width: 45%;">{{'support.cotacoes.colunas.veiculo' | translate}}</th>
                                <!--<th style="width: 15%;">{{'support.cotacoes.colunas.valor-estimado' | translate}}</th>-->
                                <th style="width: 10%;" class="text-center" placement="right" title="{{'support.cotacoes.colunas.pecas-respondidas' | translate}}"><i class="fa fa-wrench"></i></th>
                                <th style="width: 15%;" class="text-center" placement="right" title="{{'support.cotacoes.colunas.total-respostas' | translate}} ({{'support.cotacoes.colunas.tem-pagantes' | translate}})"><i class="fa fa-users"></i></th>
                                <th style="width: 10%;">&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            @for (cotacao of cotacoesFiltradas; track cotacao) {
                                <tr class="cursor-pointer" [ngClass]="{ 'selecionada': cotacao?.quotation_id === cotacaoSelecionada?.quotation_id, 'com-solicitacao-ajuda': cotacao?.ultima_solicitacao_ajuda }" (click)="selecionarCotacao($event, cotacao.quotation_id);" [attr.title]="cotacao?.ultima_solicitacao_ajuda ? cotacao?.ultima_solicitacao_ajuda : null">
                                    <td>
                                        <span class="truncate">
                                            {{cotacao.comprador.empresa}}
                                        </span>
                                    </td>
                                    <td><span class="truncate"><i class="rounded-circle marca {{cotacao.marca}} float-start"></i>{{cotacao.veiculo}}</span></td>
                                    <td class="text-center pl0 pr0">{{cotacao.total_pecas_respondidas}} / {{cotacao.total_pecas}} ({{cotacao.total_pecas_bo}})</td>
                                    <td class="text-center">{{cotacao.total_respostas}} <i class="fa" [ngClass]="cotacao.tem_pagantes ? 'fa-thumbs-o-up text-success' : 'fa-thumbs-o-down text-danger'"></i></td>
                                    <td class="text-center">
                                        <div class="buttons">
                                            <div class="red"><i class="fa fa-trash" (click)="acao_excluir($event, cotacao?.quotation_id, 0)"></i></div>
                                            <div class="green"><i class="fa fa-check-circle" (click)="acao_marcar_concluido($event, QuotationPageTypeEnum.Cotacoes, cotacao?.quotation_id, null)"></i></div>
                                        </div>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                }
            </ng-scrollbar>

            <ngx-loading [show]="loading.cotacoes" [template]="templateCotacao"></ngx-loading>
        </div>
        <div class="vendedores col-md-7">
            <div class="card card-default mb0">
                <div ngbAccordion accordion="NgbAccordion" class="ngbAccordion" [closeOthers]="true" (hide)="accordionChange($event)">
                    <div ngbAccordionItem="panelVendedores">
                        <div ngbAccordionHeader>
                            <button ngbAccordionButton>
                                <div class="linha d-flex">
                                    {{'support.cotacoes.vendedores.titulo' | translate}}
                                    <div class="links">
                                        @if (cotacaoSelecionada) {
                                            @if (!cotacaoSelecionada?.is_from_api) {
                                                <a href="javascript:void(0)" (click)="modal_alterar_vendedores_abrir($event)">{{'support.cotacoes.vendedores.atualizar.vendedores' | translate}}</a>
                                            }
                                            <div class="inline">
                                                |
                                                <a href="javascript:void(0)" (click)="modal_adicionar_vendedor_externo_abrir($event)">{{'support.cotacoes.vendedores.adicionar-vendedor-externo.link' | translate}}</a>
                                            </div>
                                            <i class="fa fa-1x fa-refresh cursor-pointer" tooltip="{{'support.cotacoes.vendedores.atualizar.tooltip' | translate}}" (click)="atualizarCotacao($event, 0, null)"></i>
                                        }
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody class="card-body pb0 d-flex">
                                <ng-template>
                                    <div class="col-md-7">
                                        <label class="col-md-12">{{'support.cotacoes.vendedores.colunas.titulo.contato' | translate}}</label>
                                        @if (vendedores) {
                                            <ng-scrollbar #psVendedores class="sellers-scrollable">
                                                @if (vendedores?.aguardando?.length == 0) {
                                                    <div class="text-center vazio">
                                                        <p class="mb0">{{'support.cotacoes.mensagens.nenhum-vendedor-encontrado' | translate}}</p>
                                                    </div>
                                                } @else {
                                                    <table class="table table-hover table-striped table-responsible">
                                                        <thead>
                                                            <tr>
                                                                <th>{{'support.cotacoes.vendedores.colunas.nome-empresa' | translate}}</th>
                                                                <th>{{'support.cotacoes.vendedores.colunas.fone-email' | translate}}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            @for (vendedor of vendedores.aguardando; track vendedor) {
                                                                <tr [ngClass]="{ 'bloqueado': vendedor.bloqueado }">
                                                                    <td>
                                                                        @if (vendedor?.is_billable) {
                                                                            <i class="fa fa-star pagante"></i>
                                                                        }
                                                                        @if (!vendedor.bloqueado && vendedor.fone2 && vendedor.user_company_id > 0) {
                                                                            <i class="fa icons fa-whatsapp text-success cursor-pointer" (click)="whatsapp_enviar(vendedor)"></i>
                                                                        }
                                                                        {{vendedor.nome}}
                                                                        <br />
                                                                        ({{vendedor.empresa}})
                                                                    </td>
                                                                    <td>
                                                                        <span class="truncate">{{vendedor.fones}}</span>
                                                                        <span class="truncate">{{vendedor.email}}</span>
                                                                    </td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                }
                                            </ng-scrollbar>
                                        }
                                    </div>
                                    <div class="col-md-5">
                                        <label class="col-md-12">{{'support.cotacoes.vendedores.colunas.titulo.participando' | translate}}</label>
                                        <ng-scrollbar #psVendedoresParticipando="ngScrollbar">
                                            @if (vendedores?.participando?.length == 0) {
                                                <div class="text-center vazio">
                                                    <p class="mb0">{{'support.cotacoes.mensagens.nenhum-vendedor-encontrado' | translate}}</p>
                                                </div>
                                            } @else {
                                                <table class="table table-hover table-striped table-responsible">
                                                    <thead>
                                                        <tr>
                                                            <th>{{'support.cotacoes.vendedores.colunas.nome-empresa' | translate}}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        @for (vendedor of vendedores.participando; track vendedor) {
                                                            <tr [ngClass]="{ 'nao-participando' : vendedor?.nao_quer_participar, 'bloqueado': vendedor.bloqueado }">
                                                                <td>
                                                                    @if (vendedor?.is_billable) {
                                                                        <i class="fa fa-star pagante"></i>
                                                                    }
                                                                    @if (vendedor?.nao_quer_participar) {
                                                                        <i class="fa fa-info-circle" tooltip="{{vendedor?.razao_nao_participar}}"></i>
                                                                    }
                                                                    {{vendedor.nome}}
                                                                    <br />
                                                                    ({{vendedor.empresa}})
                                                                </td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            }
                                        </ng-scrollbar>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div ngbAccordionItem="panelCotacao">
                        <div ngbAccordionHeader>
                            <button ngbAccordionButton>
                                <div class="linha d-flex panel-cotacao-header">
                                    <div>
                                        {{'support.cotacoes.dados-cotacao.titulo' | translate}}
                                        @if (cotacaoSelecionada) {
                                            <small>(#{{cotacaoSelecionada?.quotation_id}} - {{cotacaoSelecionada?.veiculo}} - {{'support.cotacoes.dados-cotacao.data-criacao' | translate}}: {{cotacaoSelecionada?.creation_date | date:'dd/MM/yyyy HH:mm'}})</small>
                                        }
                                    </div>
                                    <div>
                                        @if (cotacaoSelecionada) {
                                            <a href="javascript:void(0)" style="margin-right: 10px;" class="float-end" (click)="modal_editar_cotacao_abrir($event)">{{'support.cotacoes.dados-cotacao.editar.link' | translate}}</a>
                                        }
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div ngbAccordionCollapse>
                            <div ngbAccordionBody class="dados-cotacao">
                                <ng-template>
                                    <div class="row mt10">
                                        <div class="col col-md-4">
                                            <label class="col-md-4">{{'support.cotacoes.dados-cotacao.codigo' | translate}}:</label>
                                            <span class="col-md-8">#{{cotacaoSelecionada?.quotation_id}}
                                                @if (cotacaoSelecionada?.tem_erp) {
                                                    <span>{{'support.cotacoes.dados-cotacao.tem-erp' | translate}}</span>
                                                }
                                            </span>
                                        </div>
                                        <div class="col col-md-4">
                                            <label class="col-md-4">{{'support.cotacoes.dados-cotacao.data-criacao' | translate}}:</label>
                                            <span class="col-md-8">{{cotacaoSelecionada?.creation_date | date:'dd/MM/yyyy HH:mm'}}</span>
                                        </div>
                                        <div class="col col-md-4 d-flex">
                                            <label class="col-md-4">{{'support.cotacoes.dados-cotacao.status' | translate}}:</label>
                                            <span class="truncate" style="width: 66.67%;">{{cotacaoSelecionada?.status}}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col col-md-4">
                                            <label class="col-md-4">{{'support.cotacoes.dados-cotacao.comprador.nome-oficina' | translate}}:</label>
                                            <span class="col-md-8">{{cotacaoSelecionada?.comprador.empresa}}</span>
                                            <div class="col-md-12"></div>
                                            <label class="col-md-4 pr0">{{'support.cotacoes.dados-cotacao.tipo-peca.titulo' | translate}}:</label>
                                            <span class="col-md-8">{{cotacaoSelecionada?.tipos_pecas}}</span>
                                        </div>
                                        <div class="col col-md-4 d-flex">
                                            <label class="col-md-4">{{'support.cotacoes.dados-cotacao.comprador.fones' | translate}}:</label>
                                            <div>{{cotacaoSelecionada?.comprador.fone1}}
                                                @if (cotacaoSelecionada?.comprador.fone2) {
                                                    <br />
                                                    <span>{{cotacaoSelecionada?.comprador.fone2}}</span>
                                                }
                                            </div>
                                        </div>
                                        <div class="col col-md-4 d-flex">
                                            <label class="col-md-4">{{'support.cotacoes.dados-cotacao.comprador.email' | translate}}:</label>
                                            <span class="truncate" style="width: 66.67%;">{{cotacaoSelecionada?.comprador.email}}</span>

                                            @if (cotacaoSelecionada?.buyer_notes?.length > 0) {
                                                <div class="col-md-12"></div>
                                                <label class="col-md-4 pr0">{{'support.cotacoes.dados-cotacao.observacao' | translate}}:</label>
                                                <span class="col-md-8"><i class="fa fa-info-circle" tooltip="{{cotacaoSelecionada?.buyer_notes}}"></i></span>
                                            }
                                        </div>
                                    </div>
                                    <div class="row mt5 pecas">
                                        <div class="col-md-6 text-center">
                                            <label class="col-md-12">{{'support.cotacoes.dados-cotacao.pecas.titulo.nao-encontradas' | translate}}</label>
                                            <ng-scrollbar>
                                                <ul>
                                                    @for (peca of cotacaoSelecionada?.pecas_nao_encontradas; track peca) {
                                                        <li (click)="modal_editar_peca_abrir($event, peca)">{{peca.codigo_nome}}</li>
                                                    }
                                                </ul>
                                            </ng-scrollbar>
                                        </div>
                                        <div class="col-md-6 text-center">
                                            <label class="col-md-12">{{'support.cotacoes.dados-cotacao.pecas.titulo.encontradas' | translate}}</label>
                                            <ng-scrollbar>
                                                <ul>
                                                    @for (peca of cotacaoSelecionada?.pecas_encontradas; track peca) {
                                                        <li (click)="modal_editar_peca_abrir($event, peca)">{{peca.codigo_nome}}
                                                            @if (peca.is_bo) {
                                                                <span class="text-bold">{{'support.cotacoes.dados-cotacao.pecas.bo' | translate}}</span>
                                                            }
                                                        </li>
                                                    }
                                                </ul>
                                            </ng-scrollbar>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ngx-loading [show]="loading.vendedores" [template]="templateVendedor"></ngx-loading>
        </div>
    </div>
</div>

<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
<ng-template #templateCotacao></ng-template>
<ng-template #templateVendedor></ng-template>

<ng-template #templateAdicionarVendedorExterno>
    <div class="modal-header">
        <h4 class="modal-title">{{'support.cotacoes.vendedores.adicionar-vendedor-externo.titulo' | translate}}</h4>
        <button type="button" data-dismiss="modal" aria-label="Close" class="btn-close" (click)="modal_adicionar_vendedor_externo_fechar();"></button>
    </div>
    <div class="modal-body">
        <div>
            <span>{{'support.cotacoes.vendedores.adicionar-vendedor-externo.subtitulo' | translate}}: {{ cotacaoSelecionada?.veiculo }}</span>
        </div>
        <div>
            <form novalidate (ngSubmit)="modal_adicionar_vendedor_externo_enviar_link(0, false, $event)" [formGroup]="vendedorExternoForm">
                <ng-scrollbar>
                    <div class="container-vendedor-externo pb0 col-md-12">
                        <div class="d-flex flex-wrap">
                            @if (errors?.length > 0) {
                                <div class="alert alert-danger col-md-6 offset-md-3">
                                    <h3 class="mt0">{{'support.cotacoes.vendedores.adicionar-vendedor-externo.erro-cadastro.titulo' | translate}}</h3>
                                    <ul>
                                        @for (error of errors; track error) {
                                            <li>{{ error }}</li>
                                        }
                                    </ul>
                                </div>
                            }
                        </div>

                        <div class="d-flex flex-wrap" style="justify-content: center;">
                            <div class="form-check radio-group">
                                <div><input type="radio" name="tipoNC" value="nc" formControlName="tipoNC" />{{'support.cotacoes.vendedores.adicionar-vendedor-externo.campos.interno-externo.nao-cadastrado' | translate: {system: ('geral.sistema' | translate)} }}</div>
                                <div><input type="radio" name="tipoNC" value="c" formControlName="tipoNC" />{{ 'support.cotacoes.vendedores.adicionar-vendedor-externo.campos.interno-externo.cadastrado' | translate: {system: ('geral.sistema' | translate)} }}</div>
                            </div>
                        </div>

                        <div class="d-flex flex-wrap" [ngClass]="{ 'd-none': vendedorExternoForm.value.tipoNC === 'c' }">

                            <div class="mb-3 form-group col-md-6">
                                <label class="col-form-label">{{'support.cotacoes.vendedores.adicionar-vendedor-externo.campos.cnpj' | translate}}</label>
                                <div>
                                    <input class="form-control" id="cnpj" type="text" placeholder="{{'support.cotacoes.vendedores.adicionar-vendedor-externo.campos.cnpj' | translate}}{{'support.cotacoes.vendedores.adicionar-vendedor-externo.campos.requerido' | translate}}" formControlName="cnpj" [ngClass]="{'is-invalid': displayMessage.cnpj }" cnpj [mask]="MASKS.cnpj.text" (blur)="atualizarValidacao()" />
                                    @if (displayMessage.cnpj) {
                                        <span class="text-danger">
                                            <p [innerHTML]="displayMessage.cnpj" class="mb-0"></p>
                                        </span>
                                    }
                                </div>
                            </div>

                            <div class="mb-3 form-group col-md-6">
                                <label class="col-form-label">{{'support.cotacoes.vendedores.adicionar-vendedor-externo.campos.empresa' | translate}}</label>
                                <div>
                                    <input class="form-control" id="empresa" type="text" maxlength="100" placeholder="{{'support.cotacoes.vendedores.adicionar-vendedor-externo.campos.empresa' | translate}}{{'support.cotacoes.vendedores.adicionar-vendedor-externo.campos.requerido' | translate}}" formControlName="empresa" [ngClass]="{'is-invalid': displayMessage.empresa }" (blur)="atualizarValidacao()" />
                                    @if (displayMessage.empresa) {
                                        <span class="text-danger">
                                            <p [innerHTML]="displayMessage.empresa" class="mb-0"></p>
                                        </span>
                                    }
                                </div>
                            </div>

                        </div>

                        <div class="d-flex flex-wrap" [ngClass]="{ 'd-none': vendedorExternoForm.value.tipoNC === 'c' }">

                            <div class="mb-3 form-group col-md-6">
                                <label class="col-form-label">{{'support.cotacoes.vendedores.adicionar-vendedor-externo.campos.vendedor' | translate}}</label>
                                <div>
                                    <input class="form-control" id="vendedor" type="text" maxlength="100" placeholder="{{'support.cotacoes.vendedores.adicionar-vendedor-externo.campos.vendedor' | translate}}{{'support.cotacoes.vendedores.adicionar-vendedor-externo.campos.requerido' | translate}}" formControlName="vendedor" [ngClass]="{'is-invalid': displayMessage.vendedor }" (blur)="atualizarValidacao()" />
                                    @if (displayMessage.vendedor) {
                                        <span class="text-danger">
                                            <p [innerHTML]="displayMessage.vendedor" class="mb-0"></p>
                                        </span>
                                    }
                                </div>
                            </div>

                            <div class="mb-3 form-group col-md-6">
                                <label class="col-form-label">{{'support.cotacoes.vendedores.adicionar-vendedor-externo.campos.whatsapp' | translate}}</label>
                                <div>
                                    <input class="form-control" id="whatsapp" type="text" placeholder="{{'support.cotacoes.vendedores.adicionar-vendedor-externo.campos.whatsapp' | translate}}{{'support.cotacoes.vendedores.adicionar-vendedor-externo.campos.requerido' | translate}}" formControlName="whatsapp" [ngClass]="{'is-invalid': displayMessage.whatsapp }" [textMask]="{mask: MASKS.phoneNumber.textMaskFunction}" (blur)="atualizarValidacao()" />
                                    @if (displayMessage.whatsapp) {
                                        <span class="text-danger">
                                            <p [innerHTML]="displayMessage.whatsapp" class="mb-0"></p>
                                        </span>
                                    }
                                </div>
                            </div>

                        </div>

                        <div class="d-flex flex-wrap" [ngClass]="{ 'd-none': vendedorExternoForm.value.tipoNC === 'nc' }">

                            <div class="mb-3 form-group col-md-6">
                                <label class="col-form-label mb-1">{{'support.cotacoes.vendedores.adicionar-vendedor-externo.campos.holding.label' | translate}}</label>
                                <div>
                                    <ng-select formControlName="holding" placeholder="{{'support.cotacoes.vendedores.adicionar-vendedor-externo.campos.holding.placeholder' | translate}}" (change)="modal_adicionar_vendedor_externo_selecionar_cadastrado_holding()" [ngClass]="{'is-invalid': displayMessage.holding }" [attr.disabled]="(modal_adicionar_vendedor_externo_holdings.length === 0) ? 'disabled' : null">
                                        @for (item of modal_adicionar_vendedor_externo_holdings; track item) {
                                            <ng-option [value]="item">{{item.nome}}</ng-option>
                                        }
                                    </ng-select>
                                    @if (displayMessage.holding) {
                                        <span class="text-danger">
                                            <p [innerHTML]="displayMessage.holding" class="mb-0"></p>
                                        </span>
                                    }
                                </div>
                            </div>

                            <div class="mb-3 form-group col-md-6">
                                <label class="col-form-label mb-1">{{'support.cotacoes.vendedores.adicionar-vendedor-externo.campos.company.label' | translate}}</label>
                                <div>
                                    <ng-select formControlName="company" placeholder="{{'support.cotacoes.vendedores.adicionar-vendedor-externo.campos.company.placeholder' | translate}}" (change)="modal_adicionar_vendedor_externo_selecionar_cadastrado_company()" [ngClass]="{'is-invalid': displayMessage.company }">
                                        @for (item of modal_adicionar_vendedor_externo_companies; track item) {
                                            <ng-option [value]="item">{{item.nome}}</ng-option>
                                        }
                                    </ng-select>
                                    @if (displayMessage.company) {
                                        <span class="text-danger">
                                            <p [innerHTML]="displayMessage.company" class="mb-0"></p>
                                        </span>
                                    }
                                </div>
                            </div>

                        </div>

                        <div class="d-flex flex-wrap" [ngClass]="{ 'd-none': vendedorExternoForm.value.tipoNC === 'nc' }">

                            <div class="mb-3 form-group col-md-6">
                                <label class="col-form-label mb-1">{{'support.cotacoes.vendedores.adicionar-vendedor-externo.campos.usuario.label' | translate}}</label>
                                <div>
                                    <ng-select formControlName="usuario" placeholder="{{'support.cotacoes.vendedores.adicionar-vendedor-externo.campos.usuario.placeholder' | translate}}" (change)="modal_adicionar_vendedor_externo_selecionar_cadastrado_vendedor()" [ngClass]="{'is-invalid': displayMessage.usuario }">
                                        @for (item of modal_adicionar_vendedor_externo_vendedores; track item) {
                                            <ng-option [value]="item">{{item.nome}}</ng-option>
                                        }
                                    </ng-select>
                                    @if (displayMessage.usuario) {
                                        <span class="text-danger">
                                            <p [innerHTML]="displayMessage.usuario" class="mb-0"></p>
                                        </span>
                                    }
                                </div>
                            </div>

                            <div class="mb-3 form-group col-md-6">
                                <label class="col-form-label mb-1">{{'support.cotacoes.vendedores.adicionar-vendedor-externo.campos.whatsapp' | translate}}</label>
                                <div>
                                    <input class="form-control" id="whatsapp" type="text" placeholder="{{'support.cotacoes.vendedores.adicionar-vendedor-externo.campos.whatsapp' | translate}}{{'support.cotacoes.vendedores.adicionar-vendedor-externo.campos.requerido' | translate}}" formControlName="whatsapp" [ngClass]="{'is-invalid': displayMessage.whatsapp }" [textMask]="{mask: MASKS.phoneNumber.textMaskFunction}" (blur)="atualizarValidacao()" [readonly]="(!vendedorExternoForm.value.usuario) ? 'disabled' : null" />
                                    @if (displayMessage.whatsapp) {
                                        <span class="text-danger">
                                            <p [innerHTML]="displayMessage.whatsapp" class="mb-0"></p>
                                        </span>
                                    }
                                </div>
                            </div>

                        </div>

                        <div class="d-flex flex-wrap">
                            <table class="table table-bordered">
                                <tbody>
                                    <tr>
                                        <td class="w_ninety"><b>{{ 'support.cotacoes.vendedores.adicionar-vendedor-externo.campos.peca-nome' | translate}}</b></td>
                                        <td class="w_five">
                                            <div class="form-check c-checkbox needsclick checkboxcot">
                                                <label class="needsclick">
                                                    <input type="checkbox" class="needsclick todas_pecas" (click)="modal_adicionar_vendedor_externo_selecionar_todas_pecas($event);">
                                                    <span class="fa fa-check"></span>
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                    @for (peca of cotacaoSelecionada?.pecas_todas; track peca) {
                                        <tr>
                                            <td>{{peca.nome}}</td>
                                            <td>
                                                <div class="form-check c-checkbox needsclick checkboxcot">
                                                    <label class="needsclick">
                                                        <input type="checkbox" class="needsclick peca_notificacao" (click)="modal_adicionar_vendedor_externo_selecionar(peca, $event);">
                                                        <span class="fa fa-check"></span>
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                </ng-scrollbar>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button id="closeModal" type="button" class="btn btn-light" (click)="modal_adicionar_vendedor_externo_fechar();">{{ 'elemento.generico-botao.fechar' | translate}}</button>
        <button class="btn btn-sm btn-primary" (click)="modal_adicionar_vendedor_externo_enviar_link(0, false, $event)" [disabled]="!vendedorExternoForm.valid || !vendedorExternoForm.dirty ? 'disabled' : null" type="submit">{{'support.cotacoes.vendedores.adicionar-vendedor-externo.enviar-link' | translate}}</button>
    </div>
</ng-template>

<app-alterar-vendedores [cotacao]="cotacaoSelecionada" [visivel]="modal_visivel.alterar_vendedores" (fechar)="modal_alterar_vendedores_fechar($event)"></app-alterar-vendedores>
<app-editar-cotacao [cotacao]="cotacaoSelecionada" [visivel]="modal_visivel.editar_cotacao" (fechar)="modal_editar_cotacao_fechar($event)"></app-editar-cotacao>
<app-editar-peca [peca]="pecaSelecionada" [visivel]="modal_visivel.editar_peca" (fechar)="modal_editar_peca_fechar($event)"></app-editar-peca>
