import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from 'app/_services/base.service';
import { SettingsService } from "app/_services/settings.service";

@Injectable({ providedIn: 'root' })
export class SupportAvaliacaoVendedoresService extends BaseService {
    private _domain: string = '/support/sellers-reviews';

    constructor(private config: SettingsService) {
        super();
    }

    public getAll(seller_company_ids: number[]): Observable<any> {
        const listArray = seller_company_ids.join(',');
        return this.config.executeGet({ url: `${this._domain}?sellerCompanyIds=${listArray}`, body: {} });
    }

    public getAllSellers(): Observable<any> {
        const body = {};
        return this.config.executeGet({ url: `${this._domain}/sellers` });
    }

    public getAllSellersByQuotationId(quotation_id: number): Observable<any> {
        return this.config.executeGet({ url: `${this._domain}/sellers/quotations/${quotation_id}`});
    }
}
