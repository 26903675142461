export class ManageCompanyCnpj {
    id: number;
    cnpj: string;
    name: string;
    fantasyName: string;
    companiesCnpjs: CompanyCnpj[];

    public constructor(init?: Partial<ManageCompanyCnpj>) {
        Object.assign(this, init);
    }
}

export class CompanyCnpj {
    cnpj: string;
    name: string;
}
