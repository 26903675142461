import { TemplateRef } from "@angular/core";

export class SupportGridview {
    actions?: SuportGridviewAction;
    emptyText: { isHtml: boolean; value: string; };
    list: any[];
    sort?: SuportGridviewSort;
    table: SupportGridviewTable;

    constructor() {
        this.actions = {
            edit: { enable: true, type: 'icon' },
            remove: { enable: false, type: 'icon' }
        };

        this.emptyText = { isHtml: false, value: '' };
        this.list = [];

        this.sort = { enabled: false, sortedBy: null, isReverse: false };
    }

    set(options: Partial<{ actions: Partial<SuportGridviewAction>, emptyText: Partial<{ isHtml: boolean; value: string; }>, list: any[], sort: Partial<SuportGridviewSort>, table: SupportGridviewTable }>) {
        if (options.actions) {
            if (options.actions.edit)
                this.actions.edit = { ...this.actions.edit, ...options.actions.edit };

            if (options.actions.remove)
                this.actions.remove = { ...this.actions.remove, ...options.actions.remove };
        }

        if (options.emptyText) {
            if (options.emptyText)
                this.emptyText = { ...this.emptyText, ...options.emptyText };
        }

        this.list = options.list;

        if (options.sort) {
            if (!options.sort.enabled) {
                this.sort.isReverse = false;
                this.sort.sortedBy = undefined;
            } else {
                this.sort.enabled = options.sort.enabled;
                this.sort.isReverse = options.sort.isReverse || false;
                this.sort.sortedBy = options.sort.sortedBy;
            }
        }

        this.table = options.table;
    }
}

export interface SuportGridviewAction {
    edit: SuportGridviewActionItem;
    remove: SuportGridviewActionItem;
}

export interface SuportGridviewActionItem {
    confirmationText?: string;
    disabledCondition?: (item: any) => boolean;
    enable: boolean;
    labelText?: string;
    type?: 'icon' | 'button';
}

export interface SuportGridviewSort {
    enabled: boolean;
    isReverse: boolean;
    sortedBy: string;
}

export interface SupportGridviewTable {
    body: SupportGridviewTableBodyCell[];
    bodyClassName?: string;
    header: SupportGridviewTableHeaderCell[];
}

export interface SupportGridviewTableHeaderCell {
    className: string;
    sortName?: string;
    value: string;
}

export class SupportGridviewTableBodyCell {
    className: string;
    value: string;
    type: 'string' | 'date' | 'number' | 'boolean' | 'html';
    format: string;
    templateName?: string;

    constructor(className: string, value: string, type?: 'string' | 'date' | 'number' | 'boolean' | 'html', format?: string, templateName?: string) {
        this.className = className;
        this.value = value;

        this.type = type ?? 'string';

        if (!format) {
            if (this.type === 'date')
                this.format = 'dd/MM/yyyy';
        }
        
        if (!this.format)
            this.format = format ?? '';
        
        if (!this.templateName)
            this.templateName = templateName;
    }
}

export class SupportGridviewTemplateCell {
    name: string;
    template: TemplateRef<any>;
}