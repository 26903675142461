<div class="contestacoes" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
    <app-header
        [hasBackButton]="false"
        [hasButtons]="false"
        [title]=" 'support.contestacoes.titulo' | translate"
    ></app-header>
    <div class="card card-default col-md-10 offset-md-1">
        <div class="col-md-12">
            @if (contestacoes.length <= 0) {
                <div class="vazio">
                    <label>{{ 'support.contestacoes.vazio' | translate}}</label>
                </div>
            } @else {                
                <section class="d-flex flex-column lista-contestacoes">
                    <header class="linha d-flex">
                        <div class="col-1 text-center p-2">#</div>
                        <div class="col p-2">{{ 'support.contestacoes.cabecalho.fornecedor' | translate}}</div>
                        <div class="col p-2">{{ 'support.contestacoes.cabecalho.oficina' | translate}}</div>
                        <div class="col-2 p-2">{{ 'support.contestacoes.cabecalho.veiculo' | translate}}</div>
                        <div class="col-2 p-2">{{ 'support.contestacoes.cabecalho.peca' | translate}}</div>
                        <div class="col-1 p-2">{{ 'support.contestacoes.cabecalho.reembolso' | translate}}</div>
                        <div class="col-1 text-center p-2">
                            <div class="form-check c-checkbox needsclick checkboxcot">
                                <label class="needsclick">
                                    <input type="checkbox" class="needsclick" (click)="selecionarTodas($event);">
                                    <span class="fa fa-check"></span>
                                </label>
                            </div>
                        </div>
                    </header>
                    <ng-scrollbar>
                        @for (contestacao of contestacoes; track contestacao) {                                
                            <div class="linha d-flex">
                                <div class="col-1 text-center p-2"><i class="fa fa-info-circle ml-1" tooltip="{{contestacao.motivo}}"></i></div>
                                <div class="col p-2">{{contestacao.fornecedor}}</div>
                                <div class="col p-2">{{contestacao.oficina}}</div>
                                <div class="col-2 text-truncate p-2">#{{contestacao.quotation_id}} - {{contestacao.veiculo}}</div>
                                <div class="col-2 text-truncate p-2">{{contestacao.peca}}</div>
                                <div class="col-1 p-2">{{contestacao.reembolso | currency:'R$'}}</div>
                                <div class="col-1 text-center p-2">
                                    <div class="form-check c-checkbox needsclick checkboxcot">
                                        <label class="needsclick">
                                            <input type="checkbox" class="needsclick contestacao-item" (click)="selecionar(contestacao, $event);">
                                            <span class="fa fa-check"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        }
                    </ng-scrollbar>
                    <footer class="col-md-12 text-center mt-3">
                        <div class="approval">
                            <input type="button" class="btn btn-sm btn-primary" (click)="aprovar()" [disabled]="!algumaContestacaoSelecionada" value="{{ 'support.contestacoes.botoes.aprovar' | translate}}" />
                        </div>
                    </footer>
                </section>   
            }
        </div>
    </div>
</div>
<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
