<div class="cadastro-cnpj-edicao">
    <div class="card col-md-8 offset-md-2 mb0">
        <div class="d-flex flex-wrap align-items-center pt10 mb10">
            <div class="col-md-4">
                <div class="mb-3 form-group">
                    <label for="name">{{'support.manutencao.cadastro-cnpj.campos.oficina.label' | translate}}</label>
                    <select class="form-select form-select-sm search-company m-b" [(ngModel)]="manageCompanyCnpj.id" name="company_id" [disabled]="isNewCnpj ? null : 'disabled'">
                        @for (companyCnpj of companiesWithoutCnpjList; track companyCnpj) {
                            <option value="{{companyCnpj.id}}">{{companyCnpj.fantasy_name}}</option>
                        }
                    </select>
                </div>
            </div>
            <div class="col-md-4 mt10">
                <button id="button-add-cnpj" type="button" class="btn btn-primary" (click)="adicionarCampoCnpj()" [disabled]="(isNewCnpj || cadastroForm.invalid) && (cadastroForm.invalid || !manageCompanyCnpj || manageCompanyCnpj.id === 0)">
                    {{'support.manutencao.cadastro-cnpj.botoes.adicionar-cnpj' | translate}}
                </button>
            </div>
        </div>
        <ng-scrollbar id="psUIE">
            <form [formGroup]="cadastroForm" novalidate (ngSubmit)="salvar(0)">
                <div class="corpo-pagina col-md-12">
                    <section class="d-flex flex-wrap striped lista-cnpjs">
                        <ng-scrollbar class="corpo" height="auto">
                            <div formArrayName="repairers">
                                @for (empresa of repairerList.controls; track empresa; let i = $index) {
                                    <div class="linha" [formGroupName]="i">
                                        <div class="col-md-7"><label>{{'support.manutencao.cadastro-cnpj.campos.razao-social.label' | translate}}</label>
                                        <input type="text" class="form-control razaoSocial_{{i}}" formControlName="razaoSocial"
                                            [ngClass]="{'is-invalid': submitted && empresa.get('razaoSocial')?.invalid }" />
                                            @if (submitted && empresa.get('razaoSocial')?.invalid) {
                                                <div class="text-danger">
                                                    @if (empresa.get('razaoSocial').hasError('required')) {
                                                        <span>{{validationMessages['razaoSocial']['required']}}</span>
                                                    }
                                                    @if (empresa.get('razaoSocial').hasError('maxlength')) {
                                                        <span>{{validationMessages['razaoSocial']['maxLength']}}</span>
                                                    }
                                                    @if (empresa.get('razaoSocial').hasError('pattern')) {
                                                        <span>{{validationMessages['razaoSocial']['pattern']}}</span>
                                                    }
                                                </div>
                                            }
                                        </div>
                                        <div class="col-4"> <label>{{'support.manutencao.cadastro-cnpj.campos.cnpj.label' | translate}}</label>
                                            <input type="text" class="form-control cnpj_{{i}}"
                                                formControlName="cnpj" cnpj [mask]="MASKS.cnpj.text" [ngClass]="{'is-invalid': submitted && empresa.get('cnpj')?.invalid }" />
                                            @if (submitted && empresa.get('cnpj')?.invalid) {
                                                <div class="text-danger">
                                                    @if (empresa.get('cnpj').hasError('required')) {
                                                        <span>{{validationMessages['cnpj']['required']}}</span>
                                                    }
                                                    @if (empresa.get('cnpj').hasError('cnpj')) {
                                                        <span>{{validationMessages['cnpj']['cnpj']}}</span>
                                                    }
                                                    @if (empresa.get('cnpj').hasError('cnpjDuplicado')) {
                                                        <span>{{validationMessages['cnpj']['cnpjDuplicado']}}</span>
                                                    }
                                                </div>
                                            }
                                        </div>
                                        <div class="col-1 excluir">
                                            @if (i > 0) {
                                                <button type="button" class="btn btn-danger" (click)="excluirCampoCnpj(i)">
                                                    {{'support.manutencao.cadastro-cnpj.botoes.excluir-cnpj' | translate}}
                                                </button>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </ng-scrollbar>
                    </section>
                </div>
                <div class="col-md-12 botoes text-center pt10">
                    <button id="button-submit" type="submit" class="btn btn-primary" [disabled]="isNewCnpj && (!manageCompanyCnpj || manageCompanyCnpj.id === 0 || manageCompanyCnpj.companiesCnpjs.length <= 1)">{{'elemento.generico-botao.salvar' | translate}}</button>
                    &nbsp;
                    <input type="button" class="btn btn-light" value="{{'elemento.generico-botao.voltar' | translate}}" (click)="voltar(false)" />
                </div>
            </form>
        </ng-scrollbar>
    </div>
</div>
