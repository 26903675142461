<div class="cadastro-cnpj">
    <app-header
        [title]="'support.manutencao.cadastro-cnpj.titulo' | translate"
        [hasBackButton]="!is_listagem"
        [hasSearchButton]="false"
        [searchInput]="filter"
        [searchPlaceholder]="'support.manutencao.cadastro-cnpj.filtro' | translate"
        (onGoBack)="back()"
        (onSearch)="onSearch($event)"
    >
        @if (is_listagem) {
            <button class="btn btn-primary btn-sm" (click)="createNew()">
                <i-feather name="plus"></i-feather>
                {{'support.manutencao.cadastro-cnpj.novo' | translate}}
            </button>
        }
    </app-header>
    @if (is_listagem) {
        <app-cadastro-cnpj-list (onAlterarTela)="acao_alterarTela($event)" [companiesCnpjList]="companiesCnpjListFiltered"></app-cadastro-cnpj-list>
    } @else {
        <app-cadastro-cnpj-edicao (onAlterarTela)="acao_alterarTela($event)" [manageCompanyCnpj]="manageCompanyCnpjSelecionado"></app-cadastro-cnpj-edicao>
    }
</div>
<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
