import { Component, OnInit } from '@angular/core';

import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject } from 'rxjs';

import { NotificationService } from 'app/_services/notification.service';
import { SupportDiarioService } from './diario.service';

import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { Globals } from 'app/_models/globals';
import { RelatorioDiario } from '../_models/relatorioDiario';
import { SupportAdminGetCarregarDiarioResponseModel } from '../_models/supportAdminGetCarregarDiarioResponseModel';
import { FilterOption } from 'app/_componentes/header/_models/filterOption';

declare const isEmpty: any;
declare const sort_by: any;

@Component({ selector: 'app-diario', templateUrl: './diario.component.html', styleUrls: ['./diario.component.scss'] })
export class SupportDiarioComponent implements OnInit {
    active: number;
    entradas: RelatorioDiario[];
    exclusoes: RelatorioDiario[];
    loading_ctr: number;
    loading: Subject<boolean> = new Subject();
    orderByField: string;
    _perfil: number;
    reverseSort: boolean;

    filterOptions: FilterOption[] = [];

    get perfil(): number {
        return this._perfil;
    }

    set perfil(value: number) {
        this._perfil = value;
        this.carregarGeral(0);
    }

    constructor(
        private notificationService: NotificationService,
        private diarioService: SupportDiarioService,
        private globals: Globals,
        private ngxLoader: NgxUiLoaderService)
    { }

    ngOnInit(): void {
        this.active = 1;
        this.entradas = [];
        this.exclusoes = [];
        this.perfil = 3;
        this.carregarTela();

        this.filterOptions = [
            { value: '3', label: this.globals.translate('support.perfil.central') },
            { value: '2', label: this.globals.translate('support.perfil.v8') },
            { value: '1', label: this.globals.translate('support.perfil.popular') }
        ]
    }

    atualizar() {
        this.carregarTela();
    }

    carregarGeral(nAttempts: number) {
        this.diarioService.getDailyReport(this.perfil).subscribe({
            next: (response: ApiResponseModel<SupportAdminGetCarregarDiarioResponseModel>) => {
                if (response && response.success) {
                    this.entradas = response.result.relatorioDiario.entradas;
                    this.exclusoes = response.result.relatorioDiario.exclusoes;
                    this.ngxLoader.stopLoader('loader-principal');
                }
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.ngxLoader.stopLoader('loader-principal');
                    this.notificationService.showErrorToastr(this.globals.translate('support.diario.mensagens.erro.carregamento.corpo'));
                    return;
                }

                this.carregarGeral(++nAttempts);
            }
        });
    }

    carregarTela() {
        this.ngxLoader.startLoader('loader-principal');
        this.carregarGeral(0);
    }

    ordenar(coluna, direcao) {
        this.reverseSort = (this.orderByField === coluna) ? !this.reverseSort : direcao;
        this.orderByField = coluna;
        this.sortList();
    }

    sortList() {
        if (this.active == 1) {
            if (this.entradas === null || this.entradas === undefined)
                return;

            this.entradas.sort(sort_by([{ name: this.orderByField, reverse: this.reverseSort }]));
        }
        else if (this.active == 2) {
            if (this.exclusoes === null || this.exclusoes === undefined)
                return;

            this.exclusoes.sort(sort_by([{ name: this.orderByField, reverse: this.reverseSort }]));
        }

    }
}
