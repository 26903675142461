import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { SupportCadastroNovidadesService } from '../../cadastro-novidades.service';
import { TranslateService } from '@ngx-translate/core';

import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { SupportGridview, SupportGridviewTableBodyCell } from 'app/support/_models/supportGridview';
import { Update } from 'app/_models/update';

@Component({ selector: 'app-cadastro-novidades-list', templateUrl: './list.component.html', styleUrls: ['./list.component.scss'] })
export class SupportCadastroNovidadesListComponent {
    public filter: string = '';

    @Output() editUpdate: EventEmitter<any> = new EventEmitter();
    @Output() updatesListChange: EventEmitter<Update[]> = new EventEmitter();

    private _updatesList: Update[];
    @Input() get updatesList(){
        return this._updatesList;
    }

    set updatesList(updatesList: Update[]){
        this.updatesListChange.emit(updatesList);
        this._updatesList = updatesList;

        if (this._updatesList) {
            this._updatesList.forEach(u => u.mudancas =
                `${u.has_features ? this.translateService.instant('support.manutencao.cadastro-novidades.listagem.corpo.possui-features') : ''}
                ${u.has_features && u.has_bugs_fixes ? " | " : ''}
                ${u.has_bugs_fixes ? this.translateService.instant('support.manutencao.cadastro-novidades.listagem.corpo.possui-bug-fixes') : ''}`
            );
        }

        this.config.list = this._updatesList;
    }

    public config: SupportGridview = new SupportGridview();

    constructor(
        private supportCadastroNovidadesService: SupportCadastroNovidadesService,
        private ngxLoader: NgxUiLoaderService,
        private translateService: TranslateService
    ) {
        this.config.set({
            actions: {
                edit: { enable: true },
                remove: { confirmationText: 'support.manutencao.cadastro-novidades.listagem.confirm-remover', enable: true }
            },
            emptyText: { isHtml: false, value: 'support.manutencao.cadastro-novidades.listagem.vazio' },
            list: [],
            table: {
                body: [
                    new SupportGridviewTableBodyCell('col-0-5', 'release_date', 'date'),
                    new SupportGridviewTableBodyCell('col-2 truncate-gridview', 'title'),
                    new SupportGridviewTableBodyCell('col-1-5', 'mudancas'),
                    new SupportGridviewTableBodyCell('col-0-5 d-flex flex-wrap align-items-center justify-content-center', 'is_active', 'boolean')
                ],
                header: [
                    { className: 'col-0-5', value: 'support.manutencao.cadastro-novidades.listagem.cabecalho.data' },
                    { className: 'col-2', value: 'support.manutencao.cadastro-novidades.listagem.cabecalho.titulo' },
                    { className: 'col-1-5', value: 'support.manutencao.cadastro-novidades.listagem.cabecalho.mudancas' },
                    { className: 'col-0-5 text-center white-space-nowrap', value: 'support.manutencao.cadastro-novidades.listagem.cabecalho.publicado' },
                    { className: 'col-0-5', value: 'support.manutencao.cadastro-cnpj.tabela.cabecalho.editar' }
                ]
            }
        });
    }

    onEdit(update: Update) {
        this.editUpdate.emit(update)
    }

    onRemove(novidade: any): void {
        this.ngxLoader.startLoader('loader-principal')
        this.supportCadastroNovidadesService.deleteUpdate(novidade.item.id).subscribe({
            next: (response: ApiResponseModel<boolean>) => {
                if (response.success)
                    this.updatesList = this.updatesList.filter(update => update.id !== novidade.item.id);

                this.ngxLoader.stopLoader('loader-principal')
            }
        })
    }
}
