<div class="avaliacao-vendedores">
    <app-header
        [title]="'support.avaliacao-vendedores.titulo' | translate"
        [hasBackButton]="false"
    >
        <div class="mb-3 form-group col">
            <label>Fornecedor</label>
            <select class="form-select form-select-sm search-holding" [(ngModel)]="fornecedorSelecionado">
                <option value="0">{{ 'support.avaliacao-vendedores.selecione.fornecedor' | translate}}</option>
                @for (item of fornecedoresHoldings; track item) {
                    <option value="{{item.holding_id}}">{{item.fornecedor}}</option>
                }
            </select>
        </div>
        <div class="mb-3 form-group col">
            <label>Vendedor</label>
            <select class="form-select form-select-sm search-user" [(ngModel)]="vendedorSelecionado" [disabled]="fornecedorSelecionado && fornecedorSelecionado > 0 ? null : 'disabled'">
                <option value="0">{{ 'support.avaliacao-vendedores.selecione.vendedor' | translate}}</option>
                @for (item of vendedoresFornecedor; track item) {
                    <option value="{{item.seller_company_id}}">{{item.vendedor}}</option>
                }
            </select>
        </div>
        <div class="mb-3 form-group input-group-sm col">
            <label>Cotação</label>
            <input type="text" class="form-control form-control-sm" maxlength="6" ui-number-mask="0" min="100000" max="999999" [(ngModel)]="cotacaoSelecionada" [readonly]="!fornecedorSelecionado || fornecedorSelecionado <= 0 ? null : 'disabled'" />
        </div>
        <div class="mb-3 form-group col-0-5 col-btn">
            <input type="button" class="btn btn-primary" value="Pesquisar" (click)="pesquisar()" [disabled]="(!fornecedorSelecionado || fornecedorSelecionado <= 0) && !cotacaoSelecionada ? 'disabled' : null" />
        </div>
    </app-header>

    <div class="lista">
        <div class="card col-md-8 offset-md-2">
            @if (avaliacoes.length <= 0) {
                <div class="vazio mt30">
                    {{'support.avaliacao-vendedores.lista.vazio' | translate}}
                </div>
            } @else {
                <section class="lista-vendedores d-flex flex-wrap striped hover">
                    <header class="linha">
                        <div class="col text-center">{{'support.avaliacao-vendedores.lista.cabecalho.fornecedor' | translate}}</div>
                        <div class="col text-center">{{'support.avaliacao-vendedores.lista.cabecalho.vendedor' | translate}}</div>
                        <div class="col text-center d-flex flex-wrap">{{'support.avaliacao-vendedores.lista.cabecalho.pedidos' | translate}} <small>{{'support.avaliacao-vendedores.lista.cabecalho.tipos' | translate}}</small></div>
                        <div class="col text-center d-flex flex-wrap">{{'support.avaliacao-vendedores.lista.cabecalho.taxa-entrega-prazo' | translate}} <small>{{'support.avaliacao-vendedores.lista.cabecalho.tipos' | translate}}</small></div>
                        <div class="col text-center d-flex flex-wrap">{{'support.avaliacao-vendedores.lista.cabecalho.taxa-avaliacao' | translate}} <small>{{'support.avaliacao-vendedores.lista.cabecalho.tipos' | translate}}</small></div>
                    </header>
                    <ng-scrollbar class="corpo">
                        @for (avaliacao of avaliacoes; track avaliacao) {
                            <div class="linha">
                                <div class="col truncate">{{avaliacao.fornecedor}}</div>
                                <div class="col truncate">{{avaliacao.vendedor}}</div>
                                <div class="col text-end">{{avaliacao.total_pedidos}} <small>({{avaliacao.total_pedidos_ultimos_90}})</small></div>
                                <div class="col text-end">{{avaliacao.taxa_entrega_prazo}} <small>({{avaliacao.taxa_entrega_prazo_ultimos_90}})</small></div>
                                <div class="col text-end">{{avaliacao.taxa_avaliacoes_positivas}} <small>({{avaliacao.taxa_avaliacoes_positivas_ultimos_90}})</small></div>
                            </div>
                        }
                    </ng-scrollbar>
                </section>
            }
        </div>
    </div>

</div>

<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
