import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from 'app/_services/base.service';
import { SettingsService } from "app/_services/settings.service";
import { CompanyCnpj } from "./_models/company-model";

@Injectable({ providedIn: 'root' })
export class CadastroCNPJService extends BaseService {
    private _domain: string = '/support/company-cnpjs';

    constructor(private config: SettingsService) {
        super();
    }

    public getAll(): Observable<any> {
        return this.config.executeGet({ url: this._domain });
    }

    public getAllCompaniesWithoutCnpj(companyId: number): Observable<any> {
        return this.config.executeGet({ url: `${this._domain}/${companyId}/without-cnpj` });
    }

    public delete(companyId: number) {
        return this.config.executeDelete({ url: `${this._domain}/${companyId}` });
    }

    public save(companyId: number, companiesCnpj: CompanyCnpj[]) {
        const url = `${this._domain}/${companyId}`
        const body = { "listaCnpjs": companiesCnpj };
        return this.config.executePost({ url, body });
    }
}
