<div class="cadastro-novidades" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
    <app-header
        [hasBackButton]="createUpdate"
        [hasSearchButton]="false"
        [searchPlaceholder]="!createUpdate && 'support.manutencao.cadastro-novidades.filtro' | translate"
        [searchInput]="filter"
        [title]="'support.manutencao.cadastro-novidades.titulo' | translate"
        (onGoBack)="closeUpdateCreation()"
        (onSearch)="onSearch($event)"
    >
        @if (!createUpdate) {
            <button class="btn btn-primary" (click)="openUpdateCreation(null)">
                <i-feather name="plus"></i-feather>
                <span>{{ 'support.manutencao.cadastro-novidades.adicionar-novidade' | translate}}</span>
            </button>
        }
    </app-header>
    <div class="corpo-pagina col-md-8 offset-md-2">
        <div class="card card-default">
            @if (!createUpdate) {
                <app-cadastro-novidades-list [(updatesList)]="updatesListFiltered" (editUpdate)="openUpdateCreation($event)"></app-cadastro-novidades-list>
            } @else {
                <app-cadastro-novidades-create [selectedUpdate]="selectedUpdate" (closeUpdateCreation)="closeUpdateCreation()"></app-cadastro-novidades-create>
            }
        </div>
    </div>
</div>

<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
