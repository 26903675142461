import { Router, ActivatedRouteSnapshot, NavigationStart, NavigationEnd, RouterStateSnapshot } from '@angular/router';

import { AccountService } from 'app/account/account.service';

import { LocalStorageUtils } from 'app/_utils/localstorage';
import { Page } from 'app/_models/page';
import { environment } from 'app/../environments/environment'

declare const findInArrayIndex: any;
declare const isEmpty: any;

export class BaseGuard {
    protected localStorageUtils = new LocalStorageUtils();
    private jaRedirecionouPraHome: boolean;

    constructor(protected router: Router, protected accountService: AccountService) { 
        let maintenance = environment.inMaintenance;

        if (maintenance)
            this.navegarManutencao();
    }

    protected navegarAcessoNegado() {
        this.router.navigate(['/acesso-negado']);
    }

    protected navegarHomePage(urlHomePage: string) {
        this.router.navigate([urlHomePage]);
    }

    protected navegarManutencao() {
        this.router.navigate(['/em-manutencao']);
    }

    protected navegarLogin(routeAc: ActivatedRouteSnapshot = null) {
        this.accountService.logout(true);

        var returnUrl = window.location.pathname;

        if (isEmpty(routeAc))
            this.router.navigate(['/login/'], { queryParams: { returnUrl: returnUrl } });
        else
            this.router.navigate(['/login/'], { queryParams: { token: routeAc.queryParams.token, user_id: routeAc.queryParams.user_id, returnUrl: returnUrl } });
    }

    protected validarAcesso(routeAc: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.validarAcessoUserId(routeAc, routeAc.queryParams.user_id, state);
    }

    protected validarAcessoUserId(routeAc: ActivatedRouteSnapshot, user_id: number, state: RouterStateSnapshot): boolean {
        var usuario = this.accountService.obterUsuario();

        // Se o id do usuário que vier por parametro for diferente de nulo e diferente do usuário logado, retorna false
        if (!usuario || (!isEmpty(user_id) && user_id != usuario.id)) {
            this.navegarLogin(routeAc);
            return false;
        }

        let pages: Page[] = usuario.pages;
        let homePage = usuario.homePage;

        if (isEmpty(pages) || pages.length == 0 || isEmpty(homePage))
            this.navegarLogin();

        var paginaAtual = state.url;

        if (paginaAtual === '')
            paginaAtual = homePage;

        if (paginaAtual === 'login' || paginaAtual === 'esqueci-senha' || paginaAtual === 'alterar-senha')
            return true;

        // Verifica se o usuário tem permissão para acessar a tela atual
        var temAcesso = usuario.pages.some(p => paginaAtual.startsWith(`/${p.url}`));

        // Se não tem acesso
        if (!temAcesso) {
            // Verifica se existem telas filhas para essa página
            let childrenPagesList = usuario.pages.map(a => {
                if (a.childrenPages) {
                    for (var i = 0; i <= a.childrenPages.length - 1; i++) {
                        a.childrenPages[i] = a.url + '/' + a.childrenPages[i];
                    }
                }

                return a.childrenPages;
            });

            let listaSubTelas = usuario.pages.map(a => {
                if (a.subPages) {
                    for (var i = 0; i <= a.subPages.length - 1; i++) {
                        a.subPages[i] = a.url + '/' + a.subPages[i];
                    }
                }

                return a.subPages;
            });

            let childrenPages = [].concat.apply([], childrenPagesList);
            childrenPages = childrenPagesList.concat.apply(childrenPagesList, listaSubTelas);

            paginaAtual = (paginaAtual.match(new RegExp("/", "g")) || []).length < 3 ? paginaAtual : paginaAtual.substring(0, paginaAtual.lastIndexOf('/'));

            temAcesso = childrenPages.includes(paginaAtual);
        }

        // Se continua sem acesso
        if (!temAcesso && !this.jaRedirecionouPraHome) {
            this.navegarHomePage(usuario.homePage);
            this.jaRedirecionouPraHome = true;
        }

        return true;
    }
}
