import { AbstractControl, ValidatorFn, FormArray } from '@angular/forms';

export function CnpjDuplicadoValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!control.parent)
        return null;

    const cnpj = control.value;
    const id = control.parent.controls['id'].value;
    const parent = control.parent instanceof FormArray ? control.parent as FormArray : control.parent.parent as FormArray;

    if (!parent || !cnpj) {
      return null;
    }
    
    const allControls = parent.controls.map(c => c['controls']);

    const cnpjs = allControls.filter(c => c.cnpj.value === cnpj && c.id.value !== id).map(c => c.cnpj.value);
    const hasDuplicatedCnpj = cnpjs.length >= 1;

    if (hasDuplicatedCnpj) {
      return { 'cnpjDuplicado': true };
    }

    return null;
  };
}