<div class="cadastro-gestor-whatsapp" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
    <app-header
        [hasBackButton]="false"
        [hasButtons]="false"
        [hasSearchButton]="false"
        [searchInput]="searchTerm"
        [searchPlaceholder]="'support.manutencao.cadastro-gestor-whatsapp.filtro' | translate"
        [title]="'support.manutencao.cadastro-gestor-whatsapp.titulo' | translate"
        (onSearch)="searchTerm = $event"
    ></app-header>

    <div class="corpo-pagina">
        <div class="card card-default">
            <form [formGroup]="repairersForm">
                @if (repairerList?.controls?.length > 0) {
                    <section class="d-flex flex-wrap striped hover bordered">
                        <header>
                            <div class="col-2">{{'support.manutencao.cadastro-gestor-whatsapp.tabela.cabecalho.oficina' | translate}}</div>
                            <div class="col text-center col-0-25">{{'support.manutencao.cadastro-gestor-whatsapp.tabela.cabecalho.ativo' | translate}}</div>
                            <div class="col">{{'support.manutencao.cadastro-gestor-whatsapp.tabela.cabecalho.gestor' | translate}}</div>
                            <div class="col">{{'support.manutencao.cadastro-gestor-whatsapp.tabela.cabecalho.whatsapp' | translate}}</div>
                            <div class="col"></div>
                        </header>
                        <ng-scrollbar class="corpo" formArrayName="repairers">
                            @for (repairer of repairerList.controls; track repairer; let i = $index) {
                                <div class="linha" [formGroupName]="i">
                                    <div class="col-2">{{repairer.get('name_formated').value}}</div>
                                    <div class="col text-center col-0-25">
                                        <div class="form-check c-checkbox needsclick checkboxcot mt0 mb0">
                                            <label class="needsclick" style="margin-top: 10px;">
                                                <input type="checkbox" (click)="toggle(i)" formControlName="is_active" />
                                                <span class="fa fa-check"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <input type="text" class="form-control form-control-sm text-capitalize" formControlName="manager_name" [ngClass]="{'is-invalid': displayMessage.manager_name }" maxlength="50" />
                                        @if (displayMessage.manager_name) {
                                            <span class="text-danger">
                                                <p [innerHTML]="displayMessage.manager_name"></p>
                                            </span>
                                        }
                                    </div>
                                    <div class="col">
                                        <input type="text" class="form-control form-control-sm" formControlName="manager_whatsapp" [ngClass]="{'is-invalid': displayMessage.manager_whatsapp }" [textMask]="{mask: MASKS.phoneNumber.textMaskFunction}" />
                                        @if (displayMessage.manager_whatsapp) {
                                            <span class="text-danger">
                                                <p [innerHTML]="displayMessage.manager_whatsapp"></p>
                                            </span>
                                        }
                                    </div>
                                    <div class="col text-start action">
                                        <button class="btn btn-light btn-sm repairer_{{i}}" [disabled]="repairer.get('is_central').value" (click)="removeRepairer(i)">{{'elemento.generico-botao.remover' | translate}}</button>
                                        &nbsp;
                                        <button class="btn btn-primary btn-sm repairer_{{i}}" [disabled]="!repairer.valid" (click)="save(repairer, i)">{{'elemento.generico-botao.atualizar' | translate}}</button>
                                        &nbsp;
                                        <div class="fa fa-spinner spin repairer_{{i}}" style="display: none;"><div></div></div>
                                        <i class="fa fa-check text-success repairer_{{i}}" style="display: none;"></i>
                                        <i class="fa fa-times text-danger repairer_{{i}}" style="display: none;"></i>
                                    </div>
                                </div>
                            }
                        </ng-scrollbar>
                    </section>
                } @else {
                    <div class="vazio">
                        {{ 'support.manutencao.cadastro-gestor-whatsapp.tabela.vazio' | translate}}
                    </div>
                }

            </form>

        </div>
    </div>
</div>

<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
