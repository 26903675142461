export interface CotacaoSemaforoVendedor {
    quotation_id: number;
    supplier_name: string;
    seller_company_id: number;
    seller_name: string;
    situacao: string;
    status_id: CotacaoSemaforoVendedorStatusEnum;
    bloqueado: boolean;

    isIncluded: boolean;
    removeAnswer: boolean;
}

export enum CotacaoSemaforoVendedorStatusEnum {
    NaoParticipando = 1,
    Participando = 2,
    Respondido = 3,
}

export function isParticipando(statusId: CotacaoSemaforoVendedorStatusEnum): boolean {
    let status = [CotacaoSemaforoVendedorStatusEnum.Participando, CotacaoSemaforoVendedorStatusEnum.Respondido]
    return status.includes(statusId);
}
