<div class="gerenciar-vendedores" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
    <app-header
        [title]="'support.gerenciar-vendedores.titulo' | translate"
        [hasBackButton]="false"
    >
        @if (holdingSelecionadaId > 0) {
            <div class="input-group holding-is-billable col-md-2">
                <div>
                    @if (holdingSelecionada.status_pagamento === 1) {
                        {{ 'support.gerenciar-vendedores.mensagens.holding_is_billable' | translate}}
                    }
                    @if (holdingSelecionada.status_pagamento === 2) {
                        {{ 'support.gerenciar-vendedores.mensagens.holding_is_net_free' | translate}}
                    }
                    @if (holdingSelecionada.status_pagamento > 2) {
                        {{ 'support.gerenciar-vendedores.mensagens.holding_is_not_billable' | translate}}
                    }
                </div>
            </div>
        }
        <div>
            <div class="ng-autocomplete">
                <ng-autocomplete [data]="holdings"
                                [searchKeyword]="keyword"
                                (selected)='holdingsSelectEvent($event)'
                                [itemTemplate]="holdingsTemplate"
                                [notFoundTemplate]="notFoundTemplate"
                                [notFoundText]="notFoundText">
                </ng-autocomplete>
                <ng-template #holdingsTemplate let-item>
                    <a [innerHTML]="item.name"></a>
                </ng-template>
                <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                </ng-template>
            </div>
        </div>

        <div>
            <button type="button" class="btn btn-primary btn-sm btn-listar" (click)="listarEmpresasAssociadas(holdingSelecionadaId)" [disabled]="holdingSelecionadaId == 0">{{ 'support.gerenciar-vendedores.listar-empresas' | translate}}</button>
            <button type="button" class="btn btn-light btn-sm btn-csv" (click)="exportarCSV()" [disabled]="holdingSelecionadaId == 0">{{ 'support.gerenciar-vendedores.baixar-csv' | translate}}</button>
        </div>
    </app-header>

    <app-gerenciar-vendedores-interno [holdingSelecionada]="holdingSelecionada" [isFromSupport]="true"></app-gerenciar-vendedores-interno>
</div>

<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
