<div class="limpar-tela-vendedor" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
    <app-header
        [title]="'support.manutencao.limpar-tela-vendedor.titulo' | translate"
        [hasBackButton]="false"
        [hasButtons]="false"
        [hasMinCharacters]="false"
        [hasSearchButton]="true"
        [searchPlaceholder]="'support.manutencao.limpar-tela-vendedor.id-vendedor' | translate"
        searchType="number"
        (onSearch)="getSeller($event, 0)"
    ></app-header>
    <div class="corpo-pagina col-md-8 offset-md-2">

        <div class="card card-default">
            <app-support-gridview [config]="config" (onRemove)="onCleanPage($event)"></app-support-gridview>
        </div>

    </div>
</div>

<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
