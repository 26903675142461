import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { NotificationService } from '../../_services/notification.service';
import { SupportVoltarCotacaoService } from './voltar-cotacao.service';

import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { CotacaoVoltar } from './_models/cotacaoVoltar';
import { Globals } from '../../_models/globals';
import { SupportGridview, SupportGridviewTableBodyCell, SupportGridviewTemplateCell } from '../_models/supportGridview';
import { SupportMaintenanceGetListarVoltarCotacaoResponseModel } from './_models/supportMaintenanceGetListarVoltarCotacaoResponseModel';

@Component({ selector: 'app-voltar-cotacao', templateUrl: './voltar-cotacao.component.html', styleUrls: ['./voltar-cotacao.component.scss'] })
export class SupportVoltarCotacaoComponent implements AfterViewInit {
    @ViewChild('pecasTemplate') pecasTemplate: TemplateRef<any>;
    @ViewChild('situacaoTemplate') situacaoTemplate: TemplateRef<any>;
    templatesArray: SupportGridviewTemplateCell[] = [];

    public config: SupportGridview = new SupportGridview();

    constructor(
        private notificationService: NotificationService,
        private voltarCotacaoService: SupportVoltarCotacaoService,
        private globals: Globals,
        private ngxLoader: NgxUiLoaderService) { }

    ngAfterViewInit() {
        setTimeout(() => {
            this.templatesArray = [ { name: 'pecasTemplate', template: this.pecasTemplate }, { name: 'situacaoTemplate', template: this.situacaoTemplate } ];

            this.config.set({
                actions: {
                    edit: { confirmationText: 'support.manutencao.voltar-cotacao.mensagens.confirm.voltar', enable: true, labelText: 'elemento.generico-botao.voltar', type: 'button' }
                },
                emptyText: { value: 'support.manutencao.voltar-cotacao.nenhuma-cotacao-encontrada' },
                list: [],
                table: {
                    body: [
                        new SupportGridviewTableBodyCell('col text-center', 'quotation_id'),
                        new SupportGridviewTableBodyCell('col col-4 truncate-gridview', 'veiculo'),
                        new SupportGridviewTableBodyCell('col text-center', '', 'html', null, 'situacaoTemplate'),
                        new SupportGridviewTableBodyCell('col col-2 text-center', 'data_criacao', 'date', 'dd/MM/yyyy HH:mm'),
                        new SupportGridviewTableBodyCell('col text-center', '', 'html', null, 'pecasTemplate')
                    ],
                    header: [
                        { className: 'col text-center', value: 'support.manutencao.voltar-cotacao.tabela.quotation-id' },
                        { className: 'col-4', value: 'support.manutencao.voltar-cotacao.tabela.veiculo' },
                        { className: 'col', value: 'support.manutencao.voltar-cotacao.tabela.situacao.titulo' },
                        { className: 'col-2 text-center', value: 'support.manutencao.voltar-cotacao.tabela.data-criacao' },
                        { className: 'col text-center', value: 'support.manutencao.voltar-cotacao.tabela.pecas' },
                        { className: 'col-0-5', value: '' }
                    ]
                }
            });
        });
    }

    getAll(licensePlate: string, nAttempts: number) {
        this.ngxLoader.startLoader('loader-principal');

        this.voltarCotacaoService.getQuotationsByLicensePlate(licensePlate).subscribe({
            next: (response: ApiResponseModel<SupportMaintenanceGetListarVoltarCotacaoResponseModel>) => {
                if (response && response.success) {
                    let cotacoes = response.result.cotacoes;

                    cotacoes.forEach(c => {
                        let pecas = c.pecas.map(p => p.nome);
                        c.pecas_title = pecas.join('<br />');
                    });

                    this.config.list = cotacoes;
                }

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.ngxLoader.stopLoader('loader-principal');
                    this.notificationService.showErrorToastr(this.globals.translate('support.manutencao.voltar-cotacao.mensagens.erro.carregamento.corpo'));
                    return;
                }

                this.getAll(licensePlate, ++nAttempts);
            }
        });
    }

    onExecute($event: CotacaoVoltar) {
        this.voltarCotacao($event.quotation_id, $event.excluida, 0);
    }

    onSearch($event: any) {
        this.getAll($event, 0);
    }

    voltarCotacao(quotationId: number, excluida: boolean, nAttempts: number) {
        this.ngxLoader.startLoader('loader-principal');

        this.voltarCotacaoService.setQuotationBack(quotationId, excluida).subscribe({
            next: () => {
                let indice = this.config.list.findIndex(c => c.quotation_id === quotationId);
                this.config.list.splice(indice, 1);

                this.notificationService.showSuccessToastr(this.globals.translate('support.manutencao.voltar-cotacao.mensagens.sucesso.voltar.corpo'));
                this.ngxLoader.stopLoader('loader-principal');
            },
            error: (error) => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.ngxLoader.stopLoader('loader-principal');
                    this.notificationService.showErrorToastr(this.globals.translate('support.manutencao.voltar-cotacao.mensagens.erro.voltar.corpo'));
                    return;
                }

                this.voltarCotacao(quotationId, excluida, ++nAttempts);
            }
        });
    }
}
