import { Component, OnInit, TemplateRef, Input, OnChanges, SimpleChanges, ViewChild, EventEmitter, Output } from '@angular/core';

import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { CotacaoSemaforoVendedor, isParticipando } from './_models/cotacaoSemaforoVendedor';
import { Globals } from 'app/_models/globals';

import { NotificationService } from 'app/_services/notification.service';
import { SupportCotacoesService } from 'app/support/cotacoes/cotacoes.service';

declare const isEmpty: any;

@Component({selector: 'app-alterar-vendedores', templateUrl: './alterar-vendedores.component.html', styleUrls: ['./alterar-vendedores.component.scss']})
export class SupportAlterarVendedoresComponent implements OnInit, OnChanges {
    @ViewChild('template') template: TemplateRef<any>;
    @Input() cotacao: any;
    @Input() visivel: boolean;
    @Output() fechar = new EventEmitter<any>();

    atualizar: boolean;
    loading: boolean;
    modalRef: BsModalRef;
    vendedores: CotacaoSemaforoVendedor[];

    constructor(
        private notificationService: NotificationService,
        private supportCotacoesService: SupportCotacoesService,
        private globals: Globals,
        private modalService: BsModalService) { }

    ngOnInit() {
        this.loading = false;
        this.vendedores = [];
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!isEmpty(changes.visivel)) {
            if (changes.visivel.currentValue)
                this.abrirModal(0);
            else if (this.modalRef)
                this.modalRef.hide();
        }
    }

    abrirModal(nAttempts: number) {
        this.atualizar = false;
        let options: ModalOptions = { backdrop: 'static', class: 'gray modal-lg', keyboard: false };
        this.modalRef = this.modalService.show(this.template, options);

        this.loadSellers(nAttempts);
    }

    atualizarVendedor(vendedor: CotacaoSemaforoVendedor) {
        vendedor.isIncluded = isParticipando(vendedor.status_id);
        vendedor.removeAnswer = vendedor.status_id === 3;
    }

    acao_apagar_resposta(vendedor: CotacaoSemaforoVendedor, nAttempts: number) {
        this.loading = true;

        this.supportCotacoesService.deleteAnswer(vendedor.quotation_id, vendedor.seller_company_id).subscribe({
            next: (response: ApiResponseModel<boolean>) => {
                if (response && response.success) {
                    vendedor.status_id = 2;
                    vendedor.situacao = 'Pendente';
                    this.atualizarVendedor(vendedor);

                    this.notificationService.showSuccessToastr(this.globals.translate('support.cotacoes.modal.alterar-vendedores.mensagens.sucesso.apagar-resposta.corpo'));
                    this.atualizar = true;
                    this.loading = false;
                }
            },
            error: (error: any) => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.notificationService.showErrorToastr(this.globals.translate('support.cotacoes.modal.alterar-vendedores.mensagens.erro.apagar-resposta.corpo'));
                    this.loading = false;
                    return;
                }

                this.acao_apagar_resposta(vendedor, ++nAttempts);
            }
        });
    }

    fecharModal() {
        this.fechar.emit({ atualizar: this.atualizar });
    }

    handleSellerInclusion(vendedor: CotacaoSemaforoVendedor) {
        if (vendedor.isIncluded)
            return this.removeSeller(vendedor);

        return this.includeSeller(vendedor);
    }

    includeSeller(vendedor: CotacaoSemaforoVendedor) {
        this.loading = true;

        this.supportCotacoesService.includeSeller(vendedor.quotation_id, vendedor.seller_company_id).subscribe({
            next: (response: ApiResponseModel<boolean>) => {
                if (response && response.success) {
                    this.loadSellers(0);
                    this.notificationService.showSuccessToastr(this.globals.translate('support.cotacoes.modal.alterar-vendedores.mensagens.sucesso.incluir.corpo'));
                    this.loading = false;
                    this.atualizar = true;
                }
            },
            error: () => {
                this.notificationService.showErrorToastr(this.globals.translate('support.cotacoes.modal.alterar-vendedores.mensagens.erro.incluir.corpo'));
                this.loading = false;
                return;
            }
        });
    }

    loadSellers(nAttempts: number) {
        this.loading = true;

        this.supportCotacoesService.getAllSellersByQuotationId(this.cotacao.quotation_id).subscribe({
            next: (response: ApiResponseModel<{ vendedores: CotacaoSemaforoVendedor[] }>) => {
                if (response && response.success) {
                    let vendedores = response.result.vendedores;
                    vendedores.map(v => this.atualizarVendedor(v));

                    this.vendedores = vendedores;
                    this.loading = false;
                }
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.notificationService.showErrorToastr(this.globals.translate('support.cotacoes.modal.alterar-vendedores.mensagens.erro.listar.corpo'));
                    this.loading = false;
                    return;
                }

                this.loadSellers(++nAttempts);
            }
        });
    }

    removeSeller(vendedor: CotacaoSemaforoVendedor) {
        this.loading = true;

        this.supportCotacoesService.removeSeller(vendedor.quotation_id, vendedor.seller_company_id).subscribe({
            next: (response: ApiResponseModel<boolean>) => {
                if (response && response.success) {
                    this.loadSellers(0);
                    this.notificationService.showSuccessToastr(this.globals.translate('support.cotacoes.modal.alterar-vendedores.mensagens.sucesso.remover.corpo'));
                    this.loading = false;
                    this.atualizar = true;
                }
            },
            error: () => {
                this.notificationService.showErrorToastr(this.globals.translate('support.cotacoes.modal.alterar-vendedores.mensagens.erro.remover.corpo'));
                this.loading = false;
                return;
            }
        });
    }
}
