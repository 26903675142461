import { Component, ElementRef, OnInit, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControlName, FormGroup, Validators } from '@angular/forms';
import { NgxBrazilMASKS, NgxBrazilValidators } from 'ngx-brazil';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, fromEvent, merge } from 'rxjs';

import { DisplayMessage, GenericValidator, ValidationMessages } from '../../_utils/generic-form-validation';

import { NotificationService } from '../../_services/notification.service';
import { SupportCadastroGestorWhatsappService } from './cadastro-gestor-whatsapp.service';

import { Globals } from '../../_models/globals';
import { OficinaGestorWhatsapp } from './_models/oficina-gestor-whatsapp';
import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { SupportManagerWhatsappGetAllResponseModel } from './_models/supportManagerWhatsappGetAllResponseModel';

declare const $: any;

@Component({ selector: 'app-cadastro-gestor-whatsapp', templateUrl: './cadastro-gestor-whatsapp.component.html', styleUrls: ['./cadastro-gestor-whatsapp.component.scss'] })
export class SupportCadastroGestorWhatsappComponent implements OnInit {
    repairers: OficinaGestorWhatsapp[];
    repairerList: FormArray;
    repairersForm: FormGroup;
    displayMessage: DisplayMessage = {};
    errors: any[] = [];
    genericValidator: GenericValidator;
    validationMessages: ValidationMessages;
    @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
    _searchString: string = '';

    public MASKS: any = NgxBrazilMASKS;

    constructor(
        private notificationService: NotificationService,
        private cadastroGestorWhatsappService: SupportCadastroGestorWhatsappService,
        private globals: Globals,
        private fb: FormBuilder,
        private ngxLoader: NgxUiLoaderService) { }

    ngOnInit(): void {
        this.repairersForm = this.fb.group({
            repairers: this.fb.array([])
        });

        this.repairerList = this.repairersForm.get('repairers') as FormArray;

        this.validationMessages = {
            manager_name: { required: this.globals.translate('support.manutencao.tabela.campos.manager-name.obrigatorio'), maxLength: this.globals.translate('support.manutencao.tabela.campos.manager-name.tamanho'), pattern: this.globals.translate('support.manutencao.tabela.campos.manager-name.somente-letras') },
            manager_whatsapp: { required: this.globals.translate('support.manutencao.tabela.campos.manager-whatsapp.obrigatorio'), celular: this.globals.translate('support.manutencao.tabela.campos.manager-whatsapp.formato-invalido') }
        };

        this.genericValidator = new GenericValidator(this.validationMessages);

        this.getList();
    }

    addRepairer(repairer: OficinaGestorWhatsapp) {
        const repairerGroup = this.fb.group({
            user_company_id: [repairer.user_company_id],
            repairer_name: [repairer.repairer_name],
            user_fullname: [repairer.user_fullname],
            manager_name: [{ value: repairer.is_active ? repairer.manager_name : '', disabled: !repairer.is_active }, [Validators.required, Validators.maxLength(50), Validators.pattern('^[a-zA-ZçÇáãéíóôÁÃÉÍÓÔ ]*$')]],
            manager_whatsapp: [{ value: repairer.is_active ? repairer.manager_whatsapp : '', disabled: !repairer.is_active }, [Validators.required, NgxBrazilValidators.phoneNumber]],
            name_formated: [repairer.name_formated],
            is_active: [repairer.is_active],
            is_central: [repairer.is_central]
        });

        this.repairerList.push(repairerGroup);
    }

    buildRepairerList() {
        this.repairerList.clear();

        const term: string = this._searchString.toLowerCase();

        for (let repairer of this.repairers.filter(r => r.name_formated.toLowerCase().includes(term))) {
            this.addRepairer(repairer);
        }
    }

    getList() {
        this.ngxLoader.startLoader('loader-principal');

        this.cadastroGestorWhatsappService.getRepairerList().subscribe({
            next: (ret: ApiResponseModel<SupportManagerWhatsappGetAllResponseModel>) => {
                this.ngxLoader.stopLoader('loader-principal');

                if (ret != null && ret.success) {
                    this.repairers = ret.result.managers;

                    this.buildRepairerList();
                }
            },
            error: () => {
                this.notificationService.showErrorToastr(this.globals.translate('support.manutencao.cadastro-gestor-whatsapp.mensagens.listar.erro.corpo'));
                this.ngxLoader.stopLoader('loader-principal');
            }
         });
    }

    async removeRepairer(index: number) {
        if (!await this.notificationService.showConfirmationDialog('support.manutencao.cadastro-gestor-whatsapp.mensagens.remover.confirm'))
            return;

        this.startLoading(index);

        this.cadastroGestorWhatsappService.removeRepairer(this.repairers[index].user_company_id).subscribe({
            next: () => {
                this.stopLoading(index, true, false);
                this.notificationService.showSuccessToastr(this.globals.translate('support.manutencao.cadastro-gestor-whatsapp.mensagens.remover.sucesso.corpo'));

                setTimeout(() => {
                    const listIndex = this.repairers.findIndex(r => r.user_company_id == this.repairerList.at(index).get('user_company_id').value);
                    this.repairers.splice(listIndex, 1);
                    this.buildRepairerList();
                }, 500);
            },
            error: () => {
                this.stopLoading(index, false, false);
                this.notificationService.showErrorToastr(this.globals.translate('support.manutencao.cadastro-gestor-whatsapp.mensagens.remover.erro.corpo'));
            }
        });
    }

    startLoading(index: number) {
        $(`.spin.repairer_${index}`).show();
        $(`.btn.repairer_${index}`).prop('disabled', true);
    }

    stopLoading(index: number, success: boolean, isUpdate: boolean = false) {
        const type = success ? 'success' : 'danger';
        const spinnerName = `.text-${type}.repairer_${index}`;
        $(`.spin.repairer_${index}`).hide();
        $(spinnerName).show();

        setTimeout(() => {
            $(spinnerName).hide();
            const repairer = this.repairerList.at(index);
            const isCentral = repairer.get('is_central').value;

            if (isUpdate) {
                $(`.btn.btn-primary.repairer_${index}`).prop('disabled', false);

                if (!isCentral)
                    $(`.btn.btn-light.repairer_${index}`).prop('disabled', false);
            }
        }, 1000)
    }

    save(repairerControl: any, index: number) {
        this.displayMessage['repairersForm'] = '';

        if (this.repairerList.at(index).valid) {
            this.startLoading(index);

            const repairer: OficinaGestorWhatsapp = $.extend(true, {}, repairerControl.value);

            this.cadastroGestorWhatsappService.saveRepairer(repairer).subscribe({
                next: () => {
                    this.stopLoading(index, true, true);
                    this.notificationService.showSuccessToastr(this.globals.translate('support.manutencao.cadastro-gestor-whatsapp.mensagens.salvar.sucesso.corpo'));

                    this.repairerList.at(index).markAsPristine();
                },
                error: (error) => {
                    this.stopLoading(index, false, true);
                    console.log(error);
                    this.notificationService.showErrorToastr(this.globals.translate('support.manutencao.cadastro-gestor-whatsapp.mensagens.salvar.erro.corpo'));
                }
            });
        }
    }

    toggle (index: number) {
        const repairer = this.repairerList.at(index);
        const disabled = repairer.get('is_active').value;

        if (disabled) {
            repairer.get('manager_name').setValue('');
            repairer.get('manager_name').disable();
            repairer.get('manager_whatsapp').setValue('');
            repairer.get('manager_whatsapp').disable();
        } else {
            repairer.get('manager_name').enable();
            repairer.get('manager_whatsapp').enable();
        }
    }

    validate(): void
    {
        let controlBlur: Observable<any>[] = this.formInputElements.map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));
        merge(...controlBlur).subscribe(() => { this.displayMessage = this.genericValidator.processarMensagens(this.repairersForm); });
    }

    get searchTerm(): string
    {
        return this._searchString;
    }

    set searchTerm(value: string)
    {
        this._searchString = value;
        this.buildRepairerList();
    }
}
