import { Injectable } from '@angular/core';

import { SettingsService } from 'app/_services/settings.service';
import { Observable } from 'rxjs';

declare const formatDateToYYYYMMDD: any;

@Injectable({ providedIn: 'root' })
export class SupportFaturamentoService {
    private _domain: string = '/support/faturamento';

    constructor(private config: SettingsService) { }

    public getCurrentSituation(): Observable<any> {
        return this.config.executeGet({ url: `${this._domain}/current-situation` });
    }

    public getAllLastLogs(groupId: string, creationDate: Date): Observable<any> {
        let url = `${this._domain}/last-logs`;
        let parameters = [];

        if (groupId)
            parameters.push(`groupId=${groupId}`);

        if (creationDate)
        {
            const creationDateString = formatDateToYYYYMMDD(creationDate, '-');
            parameters.push(`creationDate=${creationDateString}`);
        }

        if (parameters.length > 0)
            url += `?${parameters.join('&')}`;

        return this.config.executeGet({ url });
    }

    public send(step: number): Observable<any> {
        return this.config.executePost({ url: `${this._domain}/send/${step}` });
    }
}
