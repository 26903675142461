import { Injectable } from "@angular/core";
import { BaseService } from 'app/_services/base.service';
import { SettingsService } from "app/_services/settings.service";
import { Observable } from 'rxjs';
import { Update } from 'app/_models/update';

@Injectable({ providedIn: 'root' })
export class SupportCadastroNovidadesService extends BaseService {
    private _domain: string = '/support/new-features';

    constructor(private config: SettingsService) {
        super();
    }

    public deleteUpdate(id: string): Observable<any> {
        return this.config.executeDelete({ url: `${this._domain}/${id}` });
    }

    public getUpdatesList(): Observable<any> {
        return this.config.executeGet({ url: `${this._domain}` });
    }

    public save(update: Update): Observable<any> {
        return this.config.executePost({ url: `${this._domain}`, body: update });
    }
}
