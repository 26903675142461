<ng-template #template>
    <div class="modal-header">
        <h3 class="modal-title float-start">{{ 'support.cotacoes.modal.alterar-vendedores.titulo' | translate }}{{cotacao?.quotation_id}}</h3>
        <button type="button" class="btn-close float-end" aria-label="Fechar" (click)="fecharModal()"></button>
    </div>
    <div class="modal-body">
        <h4>{{'support.cotacoes.modal.alterar-vendedores.veiculo' | translate}}: <span>{{cotacao?.veiculo}}</span></h4>

        <section class="lista-vendedores">
            <header>
                <div class="col">Empresa</div>
                <div class="col">Vendedor</div>
                <div class="col text-center">Status</div>
                <div class="col text-center">Ação</div>
            </header>
            <div class="lista-vendedores-body">
                <ng-scrollbar>
                    @for (vendedor of vendedores; track vendedor) {
                        <div class="linha" [ngClass]="{'bloqueado': vendedor.bloqueado}">
                            <div class="col">{{vendedor.supplier_name}}</div>
                            <div class="col">{{vendedor.seller_name}}</div>
                            <div class="col">{{vendedor.situacao}}</div>
                            <div class="col">
                                @if (!vendedor.bloqueado) {
                                    <button class="btn btn-light" (click)="handleSellerInclusion(vendedor)">
                                        {{('support.cotacoes.modal.alterar-vendedores.botao' + (vendedor.isIncluded ? '.remover' : '.incluir')) | translate}}
                                    </button>
                                    @if (vendedor.removeAnswer) {
                                        <i class="fa fa-trash" title="{{'support.cotacoes.modal.alterar-vendedores.apagar-resposta' | translate}}" (click)="acao_apagar_resposta(vendedor, 0)"></i>
                                    }
                                }
                            </div>
                        </div>
                    }
                </ng-scrollbar>
            </div>
        </section>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="fecharModal()">{{'support.cotacoes.modal.alterar-vendedores.botao.fechar' | translate}}</button>
    </div>
    <ngx-loading [show]="loading" [template]="templateAlterar"></ngx-loading>
    <ng-template #templateAlterar></ng-template>
</ng-template>
