import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';

import { InternalUser } from '../../_models/internalUser';
import { LocalStorageUtils } from 'app/_utils/localstorage';
import { RolesIdsEnum } from 'app/_models/roles';
import { SuportGridviewSort, SupportGridview, SupportGridviewTableBodyCell, SupportGridviewTemplateCell } from 'app/support/_models/supportGridview';

import { SupportGridviewComponent } from 'app/support/_componentes/gridview/gridview.component';

import { SupportUsuarioInternoService } from '../../usuario-interno.service';
import { Subscription } from 'rxjs';

@Component({ selector: 'app-usuario-interno-lista', templateUrl: './usuario-interno-lista.component.html', styleUrls: ['./usuario-interno-lista.component.scss'] })
export class SupportUsuarioInternoListaComponent implements AfterViewInit, OnDestroy {
    public config: SupportGridview = new SupportGridview();
    templatesArray: SupportGridviewTemplateCell[] = [];
    private _sortSubscription: Subscription;

    @ViewChild('buttonsTemplate') buttonsTemplate: TemplateRef<any>;
    @ViewChild('gridViewComponent') gridViewComponent: SupportGridviewComponent;
    @Output() onEditUser = new EventEmitter<any>();
    @Output() onEditProfile = new EventEmitter<any>();
    @Output() onReactivateProfile = new EventEmitter<any>();
    @Output() onRemoveProfile = new EventEmitter<any>();
    private localStorage: LocalStorageUtils;
    private rolesCentral: number[];
    roles: typeof RolesIdsEnum = RolesIdsEnum;

    @Input() get users(): InternalUser[] {
        return this.config.list;
    }
    set users (value: InternalUser[]) {
        this.config.list = value;
    }

    constructor(private supportUsuarioInternoService: SupportUsuarioInternoService) {
        this.localStorage = new LocalStorageUtils();
        this.rolesCentral = this.localStorage.listarRolesCentral();

        this._sortSubscription = this.supportUsuarioInternoService.sortDataValue$.subscribe(value => this.config.sort = value);

        this.config.set({
            actions: { edit: { enable: false }, remove: { enable: false } },
            emptyText: { isHtml: false, value: 'support.manutencao.usuario.interno.lista.mensagens.lista-usuario-vazia' },
            list: [],
            sort: this.supportUsuarioInternoService.getSortDataValue(),
            table: {
                body: [
                    new SupportGridviewTableBodyCell('col-0-5 text-center', 'userId'),
                    new SupportGridviewTableBodyCell('col-2 truncate-gridview', 'name'),
                    new SupportGridviewTableBodyCell('col', 'roleDescription'),
                    new SupportGridviewTableBodyCell('col col-0-5 icons', '', 'html', null, 'buttonsTemplate')
                ],
                header: [
                    { className: 'col-0-5 text-center', sortName: 'userId', value: 'support.manutencao.usuario.interno.lista.cabecalho.id' },
                    { className: 'col-2', sortName: 'name', value: 'support.manutencao.usuario.interno.lista.cabecalho.nome' },
                    { className: 'col', sortName: 'roleDescription', value: 'support.manutencao.usuario.interno.lista.cabecalho.perfil' },
                    { className: 'col-0-5 text-center', value: 'support.manutencao.usuario.interno.lista.cabecalho.acao' }
                ]
            }
        });
    }

    ngAfterViewInit(): void {
        setTimeout(() => { this.templatesArray = [ { name: 'buttonsTemplate', template: this.buttonsTemplate } ]; });
    }

    ngOnDestroy(): void {
        if (!this._sortSubscription?.closed)
            this._sortSubscription.unsubscribe();
    }

    onEdit(internalUser: InternalUser) {
        this.onEditUser.emit({ userId: internalUser.userId });
    }

    onEditUserProfile(internalUser: InternalUser) {
        this.onEditProfile.emit({ userId: internalUser.userId });
    }

    onReactivateUserProfile(internalUser: InternalUser) {
        this.onReactivateProfile.emit({ userId: internalUser.userId });
    }

    onRemoveUserProfile(internalUser: InternalUser) {
        this.onRemoveProfile.emit({ userId: internalUser.userId });
    }

    onSort(sortData: SuportGridviewSort) {
        this.supportUsuarioInternoService.setSortDataValue(sortData);
    }
}
