<div class="oficina-sem-documentacao" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
    <app-header
        [title]="'support.manutencao.oficina-sem-documentacao.titulo' | translate"
        [searchPlaceholder]="'support.manutencao.oficina-sem-documentacao.placeholder' | translate"
        (onSearch)="filtrarOficinas($event)"
        [hasBackButton]="false"
        [hasSearchButton]="false"
        [hasButtons]="false"
    ></app-header>
    <div class="corpo-pagina col-md-8 offset-md-2">

        <div class="card card-default">
            <app-support-gridview [config]="config"></app-support-gridview>
        </div>

    </div>
</div>

<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
