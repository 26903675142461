import { Injectable } from "@angular/core";
import { BaseService } from "app/_services/base.service";
import { SettingsService } from "app/_services/settings.service";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class SupportMultiloginService extends BaseService {
    private _domain: string = '/support/multilogin';

    constructor(private config: SettingsService) {
        super();
    }

    public getCompaniesByHolding(holdingId: number): Observable<any> {
        return this.config.executeGet({ url: `${this._domain}/companies/${holdingId}` });
    }

    public getHoldingsByType(isBuyer: boolean): Observable<any> {
        return this.config.executeGet({ url: `${this._domain}/holdings?isBuyer=${isBuyer}` });
    }

    public getUsersByCompany(companyId: string): Observable<any> {
        return this.config.executeGet({ url: `${this._domain}/users/${companyId}` });
    }

    public getUsersByUserGroup(userGroupId: string): Observable<any> {
        return this.config.executeGet({ url: `${this._domain}/${userGroupId}` });
    }

    public removeUserGroup(userGroupId: string): Observable<any> {
        return this.config.executeDelete({ url: `${this._domain}/${userGroupId}` });
    }

    public saveMultilogin(userGroupId: string, usersIds: number[]) {
        return this.config.executePost({ url: `${this._domain}`, body: { user_group_id: userGroupId, user_group_participants_ids: usersIds } });
    }
}
