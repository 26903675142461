import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ArrowLeft, Check, Edit, User, Plus, Trash, X } from 'angular-feather/icons';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { FeatherModule } from 'angular-feather';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { HttpClient } from '@angular/common/http';
import { NgbAccordionModule, NgbCollapseModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TooltipModule } from 'ng2-tooltip-directive';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxEditorModule } from 'ngx-editor';

import { SupportAdicionarComplementoComponent } from './_componentes/adicionar-complemento/adicionar-complemento.component';
import { SupportAlterarVendedoresComponent } from './_componentes/alterar-vendedores/alterar-vendedores.component';
import { SupportAvaliacaoVendedoresComponent } from './avaliacao-vendedores/avaliacao-vendedores.component';
import { SupportCadastroCnpjEdicaoComponent } from './cadastro-cnpj/_components/cadastro-cnpj-edicao/create.component';
import { SupportCadastroCnpjListComponent } from './cadastro-cnpj/_components/list/list.component';
import { SupportCadastroGestorWhatsappComponent } from './cadastro-gestor-whatsapp/cadastro-gestor-whatsapp.component';
import { SupportCadastroNovidadesComponent } from './cadastro-novidades/cadastro-novidades.component';
import { SupportCadastroNovidadesListComponent } from './cadastro-novidades/_components/list/list.component';
import { SupportCadastroNovidadesCreateComponent } from './cadastro-novidades/_components/create/create.component';
import { SupportContestacoesComponent } from './contestacoes/contestacoes.component';
import { SupportCotacoesComponent } from "./cotacoes/cotacoes.component";
import { SupportDiarioComponent } from './diario/diario.component';
import { SupportEditarCotacaoComponent } from './_componentes/editar-cotacao/editar-cotacao.component';
import { SupportEditarPecaComponent } from './_componentes/editar-peca/editar-peca.component';
import { SupportFaturamentoComponent } from './faturamento/faturamento.component';
import { SupportGridviewComponent } from './_componentes/gridview/gridview.component';
import { SupportGerenciarVendedoresComponent } from './gerenciar-vendedores/gerenciar-vendedores.component';
import { SupportLimparTelaVendedorComponent } from './limpar-tela-vendedor/limpar-tela-vendedor.component';
import { SupportMultiloginComponent } from './multilogin/multilogin.component';
import { SupportOficinaSemDocumentacaoComponent } from './oficina-sem-documentacao/oficina-sem-documentacao.component';
import { SupportPedidosComponent } from './pedidos/pedidos.component';
import { SupportUsuarioInternoComponent } from './usuario-interno/usuario-interno.component';
import { SupportUsuarioInternoEdicaoComponent } from './usuario-interno/_componentes/usuario-interno-edicao/usuario-interno-edicao.component';
import { SupportUsuarioInternoListaComponent } from './usuario-interno/_componentes/usuario-interno-lista/usuario-interno-lista.component';
import { SupportVoltarCotacaoComponent } from './voltar-cotacao/voltar-cotacao.component';

import { SettingsService } from 'app/_services/settings.service';
import { SharedModule } from 'app/_modules/shared.module';
import { SupportAvaliacaoVendedoresService } from './avaliacao-vendedores/avaliacao-vendedores.service';
import { SupportContestacoesService } from './contestacoes/contestacoes.service';
import { SupportCotacoesService } from "./cotacoes/cotacoes.service";
import { SupportDiarioService } from './diario/diario.service';
import { SupportFaturamentoService } from './faturamento/faturamento.service';
import { SupportGerenciarVendedoresService } from './gerenciar-vendedores/gerenciar-vendedores.service';
import { SupportOficinaSemDocumentacaoService } from './oficina-sem-documentacao/oficina-sem-documentacao.service';
import { SupportPedidosService } from './pedidos/pedidos.service';
import { SupportUsuarioInternoService } from './usuario-interno/usuario-interno.service';
import { SupportVoltarCotacaoService } from './voltar-cotacao/voltar-cotacao.service';
import { SupportCadastroCnpjComponent } from './cadastro-cnpj/cadastro-cnpj.component';

import { SupportGerenciarVendedoresModule } from './_componentes/gerenciar-vendedores/gerenciar-vendedores.module';
import { SuporteRoutingModule } from "./support.route";
import { NG_SCROLLBAR_OPTIONS, NgScrollbarModule } from 'ngx-scrollbar';

export function HttpLoaderFactory(http: HttpClient) { return new TranslateHttpLoader(http); }

const icons = { ArrowLeft, Check, Edit, User, Plus, Trash, X }

@NgModule({
    declarations: [
        SupportAdicionarComplementoComponent,
        SupportAlterarVendedoresComponent,
        SupportAvaliacaoVendedoresComponent,
        SupportCadastroGestorWhatsappComponent,
        SupportCadastroNovidadesComponent,
        SupportCadastroNovidadesCreateComponent,
        SupportCadastroNovidadesListComponent,
        SupportContestacoesComponent,
        SupportCotacoesComponent,
        SupportEditarCotacaoComponent,
        SupportEditarPecaComponent,
        SupportFaturamentoComponent,
        SupportDiarioComponent,
        SupportGerenciarVendedoresComponent,
        SupportGridviewComponent,
        SupportLimparTelaVendedorComponent,
        SupportMultiloginComponent,
        SupportOficinaSemDocumentacaoComponent,
        SupportPedidosComponent,
        SupportUsuarioInternoComponent,
        SupportUsuarioInternoEdicaoComponent,
        SupportUsuarioInternoListaComponent,
        SupportVoltarCotacaoComponent,
        SupportCadastroCnpjComponent,
        SupportCadastroCnpjEdicaoComponent,
        SupportCadastroCnpjListComponent
    ],
    imports: [
        SharedModule,
        CommonModule,
        NgxMaskDirective,
        NgxMaskPipe,
        AutocompleteLibModule,
        FilterPipeModule,
        FeatherModule.pick(icons),
        FormsModule,
        NgSelectModule,
        NgbModule,
        NgbAccordionModule,
        NgbCollapseModule,
        NgxEditorModule.forRoot({
            locals: {
                bold: 'Negrito',
                italic: 'Itálico',
                code: 'Código',
                underline: 'Sublinhar',
                strike: 'Riscar',
                blockquote: 'Citação',
                bullet_list: 'Lista com marcadores',
                ordered_list: 'Lista ordenada',
                heading: 'Estilos',
                h3: 'Título',
                h4: 'Subtítulo',
                h5: 'Corpo',
                h6: 'Corpo 2',
                align_left: 'Alinhar à esquerda',
                align_center: 'Alinhar ao meio',
                align_right: 'Alinhar à direita',
                align_justify: 'Justificar',
                text_color: 'Cor do texto',
                background_color: 'Cor do destaque',
                insertLink: 'Inserir link',
                removeLink: 'Remover link',
                insertImage: 'Inserir imagem',
                url: 'URL',
                text: 'Texto',
                openInNewTab: 'Abrir em nova guia',
                insert: 'Inserir',
                altText: 'Texto alternativo',
                title: 'Título',
                remove: 'Remover',
            },
        }),
        NgxUiLoaderModule,
        NgxLoadingModule,
        NgScrollbarModule,
        ReactiveFormsModule,
        TooltipModule,
        TranslateModule.forChild(),
        SupportGerenciarVendedoresModule,
        SuporteRoutingModule
    ],
    providers: [
        SettingsService,
        SupportAvaliacaoVendedoresService,
        SupportContestacoesService,
        SupportCotacoesService,
        SupportDiarioService,
        SupportFaturamentoService,
        SupportGerenciarVendedoresService,
        SupportOficinaSemDocumentacaoService,
        SupportPedidosService,
        SupportUsuarioInternoService,
        SupportVoltarCotacaoService,

        { provide: LOCALE_ID, deps: [SettingsService, SupportCotacoesService], useFactory: (settingsService) => settingsService.getLocale() },
        { provide: NG_SCROLLBAR_OPTIONS, useValue: { visibility: 'hover' } },
        provideNgxMask()
    ],
    exports: [
        FeatherModule
    ],
    bootstrap: []
})
export class SupportModule { }
