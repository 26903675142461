<div class="faturamento">
    <app-header
        [hasBackButton]="false"
        [title]="'support.faturamento.cabecalho.titulo' | translate"
    >
        <button class="btn btn-primary btn-sm float-end" (click)="carregarTela(0)"><i class="fa fa-refresh"></i> {{'support.faturamento.cabecalho.atualizar' | translate}}</button>
    </app-header>

    <div class="card card-default d-flex flex-wrap">
        <div class="card-body linha">
            <div class="col-1 acoes">
                <ng-scrollbar>
                    <div class="card">
                        <div class="card-header">
                            <div class="card-title">{{ 'support.faturamento.passos.passo-1.titulo.passo' | translate}} - {{ 'support.faturamento.passos.passo-1.titulo.nome' | translate}}</div>
                        </div>
                        <div class="card-body text-center">
                            <button class="btn btn-primary" (click)="enviarPasso1(0, false)">{{ 'elemento.generico-botao.enviar' | translate}}</button>
                        </div>
                        <div class="card-footer">
                            {{ 'support.faturamento.passos.ultima-execucao' | translate}} {{ ultima_data_passo_1 }}
                        </div>

                        <ngx-loading [show]="loading" [template]="templateCards"></ngx-loading>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <div class="card-title">{{ 'support.faturamento.passos.passo-2.titulo.passo' | translate}} - {{ 'support.faturamento.passos.passo-2.titulo.nome' | translate}}</div>
                        </div>
                        <div class="card-body text-center">
                            <button class="btn btn-primary" (click)="enviarPasso2(0, false)">{{ 'elemento.generico-botao.enviar' | translate}}</button>
                        </div>
                        <div class="card-footer">
                            {{ 'support.faturamento.passos.ultima-execucao' | translate}} {{ ultima_data_passo_2 }}
                        </div>

                        <ngx-loading [show]="loading" [template]="templateCards"></ngx-loading>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <div class="card-title">{{ 'support.faturamento.passos.passo-3.titulo.passo' | translate}} - {{ 'support.faturamento.passos.passo-3.titulo.nome' | translate}}</div>
                        </div>
                        <div class="card-body text-center">
                            <button class="btn btn-primary" (click)="enviarPasso3(0, false)">{{ 'elemento.generico-botao.enviar' | translate}}</button>
                        </div>
                        <div class="card-footer">
                            {{ 'support.faturamento.passos.ultima-execucao' | translate}} {{ ultima_data_passo_3 }}
                        </div>

                        <ngx-loading [show]="loading" [template]="templateCards"></ngx-loading>
                    </div>
                </ng-scrollbar>
            </div>
            <div class="card col-2" [ngClass]="{ 'erro': tem_erro }">
                <div class="card-header">
                    <div class="card-title">{{ 'support.faturamento.passos.log.titulo' | translate}}<span class="text-bold">{{ultimo_passo_executado}}</span></div>
                </div>
                <div class="card-body">
                    @if (logsOrdenados.length === 0) {
                        <div class="vazio">{{ 'support.faturamento.passos.log.vazio' | translate}}</div>
                    } @else {
                        <section class="d-flex flex-wrap striped bordered">
                            <header>
                                <div class="col-2-5">{{ 'support.faturamento.passos.log.cabecalho.info' | translate}}</div>
                                <div class="col text-center">{{ 'support.faturamento.passos.log.cabecalho.data' | translate}}</div>
                                <div class="col text-center">{{ 'support.faturamento.passos.log.cabecalho.status' | translate}}</div>
                            </header>
                            <ng-scrollbar class="corpo">
                                @for (item of logsOrdenados; track item) {
                                    <div class="linha" [ngClass]="{ 'erro': item.status === 'Erro' }">
                                        <div class="col-2-5 truncate">{{item.descricao}}</div>
                                        <div class="col text-center">{{item.creation_date_local_date | date:'dd/MM/yyyy HH:mm'}}</div>
                                        <div class="col text-center">{{item.status}}</div>
                                    </div>
                                }
                            </ng-scrollbar>
                        </section>
                    }
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #templateCards></ng-template>
