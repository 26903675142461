import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, Route, RouterStateSnapshot, UrlTree } from '@angular/router';

import { BaseGuard } from './base.guard';
import { AccountService } from '../../account/account.service';
import { SupportMultiloginComponent } from '../../support/multilogin/multilogin.component';
import { Observable } from 'rxjs';
import { Globals } from '../../_models/globals';

@Injectable()
export class SupportGuard extends BaseGuard {
    constructor(protected router: Router, protected accountService: AccountService, private globals: Globals) { super(router, accountService); }

    canActivate(routeAc: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        //alert('Funcionalidade desabilitada para manutenção');
        //this.navegarLogin();
        //return;

        this.verificarLogado();
        return super.validarAcesso(routeAc, state);
    }

    canDeactivate(component: SupportMultiloginComponent): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (component.progressWillBeLost()) {
            return confirm(this.globals.translate('support.multilogin.confirmacao.sair'));
        }

        return true;
    }

    canLoad(route: Route) {
        this.verificarLogado();

        return true;
    }

    private verificarLogado() {
        let user = this.localStorageUtils.obterUsuario();

        if (!user || !user.isAdmin)
            this.navegarLogin();
    }
}
