import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { NotificationService } from '../../_services/notification.service';
import { SupportAvaliacaoVendedoresService } from './avaliacao-vendedores.service';

import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { AvaliacaoVendedor } from './_models/avalicaoVendedor';
import { Globals } from '../../_models/globals';
import { FornecedorVendedor } from './_models/fornecedor-vendedor';
import { SupportAvaliacaoVendedoresGetListarAvaliacoesResponseModel } from './_models/supportAvaliacaoVendedoresGetListarAvaliacoesResponseModel';
import { SupportAvaliacaoVendedorGetAvaliacaoVendedorFornecedorResponseModel } from './_models/supportAvaliacaoVendedorGetAvaliacaoVendedorFornecedorResponseModel';
import { SupportAvaliacaoVendedoresGetListarVendedoresCotacaoResponseModel } from './_models/supportAvaliacaoVendedoresGetListarVendedoresCotacaoResponseModel';

declare const $: any
declare const isEmpty: any;
declare const sort_by: any;
declare const unique: any;
declare const waitForElm: any

@Component({ selector: 'app-avaliacao-vendedores', templateUrl: './avaliacao-vendedores.component.html', styleUrls: ['./avaliacao-vendedores.component.scss'] })
export class SupportAvaliacaoVendedoresComponent implements OnInit
{
    public avaliacoes: AvaliacaoVendedor[] = [];
    public todosFornecedores: FornecedorVendedor[] = [];
    public fornecedoresHoldings: any[] = [];
    public vendedoresFornecedor: any[] = [];

    public cotacaoSelecionada: any;
    public fornecedorSelecionado: any = '0';
    public vendedorSelecionado: any = '0';

    constructor(
        private notificationService: NotificationService,
        private avaliacaoVendedoresService: SupportAvaliacaoVendedoresService,
        private globals: Globals,
        private ngxLoader: NgxUiLoaderService) { }

    ngOnInit(): void {
        this.carregarTela(0);
    }

    carregarTela(nAttempts: number) {
        this.ngxLoader.startLoader('loader-principal');

        this.avaliacaoVendedoresService.getAllSellers().subscribe({
            next: (response: ApiResponseModel<SupportAvaliacaoVendedorGetAvaliacaoVendedorFornecedorResponseModel>) => {
                if (response && response.success) {
                    this.todosFornecedores = response.result.avaliacaoVendedorFornecedores;

                    const fornecedores = this.todosFornecedores.map(f => ({ holding_id: f.holding_id, fornecedor: f.fornecedor })).sort(sort_by([{ name: 'fornecedor' }]));
                    this.fornecedoresHoldings = unique(fornecedores, 'holding_id');

                    this.handleDropdownConfiguration();
                }

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.ngxLoader.stopLoader('loader-principal');
                    this.notificationService.showErrorToastr(this.globals.translate('support.avaliacao-vendedores.lista.mensagens.erro.carregamento-fornecedor.corpo'));
                    return;
                }

                this.carregarTela(++nAttempts);
            }
        });
    }

    changeFornecedor(holdingId: number) {
        this.fornecedorSelecionado = holdingId;
        this.cotacaoSelecionada = undefined;
        this.vendedorSelecionado = '0';

        const vendedores = this.todosFornecedores.filter(f => f.holding_id == this.fornecedorSelecionado).map(f => ({ seller_company_id: f.seller_company_id, vendedor: f.vendedor })).sort(sort_by([{ name: 'vendedor' }]));
        this.vendedoresFornecedor = unique(vendedores, 'seller_company_id');
    }

    changeVendedor(sellerCompanyId: number) {
        this.vendedorSelecionado = sellerCompanyId;
    }

    private handleDropdownConfiguration() {
        const global = this;
        this.handleSingleDropdownConfiguration('.search-holding', 'support.multilogin.labels.holding', (value) => global.changeFornecedor(value));
        this.handleSingleDropdownConfiguration('.search-user', 'support.multilogin.labels.usuario', (value) => global.changeVendedor(value))
    }

    private handleSingleDropdownConfiguration(className: string, translateName: string, callback: any) {
        waitForElm(className).then(() => {
            $(className).select2({
                language: "pt-BR",
                placeholder:  this.globals.translate(translateName)
            });

            $(className).off('select2:select');

            if (callback)
                $(className).on('select2:select', function () { callback($(className).val()); });

            $(document).on('select2:open', () => { $('.select2-search__field').focus().select(); });
        });
    }

    listarAvaliacoes(seller_company_ids: number[], nAttempts: number) {
        this.ngxLoader.startLoader('loader-principal');

        this.avaliacaoVendedoresService.getAll(seller_company_ids).subscribe({
            next: (response: ApiResponseModel<SupportAvaliacaoVendedoresGetListarAvaliacoesResponseModel>) => {
                if (response && response.success) {
                    this.avaliacoes = response.result.avaliacaoVendedores;
                }

                this.ngxLoader.stopLoader('loader-principal');
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.ngxLoader.stopLoader('loader-principal');
                    this.notificationService.showErrorToastr(this.globals.translate('support.avaliacao-vendedores.lista.mensagens.erro.carregamento-avaliacao.corpo'));
                    return;
                }

                this.carregarTela(++nAttempts);
            }
        });
    }

    async pesquisar() {
        if ((isEmpty(this.fornecedorSelecionado) || this.fornecedorSelecionado == '0') && (isEmpty(this.cotacaoSelecionada))) {
            await this.notificationService.showWarning('support.avaliacao-vendedores.lista.mensagens.erro.pesquisa-invalida.corpo', 'support.avaliacao-vendedores.lista.mensagens.erro.pesquisa-invalida.titulo');
            return;
        }

        this.avaliacoes = [];
        let seller_company_ids: number[] = [];

        if (!isEmpty(this.cotacaoSelecionada) && this.cotacaoSelecionada > 0) {
            this.ngxLoader.startLoader('loader-principal');

            this.avaliacaoVendedoresService.getAllSellersByQuotationId(this.cotacaoSelecionada).subscribe({
                next: (response: ApiResponseModel<SupportAvaliacaoVendedoresGetListarVendedoresCotacaoResponseModel>) => {
                    if (response && response.success) {
                        seller_company_ids = response.result.vendedoresCotacao;
                        this.listarAvaliacoes(seller_company_ids, 0);
                    }
                },
                error: () => {
                    this.notificationService.showErrorToastr(this.globals.translate('support.avaliacao-vendedores.lista.mensagens.erro.carregamento-cotacao.corpo'));
                }
            });

            return;
        }

        if (!isEmpty(this.vendedorSelecionado) && this.vendedorSelecionado > 0)
            seller_company_ids.push(this.vendedorSelecionado);
        else
            seller_company_ids = this.vendedoresFornecedor.map(v => v.seller_company_id);

        this.listarAvaliacoes(seller_company_ids, 0);
    }
}
