<div class="manutencao" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
    <app-header
        [hasBackButton]="false"
        [hasButtons]="false"
        [hasMinCharacters]="false"
        [searchMask]="'SSS-0A00'"
        [searchPlaceholder]="'support.manutencao.voltar-cotacao.placeholder' | translate"
        [title]="'support.manutencao.voltar-cotacao.titulo' | translate"
        (onSearch)="onSearch($event)"
    ></app-header>
    <div class="corpo-pagina col-md-8 offset-md-2">
        <div class="card card-default">
            <app-support-gridview [config]="config" [templates]="templatesArray" (onEdit)="onExecute($event)"></app-support-gridview>

            <ng-template #situacaoTemplate let-item>
                {{ (item.excluida ? 'support.manutencao.voltar-cotacao.tabela.situacao.excluida' : 'support.manutencao.voltar-cotacao.tabela.situacao.expirada') | translate}}
                &nbsp;
                <i class="fa" [ngClass]="{ 'fa-times text-danger': item.excluida, 'fa-clock-o': !item.excluida }"></i>
            </ng-template>
            <ng-template #pecasTemplate let-item>
                <div tooltip="{{item.pecas_title}}">
                    <i class="fa fa-info-circle cursor-pointer"></i>
                </div>
            </ng-template>

            <!-- <div class="">
                {{ (cotacao.excluida ? 'support.manutencao.voltar-cotacao.tabela.situacao.excluida' : 'support.manutencao.voltar-cotacao.tabela.situacao.expirada') | translate}}
                &nbsp;
                <i class="fa" [ngClass]="{ 'fa-times text-danger': cotacao.excluida, 'fa-clock-o': !cotacao.excluida }"></i>
            </div>
            <div class="" tooltip="{{cotacao.pecas_title}}">
                <i class="fa fa-info-circle cursor-pointer"></i>
            </div> -->

        </div>

    </div>
</div>

<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
