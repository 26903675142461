import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, FormControlName, UntypedFormGroup, Validators, FormArray, FormGroup, FormControl } from '@angular/forms';

import { NgxBrazilMASKS, NgxBrazilValidators } from 'ngx-brazil';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { v4 as uuid } from 'uuid';

import { CadastroCNPJService } from '../../cadastro-cnpj.service';
import { NotificationService } from 'app/_services/notification.service';

import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { CnpjDuplicadoValidator } from 'app/_utils/validators/cnpj-duplicado.validator';
import { CompanyCnpj, ManageCompanyCnpj } from '../../_models/company-model';
import { Globals } from 'app/_models/globals';
import { ValidationMessages } from 'app/_utils/generic-form-validation';

declare const $: any;
declare const isEmpty: any;
declare const waitForElm: any;

@Component({ selector: 'app-cadastro-cnpj-edicao', templateUrl: './create.component.html', styleUrls: ['./create.component.scss'] })
export class SupportCadastroCnpjEdicaoComponent implements OnInit {
    @Output() onAlterarTela = new EventEmitter<any>();

    private _manageCompanyCnpj: ManageCompanyCnpj;
    @Input() get manageCompanyCnpj(): ManageCompanyCnpj {
        return this._manageCompanyCnpj;
    }
    set manageCompanyCnpj(value: ManageCompanyCnpj) {
        this._manageCompanyCnpj = value;

        this.carregarTela(this.manageCompanyCnpj.id, 0);
    }

    @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];

    cadastroForm: UntypedFormGroup;
    repairerList: FormArray;
    validationMessages: ValidationMessages;

    compradoresLocal: any[];
    errors: any[] = [];
    companiesWithoutCnpjList: ManageCompanyCnpj[];
    isNewCnpj: boolean = false;
    submitted: boolean = false;

    public MASKS: any = NgxBrazilMASKS;

    constructor(private activatedRoute: ActivatedRoute,
        private cadastroCnpjService: CadastroCNPJService,
        private notificationService: NotificationService,
        private globals: Globals,
        private fb: UntypedFormBuilder,
        private ngxLoader: NgxUiLoaderService,
        private cdr: ChangeDetectorRef)
    {
        this.cadastroForm = this.fb.group({
            repairers: this.fb.array([])
        });

        this.repairerList = this.cadastroForm.get('repairers') as FormArray;
    }

    ngOnInit(): void {
        this.validationMessages = {
            cnpj: {
                required: this.globals.translate('support.manutencao.cadastro-cnpj.campos.cnpj.obrigatorio'),
                cnpj: this.globals.translate('support.manutencao.cadastro-cnpj.campos.cnpj.formato-invalido'),
                cnpjDuplicado: this.globals.translate('support.manutencao.cadastro-cnpj.campos.cnpj.cnpj-duplicado')
            },
            razaoSocial: {
                required: this.globals.translate('support.manutencao.cadastro-cnpj.campos.razao-social.obrigatorio'),
                maxLength: this.globals.translate('support.manutencao.cadastro-cnpj.campos.razao-social.tamanho'),
                pattern: this.globals.translate('support.manutencao.cadastro-cnpj.campos.razao-social.somente-letras')
            }
        };
    }

    adicionarCampoCnpj(companyCnpj: CompanyCnpj = null, disabled: boolean = false): void {
        this.submitted = false;

        if (!companyCnpj) {
            companyCnpj = new CompanyCnpj();
            this.manageCompanyCnpj.companiesCnpjs.push(companyCnpj);
        } else if (disabled) {
            this.manageCompanyCnpj.companiesCnpjs.push(companyCnpj);
        }

        const companyGroup = this.fb.group({
            id: [uuid()],
            cnpj: [{ value: companyCnpj.cnpj, disabled: disabled }, [Validators.required, NgxBrazilValidators.cnpj, CnpjDuplicadoValidator()]],
            razaoSocial: [{ value: companyCnpj.name, disabled: disabled }, [Validators.required, Validators.maxLength(100), Validators.pattern('^[a-zA-ZçÇáãâéíóôÁÃÂÉÍÓÔ0-9 ./-]*$')]]
        });

        this.repairerList.push(companyGroup);

        const className = `.razaoSocial_${this.repairerList.length - 1}`;
        waitForElm(className).then(() => {
            $(className).focus();
        });

        this.resetValidation();
    }

    private buildCompanyCnpj(cnpj: string, razaoSocial: string): CompanyCnpj {
        let companyCnpj = new CompanyCnpj();
        companyCnpj.cnpj = cnpj;
        companyCnpj.name = razaoSocial;

        return companyCnpj;
    }

    private configurarDropDownList() {
        const scope = this;
        const className = '.search-company';

        waitForElm(className).then(() => {
            $(className).select2({
                language: "pt-BR",
                placeholder:  this.globals.translate('support.manutencao.cadastro-cnpj.campos.oficina.placeholder'),
            });

            $(className).off('select2:select');
            $(className).on('select2:select', function (e)
            {
                scope.showCompanyInformation($(className).val());
            });

            $(document).on('select2:open', () => { $('.select2-search__field').focus().select(); });
        });
    }

    excluirCampoCnpj(index: number): void {
        this.manageCompanyCnpj.companiesCnpjs.splice(index, 1);

        this.repairerList.removeAt(index);
        this.resetValidation();
    }

    carregarTela(companyId: number, nAttempts: number) {
        this.cadastroCnpjService.getAllCompaniesWithoutCnpj(companyId).subscribe({
            next: (response: ApiResponseModel<{ companiesCnpj: ManageCompanyCnpj[] }>) => {
                if (response != null && response.success && !isEmpty(response.result)) {
                    this.companiesWithoutCnpjList = response.result.companiesCnpj;

                    this.activatedRoute.params.subscribe(params => {
                        this.isNewCnpj = params['id'] == 0;

                        if (!this.isNewCnpj) {
                            this.showCompanyInformation(params['id']);

                            this.companiesWithoutCnpjList[0].companiesCnpjs.forEach((companyCnpj) => {
                                this.adicionarCampoCnpj(companyCnpj);
                            });
                        }
                    });

                    this.configurarDropDownList();
                    this.cdr.detectChanges();
                }
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.notificationService.showErrorToastr(this.globals.translate('support.manutencao.cadastro-cnpj.mensagens.listar.erro.corpo'));
                    return;
                }

                this.carregarTela(companyId, ++nAttempts);
            }
        });
    }

    private resetValidation() {
        this.submitted = false;
    }

    async salvar(nAttempts: number) {
        this.submitted = true;
        this.validateAllFormFields(this.cadastroForm);

        if (this.cadastroForm.invalid) {
            return;
        }

        let companiesCnpj: CompanyCnpj[] = Object.assign([], this.cadastroForm.value.repairers);
        companiesCnpj.splice(0, 1);

        this.ngxLoader.startLoader('loader-principal');

        this.cadastroCnpjService.save(this.manageCompanyCnpj.id, companiesCnpj).subscribe({
            next: async (response: ApiResponseModel<boolean>) => {
                if (response != null && response.success) {
                    if (response.success) {
                        this.notificationService.showSuccessToastr(this.globals.translate('support.manutencao.cadastro-cnpj.mensagens.salvar.sucesso.corpo'));
                        this.voltar(true);
                    }
                    else {
                        this.ngxLoader.stopLoader('loader-principal');
                        await this.notificationService.showWarning('support.manutencao.cadastro-cnpj.mensagens.salvar.cnpj-existente.corpo', 'support.manutencao.cadastro-cnpj.mensagens.salvar.cnpj-existente.titulo');
                    }
                }
                else {
                    this.ngxLoader.stopLoader('loader-principal');
                    this.notificationService.showErrorToastr(this.globals.translate('support.manutencao.cadastro-cnpj.mensagens.salvar.erro.corpo'));
                }
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.notificationService.showErrorToastr(this.globals.translate('support.manutencao.cadastro-cnpj.mensagens.salvar.erro.corpo'));
                    this.ngxLoader.stopLoader('loader-principal');
                    return;
                }

                this.salvar(++nAttempts);
            }
        });
    }

    showCompanyInformation(id: number) : void {
        this.cadastroForm.reset();
        this.repairerList.clear();
        this.manageCompanyCnpj.companiesCnpjs = [];

        let selectedItem = this.companiesWithoutCnpjList.find((item)=> item.id == id);

        this.manageCompanyCnpj.id = id;

        if (this.manageCompanyCnpj.companiesCnpjs.length == 0) {
            const companyCnpj = this.buildCompanyCnpj(selectedItem.cnpj, selectedItem.name);

            this.adicionarCampoCnpj(companyCnpj, true);
        }
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
          const control = formGroup.get(field);

          if (control instanceof FormControl) {
            control.markAsTouched({ onlySelf: true });
            control.updateValueAndValidity();
          } else if (control instanceof FormGroup) {
            this.validateAllFormFields(control);
            control.updateValueAndValidity();
          } else if (control instanceof FormArray) {
            for (const controlGroup of control.controls) {
              this.validateAllFormFields(controlGroup as FormGroup);
            }
            control.updateValueAndValidity();
          }
        });

        formGroup.updateValueAndValidity();
      }

    voltar(atualizar: boolean)
    {
        this.onAlterarTela.emit({ atualizar: atualizar, is_listagem: true });
    }
}
