import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { BaseService } from 'app/_services/base.service';
import { SettingsService } from "app/_services/settings.service";

@Injectable({ providedIn: 'root' })
export class SupportOficinaSemDocumentacaoService extends BaseService {
    private _domain: string = '/support/maintenances';

    constructor(private config: SettingsService) {
        super();
    }

    public getHoldingsWithoutDocumentation(): Observable<any> {
        return this.config.executeGet({ url: `${this._domain}/holdings/without-documentation` });
    }
}
