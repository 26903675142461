import { Component, OnInit } from '@angular/core';

import { BaseService } from 'app/_services/base.service';
import { NotificationService } from 'app/_services/notification.service';
import { SupportFaturamentoService } from './faturamento.service';

import { Globals } from 'app/_models/globals';
import { LogFaturamento, LogFaturamentoItem } from './_models/log';
import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { SupportFaturamentosSendResponseModel } from './_models/supportFaturamentosSendResponseModel';
import { SupportFaturamentoGetAllLastLogsResponseModel } from './_models/supportFaturamentoGetAllLastLogsResponseModel';
import { SupportFaturamentosGetCurrentSituationResponseModel } from './_models/supportFaturamentosGetCurrentSituationResponseModel';

declare const formatDateToDDMMYYYYHHMM: any;
declare const isEmpty: any;
declare const sort_by: any;

@Component({ selector: 'app-faturamento', templateUrl: './faturamento.component.html', styleUrls: ['./faturamento.component.scss'] })
export class SupportFaturamentoComponent implements OnInit
{
    dadosLog: LogFaturamento;
    loading: boolean;
    timeout_id: any;

    ultimoGroupId: string;

    constructor(
        private baseService: BaseService,
        private notificationService: NotificationService,
        private supportFaturamentoService: SupportFaturamentoService,
        private globals: Globals) {
        this.loading = false;
        this.timeout_id = 0;

        this.dadosLog = { enviando: false, lista: [] };
    }

    ngOnInit(): void {
        this.carregarTela(0);
    }

    cancelarTimeoutAtual() {
        let id = this.timeout_id;

        while (id--) {
            window.clearTimeout(id);
        }
    }

    carregarLogs(creation_date: Date, nAttempts: number) {
        if (isEmpty(this.baseService.usuarioLogado)) {
            this.cancelarTimeoutAtual();
            return;
        }

        this.supportFaturamentoService.getAllLastLogs(this.ultimoGroupId, creation_date).subscribe({
            next: (response: ApiResponseModel<SupportFaturamentoGetAllLastLogsResponseModel>) => {
                if (response && response.success) {
                    let temLog = response.result.logFaturamento.length > 0;

                    if (temLog) {
                        this.dadosLog.enviando = response.result.emAtividade;

                        for (let i = 0; i <= response.result.logFaturamento.length - 1; i++) {
                            let log = response.result.logFaturamento[i];
                            let oldLogIndex = this.dadosLog.lista.findIndex(l => l.id === log.id);

                            if (oldLogIndex < 0) {
                                this.dadosLog.lista.push(log);

                                if (log.tipo === 'envio-central')
                                    this.dadosLog.data_ultima_execucao_passo1 = log.creation_date_local_date;
                                else if (log.tipo === 'envio-superlogica')
                                    this.dadosLog.data_ultima_execucao_passo2 = log.creation_date_local_date;
                                else if (log.tipo === 'email-gestor')
                                    this.dadosLog.data_ultima_execucao_passo3 = log.creation_date_local_date;
                            }
                            else {
                                this.dadosLog.lista[oldLogIndex] = Object.assign({}, log);
                            }
                        }

                        this.ultimoGroupId = this.logsOrdenados[0].group_id;
                    }

                    this.loading = response.result.emAtividade;

                    this.cancelarTimeoutAtual();

                    this.timeout_id = setTimeout(() => {
                        let dates = response.result.logFaturamento.map(l => new Date(l.creation_date));
                        let creationDate = null;

                        if (dates && dates.length > 0)
                            creationDate = new Date(Math.max.apply(null, dates));

                        this.carregarLogs(creationDate, 0);
                    }, 5000);
                }
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.notificationService.showErrorToastr(this.globals.translate('support.faturamento.mensagens.erro.carregar-logs.corpo'));
                    return;
                }

                this.carregarLogs(creation_date, ++nAttempts);
            }
        });
    }

    carregarTela(nAttempts: number) {
        this.cancelarTimeoutAtual();
        this.loading = true;
        this.ultimoGroupId = null;

        // Verifica a situação atual
        this.supportFaturamentoService.getCurrentSituation().subscribe({
            next: (response: ApiResponseModel<SupportFaturamentosGetCurrentSituationResponseModel>) => {
                if (response && response.success) {
                    let logs = this.ordernarLogs(response.result.logs);
                    this.dadosLog.lista = logs;
                    this.dadosLog.data_ultima_execucao_passo1 = null;
                    this.dadosLog.data_ultima_execucao_passo2 = null;
                    this.dadosLog.data_ultima_execucao_passo3 = null;

                    if (response.result.temLog && logs.length > 0) {
                        this.dadosLog.data_ultima_execucao_passo1 = response.result.dataUltimaExecucaoPasso1;
                        this.dadosLog.data_ultima_execucao_passo2 = response.result.dataUltimaExecucaoPasso2;
                        this.dadosLog.data_ultima_execucao_passo3 = response.result.dataUltimaExecucaoPasso3;

                        this.ultimoGroupId = logs[0].group_id;
                    }

                    this.dadosLog.enviando = response.result.emAtividade;
                    this.loading = response.result.emAtividade;

                    this.timeout_id = setTimeout(() => { this.carregarLogs(null, 0); }, 5000);
                }
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.notificationService.showErrorToastr(this.globals.translate('support.faturamento.mensagens.erro.obter-situacao-inicial.corpo'));
                    return;
                }

                this.carregarTela(++nAttempts);
            }
        });
    }

    async enviarPasso1(nAttempts: number, ignorarConfirm: boolean) {
        let ultimaData = this.ultima_data_passo_1;

        if (!ignorarConfirm && !await this.notificationService.showConfirmationDialog(this.globals.translate('support.faturamento.mensagens.confirm.passo-1') + ultimaData, null, false))
            return;

        this.loading = true;
        this.cancelarTimeoutAtual();

        this.supportFaturamentoService.send(1).subscribe({
            next: (response: ApiResponseModel<SupportFaturamentosSendResponseModel>) => {
                if (response && response.success) {
                    let log = response.result.logFaturamento;
                    this.dadosLog.lista = [];
                    this.dadosLog.data_ultima_execucao_passo1 = new Date();

                    this.ultimoGroupId = log.group_id;
                    this.carregarLogs(log.creation_date_local_date, 0);

                    this.notificationService.showSuccessToastr(this.globals.translate('support.faturamento.mensagens.sucesso.passo-1.corpo'));
                }
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.carregarTela(0);
                    this.notificationService.showErrorToastr(this.globals.translate('support.faturamento.mensagens.erro.passo-1.corpo'));
                    return;
                }

                this.enviarPasso1(++nAttempts, true);
            }
        });
    }

    async enviarPasso2(nAttempts: number, ignorarConfirm: boolean) {
        let ultimaData = this.ultima_data_passo_2;
        
        if (!ignorarConfirm && !await this.notificationService.showConfirmationDialog(this.globals.translate('support.faturamento.mensagens.confirm.passo-2') + ultimaData, null, false))
            return;

        this.loading = true;
        this.cancelarTimeoutAtual();

        this.supportFaturamentoService.send(2).subscribe({
            next: (response: ApiResponseModel<SupportFaturamentosSendResponseModel>) => {
                if (response && response.success) {
                    let log = response.result.logFaturamento;
                    this.dadosLog.lista = [];
                    this.dadosLog.data_ultima_execucao_passo2 = new Date();

                    this.ultimoGroupId = log.group_id;
                    this.carregarLogs(log.creation_date_local_date, 0);

                    this.notificationService.showSuccessToastr(this.globals.translate('support.faturamento.mensagens.sucesso.passo-2.corpo'));
                }
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.carregarTela(0);
                    this.notificationService.showErrorToastr(this.globals.translate('support.faturamento.mensagens.erro.passo-2.corpo'));
                    return;
                }

                this.enviarPasso2(++nAttempts, true);
            }
        });
    }

    async enviarPasso3(nAttempts: number, ignorarConfirm: boolean) {
        let ultimaData = this.ultima_data_passo_3;

        if (!ignorarConfirm && !await this.notificationService.showConfirmationDialog(this.globals.translate('support.faturamento.mensagens.confirm.passo-3') + ultimaData, null, false))
            return;

        this.loading = true;
        this.cancelarTimeoutAtual();

        this.supportFaturamentoService.send(3).subscribe({
            next: (response: ApiResponseModel<SupportFaturamentosSendResponseModel>) => {
                if (response && response.success) {
                    let log = response.result.logFaturamento;
                    this.dadosLog.lista = [];
                    this.dadosLog.data_ultima_execucao_passo3 = new Date();
                    this.ultimoGroupId = log.group_id;
                    this.carregarLogs(log.creation_date_local_date, 0);

                    this.notificationService.showSuccessToastr(this.globals.translate('support.faturamento.mensagens.sucesso.passo-3.corpo'));
                }
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.carregarTela(0);
                    this.notificationService.showErrorToastr(this.globals.translate('support.faturamento.mensagens.erro.passo-3.corpo'));
                    return;
                }

                this.enviarPasso3(++nAttempts, true);
            }
        });
    }

    obter_ultima_data_passo(passo: number): string {
        let data = isEmpty(this.dadosLog) ? null : (passo === 1 ? this.dadosLog.data_ultima_execucao_passo1 : (passo === 2 ? this.dadosLog.data_ultima_execucao_passo2 : this.dadosLog.data_ultima_execucao_passo3));
        return isEmpty(data) ? this.globals.translate('support.faturamento.passos.data-vazia') : formatDateToDDMMYYYYHHMM(new Date(data));
    }

    ordernarLogs(logs: LogFaturamentoItem[]): LogFaturamentoItem[] {
        if (isEmpty(logs))
            return [];

        return logs.sort(sort_by([{ name: 'creation_date_local_date', reverse: true }, { name: 'descricao' }]));
    }


    get logsOrdenados(): LogFaturamentoItem[] {
        if (isEmpty(this.dadosLog) || isEmpty(this.dadosLog.lista))
            return [];

        return this.ordernarLogs(this.dadosLog.lista);
    }

    get tem_erro(): boolean {
        if (isEmpty(this.dadosLog) || isEmpty(this.dadosLog.lista))
            return false;

        return this.dadosLog.lista.some(l => l.status === 'Erro');
    }

    get ultima_data_passo_1(): string {
        return this.obter_ultima_data_passo(1);
    }

    get ultima_data_passo_2(): string {
        return this.obter_ultima_data_passo(2);
    }

    get ultima_data_passo_3(): string {
        return this.obter_ultima_data_passo(3);
    }

    get ultimo_passo_executado(): string {
        if (isEmpty(this.dadosLog) || (isEmpty(this.dadosLog.data_ultima_execucao_passo1) && isEmpty(this.dadosLog.data_ultima_execucao_passo2) && isEmpty(this.dadosLog.data_ultima_execucao_passo3)))
            return this.globals.translate('support.faturamento.passos.data-vazia');

        if (this.dadosLog.data_ultima_execucao_passo1 > this.dadosLog.data_ultima_execucao_passo2 || isEmpty(this.dadosLog.data_ultima_execucao_passo2))
            return this.globals.translate('support.faturamento.passos.passo-1.titulo.nome');

        if (this.dadosLog.data_ultima_execucao_passo2 > this.dadosLog.data_ultima_execucao_passo3 || isEmpty(this.dadosLog.data_ultima_execucao_passo3))
            return this.globals.translate('support.faturamento.passos.passo-2.titulo.nome');

        return this.globals.translate('support.faturamento.passos.passo-3.titulo.nome');
    }
}
