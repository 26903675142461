import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { AccountService } from 'app/account/account.service';
import { BaseService } from 'app/_services/base.service';
import { SettingsService } from 'app/_services/settings.service';

declare const isEmpty: any;

@Injectable({ providedIn: 'root' })
export class SupportContestacoesService extends BaseService {
    private _domain: string = '/support/contestacoes';

    constructor(private accountService: AccountService, private config: SettingsService) {
        super();
    }

    public approve(contestacoes: any): Observable<any> {
        const body = { contestacoes };
        return this.config.executePost({ url: `${this._domain}/approve`, body });
    }

    public getAll(): Observable<any> {
        if (isEmpty(this.usuarioLocal)) {
            this.accountService.logout();
            return EMPTY;
        }

        return this.config.executeGet({ url: `${this._domain}` });
    }
}
