<div class="diario" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
    <app-header
        [hasBackButton]="false"
        [hasButtons]="false"
        [filterInput]="perfil"
        filterLabel="Perfil"
        [filterOptions]="filterOptions"
        (onFilter)="perfil = $event"
    ></app-header>
    <div class="card card-default">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
            <li [ngbNavItem]="1" [ngClass]="active === 1 ? 'active' : ''">
                <a ngbNavLink>
                    <em class=""></em>{{ 'support.diario.entradas.titulo' | translate}}
                    @if (active === 1) {
                        <paneltool tool-refresh="line back-and-forth grow" class="float-end" style="cursor: pointer;" (click)="atualizar()">
                            <span href="javascript:void(0)" panel-refresh="" data-spinner="line back-and-forth grow"><em class="fa fa-refresh"></em></span>
                        </paneltool>
                    }
                </a>
                <ng-template ngbNavContent>
                    @if (entradas?.length <= 0) {
                        <div class="vazio">
                            {{'support.diario.vazio.pedido' | translate}}
                        </div>
                    } @else {
                        <section class="d-flex flex-wrap striped">
                            <header>
                                <div class="linha">
                                    <div class="col2 cursor-pointer" (click)="ordenar('delivery_day', false)">
                                        <span>{{'support.diario.entradas.colunas.delivery_day' | translate}}
                                            @if (orderByField == 'delivery_day') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSort ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    <div class="col1 cursor-pointer" (click)="ordenar('quotation_id', false)">
                                        <span>{{'support.diario.entradas.colunas.quotation_id' | translate}}
                                            @if (orderByField == 'quotation_id') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSort ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    <div class="col3 cursor-pointer" (click)="ordenar('vehicle_infos', false)">
                                        <span>{{'support.diario.entradas.colunas.vehicle' | translate}}
                                            @if (orderByField == 'vehicle_infos') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSort ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    <div class="col2 cursor-pointer" (click)="ordenar('supplier_name', false)">
                                        <span>{{'support.diario.entradas.colunas.supplier_name' | translate}}
                                            @if (orderByField == 'supplier_name') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSort ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    <div class="col1 cursor-pointer" (click)="ordenar('seller_name', false)">
                                        <span>{{'support.diario.entradas.colunas.seller_name' | translate}}
                                            @if (orderByField == 'seller_name') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSort ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    <div class="col2 cursor-pointer" (click)="ordenar('seller_phone_radio', false)">
                                        <span>{{'support.diario.entradas.colunas.seller_phone_radio' | translate}}
                                            @if (orderByField == 'seller_phone_radio') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSort ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    <div class="col2 cursor-pointer" (click)="ordenar('repairer_name', false)">
                                        <span>{{'support.diario.entradas.colunas.repairer_name' | translate}}
                                            @if (orderByField == 'repairer_name') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSort ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    <div class="col1 cursor-pointer" (click)="ordenar('buyer_name', false)">
                                        <span>{{'support.diario.entradas.colunas.buyer_name' | translate}}
                                            @if (orderByField == 'buyer_name') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSort ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    <div class="col2 cursor-pointer" (click)="ordenar('buyer_phone_radio', false)">
                                        <span>{{'support.diario.entradas.colunas.buyer_phone_radio' | translate}}
                                            @if (orderByField == 'buyer_phone_radio') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSort ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                </div>
                            </header>
                            <div class="corpo">
                                <ng-scrollbar>
                                    @for (item of entradas; track item) {
                                        <div class="linha">
                                            <div class="col2 truncate">{{item.delivery_day_formatada}}</div>
                                            <div class="col1 truncate">{{item.quotation_id}}</div>
                                            <div class="col3 truncate">{{item.vehicle_infos}}</div>
                                            <div class="col2 truncate">{{item.supplier_name}}</div>
                                            <div class="col1 truncate">{{item.seller_name}}</div>
                                            <div class="col2 truncate">{{item.seller_phone_radio}}</div>
                                            <div class="col2 truncate">{{item.repairer_name}}</div>
                                            <div class="col1 truncate">{{item.buyer_name}}</div>
                                            <div class="col2 truncate">{{item.buyer_phone_radio}}</div>
                                        </div>
                                    }
                                </ng-scrollbar>
                            </div>
                        </section>
                    }
                </ng-template>
            </li>
            <li [ngbNavItem]="2" [ngClass]="active === 2 ? 'active' : ''">
                <a ngbNavLink>
                    <em class=""></em>{{ 'support.diario.exclusoes.titulo' | translate}}
                    @if (active === 2) {
                        <paneltool tool-refresh="line back-and-forth grow" class="float-end" style="cursor: pointer;" (click)="atualizar()">
                            <span href="javascript:void(0)" panel-refresh="" data-spinner="line back-and-forth grow"><em class="fa fa-refresh"></em></span>
                        </paneltool>
                    }
                </a>
                <ng-template ngbNavContent>
                    @if (exclusoes?.length <= 0) {
                        <div class="vazio">
                            {{'support.diario.vazio.cotacao' | translate}}
                        </div>
                    } @else {
                        <section class="d-flex flex-wrap striped">
                            <header>
                                <div class="linha">
                                    <div class="col1 cursor-pointer" (click)="ordenar('creation_date', false)">
                                        <span>{{'support.diario.exclusoes.colunas.creation_date' | translate}}
                                            @if (orderByField == 'creation_date') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSort ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    <div class="col1 cursor-pointer" (click)="ordenar('rejection_date', false)">
                                        <span>{{'support.diario.exclusoes.colunas.rejection_date' | translate}}
                                            @if (orderByField == 'rejection_date') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSort ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    <div class="col1 cursor-pointer" (click)="ordenar('reason', false)">
                                        <span>{{'support.diario.exclusoes.colunas.reason' | translate}}
                                            @if (orderByField == 'reason') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSort ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    <div class="col2 cursor-pointer" (click)="ordenar('vehicle_infos', false)">
                                        <span>{{'support.diario.exclusoes.colunas.vehicle_infos' | translate}}
                                            @if (orderByField == 'vehicle_infos') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSort ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    <div class="col2 cursor-pointer" (click)="ordenar('repairer_name', false)">
                                        <span>{{'support.diario.exclusoes.colunas.repairer_name' | translate}}
                                            @if (orderByField == 'repairer_name') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSort ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    <div class="col1 cursor-pointer" (click)="ordenar('buyer_name', false)">
                                        <span>{{'support.diario.exclusoes.colunas.buyer_name' | translate}}
                                            @if (orderByField == 'buyer_name') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSort ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    <div class="col2 cursor-pointer" (click)="ordenar('buyer_phone_radio', false)">
                                        <span>{{'support.diario.exclusoes.colunas.buyer_phone_radio' | translate}}
                                            @if (orderByField == 'buyer_phone_radio') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSort ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    <div class="col1 cursor-pointer" (click)="ordenar('reason_notes', false)">
                                        <span>{{'support.diario.exclusoes.colunas.reason_notes' | translate}}
                                            @if (orderByField == 'reason_notes') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSort ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    <div class="col1 cursor-pointer" (click)="ordenar('duration', false)">
                                        <span>{{'support.diario.exclusoes.colunas.duration' | translate}}
                                            @if (orderByField == 'duration') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSort ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                </div>
                            </header>
                            <div class="corpo">
                                <ng-scrollbar>
                                    @for (item of exclusoes; track item) {
                                        <div class="linha">
                                            <div class="col1 truncate">{{item.creation_date_formatada}}</div>
                                            <div class="col1 truncate">{{item.rejection_date_formatada}}</div>
                                            <div class="col1 truncate">{{item.reason}}</div>
                                            <div class="col2 truncate">{{item.vehicle_infos}}</div>
                                            <div class="col2 truncate">{{item.repairer_name}}</div>
                                            <div class="col1 truncate">{{item.buyer_name}}</div>
                                            <div class="col2 truncate">{{item.buyer_phone_radio}}</div>
                                            <div class="col1 truncate">{{item.reason_notes}}</div>
                                            <div class="col1 truncate">{{item.duration}}</div>
                                        </div>
                                    }
                                </ng-scrollbar>
                            </div>
                        </section>
                    }
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
    </div>
</div>

<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
