import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SupportCadastroNovidadesService } from './cadastro-novidades.service';
import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { NewFeaturesGetRoles } from 'app/general/_models/new-features-model';
import { Update } from '../../_models/update';
import moment from 'moment';

declare const compararStringContemString: any;
declare const isEmpty: any;

@Component({ selector: 'app-cadastro-novidades', templateUrl: './cadastro-novidades.component.html', styleUrls: ['./cadastro-novidades.component.scss'] })
export class SupportCadastroNovidadesComponent implements OnInit {
    public filter: string = '';
    updatesList: Update[];
    updatesListFiltered: Update[];
    createUpdate: boolean;
    selectedUpdate: Update;

    constructor(private supportCadastroNovidadesService: SupportCadastroNovidadesService, private ngxLoader: NgxUiLoaderService) {
        this.createUpdate = false;
    }

    ngOnInit() {
        this.loadUpdates()
    }

    closeUpdateCreation() {
        this.loadUpdates()
        this.createUpdate = false;
    }

    loadUpdates(): void {
        this.ngxLoader.startLoader('loader-principal')
        this.supportCadastroNovidadesService.getUpdatesList().subscribe({
            next: (response: ApiResponseModel<NewFeaturesGetRoles>) => {
                this.updatesList = this.updatesListFiltered = response.result.features;
                this.ngxLoader.stopLoader('loader-principal')
            }
        })
    }

    openUpdateCreation(selectedUpdate: Update) {
        this.selectedUpdate = selectedUpdate;
        this.createUpdate = true;
    }

    onSearch($event: string) {
        if (isEmpty(this.updatesList))
            return [];

        const term = $event.toLowerCase();

        this.updatesListFiltered = this.updatesList.filter(o =>
            compararStringContemString(o.title.toLowerCase(), term)
            || compararStringContemString(o.mudancas.toLowerCase(), term)
            || moment(o.release_date).format('DD/MM/YYYY').indexOf(term) >= 0
        );
    }

    get closeUpdateCreationFunc() {
        return this.closeUpdateCreation.bind(this);
    }

    get openUpdateCreationFunc() {
        return this.openUpdateCreation.bind(this);
    }
}
