import { Injectable } from '@angular/core';
import { SettingsService } from 'app/_services/settings.service';


@Injectable({ providedIn: 'root' })
export class SupportGerenciarVendedoresService {
    private _domain: string = '/support/manage-sellers';

    constructor(private config: SettingsService) { }

    public getAllHoldings() {
        return this.config.executeGet({ url: `${this._domain}/holdings` });
    }
}
