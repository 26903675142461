<div class="usuario-interno">
    <app-header
        [title]="('support.manutencao.usuario.interno.titulo' + (isReactivating ? '-reativando' : '') | translate) + ((selectedUser?.userId > 0) ? (' - ' + selectedUser.name) : '')"
        [hasBackButton]="!isList || isReactivating"
        [hasSearchButton]="false"
        [searchInput]="searchTerm"
        [searchPlaceholder]="isList && 'support.manutencao.usuario.interno.search-placeholder' | translate"
        (onGoBack)="backToList()"
        (onSearch)="searchTerm = $event"
    >
        @if (isList && !isReactivating) {
            <button class="btn btn-primary" (click)="action_reactive()">{{'support.manutencao.usuario.interno.reativar' | translate}}</button>
            <button class="btn btn-primary" (click)="action_new()">{{'support.manutencao.usuario.interno.novo' | translate}}</button>
        }
    </app-header>

    @if (isList) {
        <app-usuario-interno-lista (onEditUser)="action_editUser($event)" (onEditProfile)="action_editProfile($event)" (onReactivateProfile)="action_reactiveProfile($event)" (onRemoveProfile)="action_removeProfile($event)" [users]="filteredUsers"></app-usuario-interno-lista>
    } @else {
        <app-usuario-interno-edicao (onAlterarTela)="action_updateUser($event)" (onBackToList)="action_backToList()" [repairers]="repairers" [user]="selectedUser" [isEditProfile]="isEditProfile"></app-usuario-interno-edicao>
    }
</div>

<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
