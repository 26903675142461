<div class="pedidos" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
    <app-header
        [hasBackButton]="false"
        [hasButtons]="false"
        [filterInput]="perfil"
        filterLabel="Perfil"
        [filterOptions]="filterOptions"
        (onFilter)="perfil = $event"
    ></app-header>
    <div class="card card-default">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
            <li [ngbNavItem]="1" [ngClass]="active === 1 ? 'active' : ''">
                <a ngbNavLink>
                    {{ 'support.pedidos.pendentes.titulo' | translate}}
                    @if (active === 1) {
                        <div class="float-end" style="cursor: pointer;" (click)="atualizar()">
                            <span href="javascript:void(0)">
                                <i class="fa fa-refresh"></i>
                            </span>
                        </div>
                    }
                </a>
                <ng-template ngbNavContent>
                    @if (pendentes?.length <= 0) {
                        <div class="vazio">
                            {{'support.pedidos.vazio' | translate}}
                        </div>
                    } @else {
                        <section class="d-flex flex-wrap striped">
                            <header>
                                <div class="linha">
                                    <div class="col1 cursor-pointer" (click)="ordenar('quotation_id', false, true)">
                                        <span>{{ 'support.pedidos.pendentes.colunas.pedido' | translate}}
                                            @if (orderByFieldPendente == 'quotation_id') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSortPendente ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    <div class="col0-5 cursor-pointer" (click)="ordenar('status_id', false, true)">
                                        <span>{{ 'support.pedidos.pendentes.colunas.fase' | translate}}
                                            @if (orderByFieldPendente == 'status_id') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSortPendente ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    <div class="col2 cursor-pointer" (click)="ordenar('repairer_name', false, true)">
                                        <span>{{'support.pedidos.pendentes.colunas.oficina' | translate}}
                                            @if (orderByFieldPendente == 'repairer_name') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSortPendente ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    <div class="col2 cursor-pointer" (click)="ordenar('veiculo', false, true)">
                                        <span>{{'support.pedidos.pendentes.colunas.veiculo' | translate}}
                                            @if (orderByFieldPendente == 'veiculo') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSortPendente ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    <div class="col1-5 cursor-pointer" (click)="ordenar('fornecedor', false, true)">
                                        <span>{{'support.pedidos.pendentes.colunas.fornecedor' | translate}}
                                            @if (orderByFieldPendente == 'fornecedor') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSortPendente ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    <div class="col1 cursor-pointer" (click)="ordenar('vendedor', false, true)">
                                        <span>{{'support.pedidos.pendentes.colunas.vendedor' | translate}}
                                            @if (orderByFieldPendente == 'vendedor') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSortPendente ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    <div class="col2 cursor-pointer" (click)="ordenar('vendedor_fone_formatado', false, true)">
                                        <span>{{'support.pedidos.pendentes.colunas.vendedor-fone' | translate}}
                                            @if (orderByFieldPendente == 'vendedor_fone_formatado') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSortPendente ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    <div class="col2 cursor-pointer" (click)="ordenar('data_pedido', false, true)">
                                        <span>{{'support.pedidos.pendentes.colunas.data-compra' | translate}}
                                            @if (orderByFieldPendente == 'data_pedido') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSortPendente ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                </div>
                            </header>
                            <div class="corpo">
                                <ng-scrollbar>
                                    @for (item of pendentes; track item) {
                                        <div class="linha">
                                            <div class="col1 truncate">{{item.quotation_id}}</div>
                                            <div class="col0-5" [tooltip]="item.motivo">
                                                {{('geral.fase-cotacao.abreviacao.' + item.status_id) | translate}}
                                                @if (item.motivo) {
                                                    <b><i class="fa fa-info-circle"></i></b>
                                                }
                                            </div>
                                            <div class="col2 truncate">{{item.repairer_name}} ({{item.comprador}})</div>
                                            <div class="col2 truncate">{{item.veiculo}}</div>
                                            <div class="col1-5 truncate">{{item.supplier_name}}</div>
                                            <div class="col1 truncate">{{item.vendedor}}</div>
                                            <div class="col2 truncate">
                                                @if (item.seller_whatsapp) {
                                                    <i class="fa icons fa-whatsapp text-success cursor-pointer" (click)="whatsapp_enviar(item)"></i>
                                                }
                                                {{item.vendedor_fone_formatado}}
                                            </div>
                                            <div class="col2 truncate">{{item.data_pedido | date:'dd/MM/yyyy HH:mm' }}</div>
                                        </div>
                                    }
                                </ng-scrollbar>
                            </div>
                        </section>
                    }
                </ng-template>
            </li>
            <li [ngbNavItem]="2" [ngClass]="active === 2 ? 'active' : ''">
                <a ngbNavLink>
                    {{ 'support.pedidos.atrasados.titulo' | translate}}
                    @if (active === 2) {
                        <div class="float-end" style="cursor: pointer;" (click)="atualizar()">
                            <span href="javascript:void(0)"><i class="fa fa-refresh"></i></span>
                        </div>
                    }
                </a>
                <ng-template ngbNavContent>
                    @if (atrasados?.length <= 0) {
                        <div class="vazio">
                            {{'support.pedidos.vazio' | translate}}
                        </div>
                    } @else {
                        <section class="d-flex flex-wrap striped">
                            <header>
                                <div class="linha">
                                    <div class="col0-75 cursor-pointer" (click)="ordenar('quotation_id', false, false)">
                                        <span>{{'support.pedidos.atrasados.colunas.pedido' | translate}}
                                            @if (orderByFieldAtrasado == 'quotation_id') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSortAtrasado ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    <div class="col1-5 cursor-pointer" (click)="ordenar('oficina', false, false)">
                                        <span>{{'support.pedidos.atrasados.colunas.oficina' | translate}}
                                            @if (orderByFieldAtrasado == 'oficina') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSortAtrasado ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    <div class="col1 cursor-pointer" (click)="ordenar('comprador', false, false)">
                                        <span>{{'support.pedidos.atrasados.colunas.comprador' | translate}}
                                            @if (orderByFieldAtrasado == 'comprador') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSortAtrasado ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    <div class="col3 cursor-pointer" (click)="ordenar('veiculo', false, false)">
                                        <span>{{'support.pedidos.atrasados.colunas.veiculo' | translate}}
                                            @if (orderByFieldAtrasado == 'veiculo') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSortAtrasado ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    <div class="col1-5 cursor-pointer" (click)="ordenar('fornecedor', false, false)">
                                        <span>{{'support.pedidos.atrasados.colunas.fornecedor' | translate}}
                                            @if (orderByFieldAtrasado == 'fornecedor') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSortAtrasado ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    <div class="col1 cursor-pointer" (click)="ordenar('vendedor', false, false)">
                                        <span>{{'support.pedidos.atrasados.colunas.vendedor' | translate}}
                                            @if (orderByFieldAtrasado == 'vendedor') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSortAtrasado ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    <div class="col2 cursor-pointer" (click)="ordenar('fone_whats', false, false)">
                                        <span>{{'support.pedidos.atrasados.colunas.vendedor-fone' | translate}}
                                            @if (orderByFieldAtrasado == 'fone_whats') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSortAtrasado ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    <div class="col2 cursor-pointer" (click)="ordenar('data_pedido', false, false)">
                                        <span>{{'support.pedidos.atrasados.colunas.data-entrega' | translate}}
                                            @if (orderByFieldAtrasado == 'data_pedido') {
                                                <span>
                                                    <em class="fa fa-caret-{{reverseSortAtrasado ? 'down' : 'up'}}"></em>
                                                </span>
                                            }
                                        </span>
                                    </div>
                                </div>
                            </header>
                            <div class="corpo">
                                <ng-scrollbar>
                                    @for (item of atrasados; track item) {
                                        <div class="linha">
                                            <div class="col0-75 truncate">{{item.quotation_id}}</div>
                                            <div class="col1-5 truncate">{{item.repairer_name}}</div>
                                            <div class="col1 truncate">{{item.comprador}}</div>
                                            <div class="col3 truncate">{{item.veiculo}}</div>
                                            <div class="col1-5 truncate">{{item.supplier_name}}</div>
                                            <div class="col1 truncate">{{item.vendedor}}</div>
                                            <div class="col2 truncate">{{item.vendedor_fone_formatado}}</div>
                                            <div class="col2 truncate">{{item.data_pedido | date:'dd/MM/yyyy HH:mm'}}</div>
                                        </div>
                                    }
                                </ng-scrollbar>
                            </div>
                        </section>
                    }
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
    </div>
</div>

<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
