import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { BaseService } from 'app/_services/base.service';
import { SettingsService } from "app/_services/settings.service";

@Injectable({ providedIn: 'root' })
export class SupportVoltarCotacaoService extends BaseService {
    private _domain: string = '/support/maintenances';

    constructor(private config: SettingsService) {
        super();
    }

    public getQuotationsByLicensePlate(licensePlate: string): Observable<any> {
        return this.config.executeGet({ url: `${this._domain}/quotations?licensePlate=${licensePlate}` });
    }

    public setQuotationBack(quotationId: number, excluida: boolean): Observable<any> {
        return this.config.executePost({ url: `${this._domain}/quotations/${quotationId}?excluida=${excluida}`, body: {} });
    }
}
