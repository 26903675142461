import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MasterPageComponent } from '../general/master-page/master-page.component';
import { SupportAvaliacaoVendedoresComponent } from './avaliacao-vendedores/avaliacao-vendedores.component';
import { SupportCadastroGestorWhatsappComponent } from './cadastro-gestor-whatsapp/cadastro-gestor-whatsapp.component';
import { SupportCadastroNovidadesComponent } from './cadastro-novidades/cadastro-novidades.component';
import { SupportContestacoesComponent } from './contestacoes/contestacoes.component';
import { SupportCotacoesComponent } from './cotacoes/cotacoes.component';
import { SupportFaturamentoComponent } from './faturamento/faturamento.component';
import { SupportDiarioComponent } from './diario/diario.component';
import { SupportGerenciarVendedoresComponent } from './gerenciar-vendedores/gerenciar-vendedores.component';
import { SupportLimparTelaVendedorComponent } from './limpar-tela-vendedor/limpar-tela-vendedor.component';
import { SupportOficinaSemDocumentacaoComponent } from './oficina-sem-documentacao/oficina-sem-documentacao.component';
import { SupportPedidosComponent } from './pedidos/pedidos.component';
import { SupportUsuarioInternoComponent } from './usuario-interno/usuario-interno.component';
import { SupportVoltarCotacaoComponent } from './voltar-cotacao/voltar-cotacao.component';
import { SupportMultiloginComponent } from './multilogin/multilogin.component';
import { SupportGuard } from '../_services/_guards/support.guard';
import { SupportCadastroCnpjComponent } from './cadastro-cnpj/cadastro-cnpj.component';

const supportRouterConfig: Routes = [
    {
        path: '', component: MasterPageComponent,
        children: [
            { path: 'avaliacao-vendedores', component: SupportAvaliacaoVendedoresComponent },
            { path: 'cotacoes', component: SupportCotacoesComponent },
            { path: 'pedidos', component: SupportPedidosComponent },
            { path: 'diario', component: SupportDiarioComponent },
            { path: 'gerenciar-vendedores', component: SupportGerenciarVendedoresComponent },

            {
                path: 'manutencao',
                children: [
                    { path: 'cadastro-gestor-whatsapp', component: SupportCadastroGestorWhatsappComponent },
                    { path: 'cadastro-novidades', component: SupportCadastroNovidadesComponent },
                    { path: 'limpar-tela-vendedor', component: SupportLimparTelaVendedorComponent },
                    { path: 'oficina-sem-documentacao', component: SupportOficinaSemDocumentacaoComponent },
                    { path: 'usuario/interno', component: SupportUsuarioInternoComponent },
                    { path: 'usuario/interno/new', component: SupportUsuarioInternoComponent },
                    { path: 'usuario/interno/:id', component: SupportUsuarioInternoComponent },
                    { path: 'usuario/interno/associations/:id', component: SupportUsuarioInternoComponent },
                    { path: 'voltar-cotacao', component: SupportVoltarCotacaoComponent },
                    { path: 'multilogin', component: SupportMultiloginComponent, canDeactivate:[SupportGuard] },
                    { path: 'cadastro-cnpj-oficina', component: SupportCadastroCnpjComponent },
                    { path: 'cadastro-cnpj-oficina/:id', component: SupportCadastroCnpjComponent },
                ]
            },

            { path: 'contestacao', component: SupportContestacoesComponent },
            { path: 'faturamento', component: SupportFaturamentoComponent }
        ]
    }
];

@NgModule({
    imports:
        [
            RouterModule.forChild(supportRouterConfig)
        ],
    exports: [RouterModule]
})
export class SuporteRoutingModule { }

