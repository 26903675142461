import { Component, OnInit } from '@angular/core';

import { NgxUiLoaderService } from 'ngx-ui-loader';

import { NotificationService } from 'app/_services/notification.service';
import { SupportContestacoesService } from './contestacoes.service';

import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { Contestacao } from './_models/contestacao';
import { Globals } from 'app/_models/globals';
import { SupportContestacoesGetAllContestacoesResponseModel } from './_models/supportContestacoesGetAllContestacoesResponseModel';

declare const $: any;

@Component({ selector: 'app-contestacoes', templateUrl: './contestacoes.component.html', styleUrls: ['./contestacoes.component.scss'] })
export class SupportContestacoesComponent implements OnInit {
    contestacoes: Contestacao[];
    contestacoesSelecionadas: any;

    constructor(
        private notificationService: NotificationService,
        private contestacoesService: SupportContestacoesService,
        private globals: Globals,
        private ngxLoader: NgxUiLoaderService) { }

    ngOnInit(): void {
        this.contestacoes = [];
        this.contestacoesSelecionadas = [];

        this.carregarTela(0);
    }

    async aprovar() {
        if (!await this.notificationService.showConfirmationDialog('support.contestacoes.confirm'))
            return;

        this.aprovarExecutar(0);
    }

    aprovarExecutar(nAttempts: number) {
        this.ngxLoader.startLoader('loader-principal');

        let contestacoesAprovadas = this.contestacoes.filter(c => this.contestacoesSelecionadas.some(cs => cs === c.id));

        if (contestacoesAprovadas.length <= 0)
            return;

        this.contestacoesService.approve(contestacoesAprovadas).subscribe({
            next: () => {
                this.notificationService.showSuccessToastr(this.globals.translate('support.contestacoes.mensagens.sucesso.aprovar.corpo'));
                this.contestacoesSelecionadas = [];
                this.carregarTela(0);
            },
            error: error => {
                nAttempts = nAttempts || 1;
                console.log(error, nAttempts);

                if (nAttempts >= 5) {
                    this.ngxLoader.stopLoader('loader-principal');
                    this.notificationService.showErrorToastr(this.globals.translate('support.contestacoes.mensagens.erro.aprovar.corpo'));
                    return;
                }

                this.aprovarExecutar(++nAttempts);
            }
        });
    }

    carregarTela(nAttempts: number) {
        this.ngxLoader.startLoader('loader-principal');

        this.contestacoesService.getAll().subscribe({
                next: (response: ApiResponseModel<SupportContestacoesGetAllContestacoesResponseModel>) => {
                    this.contestacoes = response.result.contestacoes;
                    this.ngxLoader.stopLoader('loader-principal');
                },
                error: error => {
                    nAttempts = nAttempts || 1;
                    console.log(error, nAttempts);

                    if (nAttempts >= 5) {
                        this.ngxLoader.stopLoader('loader-principal');
                        this.notificationService.showErrorToastr(this.globals.translate('support.contestacoes.mensagens.erro.carregamento.corpo'));
                        return;
                    }

                    this.carregarTela(++nAttempts);
                }
            });
    }

    selecionar(contestacao, $event) {
        $event.stopPropagation();

        if ($($event.target).is(":checked"))
            this.contestacoesSelecionadas.push(contestacao.id);
        else
            this.contestacoesSelecionadas = this.contestacoesSelecionadas.filter(c => c != contestacao.id);
    }

    selecionarTodas($event) {
        $event.stopPropagation();

        let is_checked = $($event.target).is(":checked");

        this.contestacoes.forEach(contestacao => {
            if (is_checked)
                this.contestacoesSelecionadas.push(contestacao.id);
            else
                this.contestacoesSelecionadas = this.contestacoesSelecionadas.filter(c => c != contestacao.id);
        });

        $('.contestacao-item').prop("checked", is_checked);
    }


    get algumaContestacaoSelecionada() : boolean {
        return this.contestacoesSelecionadas.length > 0;
    }
}
