import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { BaseService } from 'app/_services/base.service';
import { SettingsService } from "app/_services/settings.service";

@Injectable({ providedIn: 'root' })
export class SupportLimparTelaVendedorService extends BaseService {
    private _domain: string = '/support/maintenances';

    constructor(private config: SettingsService) {
        super();
    }

    public clearQuotationsFromSellersDashboard(seller_id: number): Observable<any> {
        return this.config.executePost({ url: `${this._domain}/sellers/${seller_id}/clear-quotations` });
    }

    public getSellerClearPageData(seller_id: number): Observable<any> {
        return this.config.executeGet({ url: `${this._domain}/sellers/${seller_id}` });
    }
}
