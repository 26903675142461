import { Injectable } from "@angular/core";

import { BaseService } from 'app/_services/base.service';
import { SettingsService } from "app/_services/settings.service";
import { Observable } from "rxjs";
import { OficinaGestorWhatsapp } from "./_models/oficina-gestor-whatsapp";

@Injectable({ providedIn: 'root' })
export class SupportCadastroGestorWhatsappService extends BaseService {
    private _domain: string = '/support/managers-whatsapp';

    constructor(private config: SettingsService) {
        super();
    }

    public getRepairerList(): Observable<any> {
        return this.config.executeGet({ url: `${this._domain}` });
    }

    public removeRepairer(holding_id: number): Observable<any> {
        return this.config.executeDelete({ url: `${this._domain}/${holding_id}` });
    }

    public saveRepairer(repairer: OficinaGestorWhatsapp): Observable<any> {
        return this.config.executePost({ url: `${this._domain}`, body: repairer });
    }
}
