import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Editor, Toolbar } from 'ngx-editor';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { Globals } from 'app/_models/globals';
import { removeHTMLTags } from 'app/_utils/general-utils';
import { RolesIdsEnum } from 'app/_models/roles';
import { Update } from 'app/_models/update';

import { NotificationService } from 'app/_services/notification.service';
import { SupportCadastroNovidadesService } from '../../cadastro-novidades.service';

declare const $: any;

@Component({ selector: 'app-cadastro-novidades-create', templateUrl: './create.component.html', styleUrls: ['./create.component.scss'] })
export class SupportCadastroNovidadesCreateComponent implements OnInit, OnDestroy {
    calendarMaxDate: { year: number, month: number, day: number };
    calendarMinDate: { year: number, month: number, day: number };
    datePicked: { year: number, month: number, day: number };

    bugsEditor: Editor;
    bugsHtml: string = '';
    featuresEditor: Editor;
    featuresHtml: string = '';
    isEditMode: boolean;

    rolesSelected = {
        central: false,
        comprador: false,
        vendedor: false,
        dono: false,
        gestor: false
    }

    toolbar: Toolbar = [
        [{ heading: ['h3', 'h4'] }],
        ['text_color', 'background_color'],
        ['bold', 'italic', 'underline', 'strike'],
        ['code', 'blockquote'],
        ['link', 'image'],
        ['align_left', 'align_center', 'align_right', 'align_justify'],
        ['ordered_list', 'bullet_list'],
    ];

    removeHTMLTags: any = removeHTMLTags;

    @Output() closeUpdateCreation = new EventEmitter();
    @Input() selectedUpdate?: Update;

    constructor(
        private supportCadastroNovidadesService: SupportCadastroNovidadesService,
        private notificationService: NotificationService,
        private globals: Globals,
        private ngxLoader: NgxUiLoaderService
    ) {
        var date = new Date();

        this.datePicked = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
        this.calendarMinDate = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
        this.calendarMaxDate = { year: date.getFullYear(), month: date.getMonth() + 2, day: date.getDate() };
    }

    ngOnInit(): void {
      this.featuresEditor = new Editor();
      this.bugsEditor = new Editor();

      this.isEditMode = this.selectedUpdate != null;

      if (this.isEditMode) {
        this.selectedUpdate.release_date = new Date(this.selectedUpdate.release_date);
        this.datePicked = { year: this.selectedUpdate.release_date.getFullYear(), month: this.selectedUpdate.release_date.getMonth() + 1, day: this.selectedUpdate.release_date.getDate() }

        this.rolesSelected = {
            central: (this.selectedUpdate.role & RolesIdsEnum.Central) == RolesIdsEnum.Central,
            comprador: (this.selectedUpdate.role & RolesIdsEnum.Comprador) == RolesIdsEnum.Comprador,
            vendedor: (this.selectedUpdate.role & RolesIdsEnum.Vendedor) == RolesIdsEnum.Vendedor,
            dono: (this.selectedUpdate.role & RolesIdsEnum.DonoDaOficina) == RolesIdsEnum.DonoDaOficina,
            gestor: (this.selectedUpdate.role & RolesIdsEnum.GestorDePecas) == RolesIdsEnum.GestorDePecas
        };

        return;
      }

      this.selectedUpdate = {
        id: null,
        role: 0,
        bugs_info: '',
        features_info: '',
        title: '',
        release_date: new Date(this.datePicked.year, this.datePicked.month - 1, this.datePicked.day),
        is_active: true
      };
    }

    ngOnDestroy(): void {
      this.featuresEditor.destroy();
      this.bugsEditor.destroy();
    }

    close() {
        this.closeUpdateCreation.emit();
    }

    saveUpdate() {
        this.selectedUpdate.release_date = new Date(this.datePicked.year, this.datePicked.month - 1, this.datePicked.day)
        this.notificationService.clearToastr();
        let hasErrors = false;

        if (this.selectedUpdate.release_date.toString() == 'Invalid Date') {
            this.notificationService.showErrorToastr(this.globals.translate('support.manutencao.cadastro-novidades.criacao.mensagens.data-invalida.corpo'));
            hasErrors = true;
        }

        if (this.selectedUpdate.role == 0) {
            this.notificationService.showErrorToastr(this.globals.translate('support.manutencao.cadastro-novidades.criacao.mensagens.falta-publico.corpo'));
            hasErrors = true;
        }

        if (this.selectedUpdate.title == '') {
            this.notificationService.showErrorToastr(this.globals.translate('support.manutencao.cadastro-novidades.criacao.mensagens.falta-titulo.corpo'));
            hasErrors = true;
        }

        if (this.removeHTMLTags(this.selectedUpdate.features_info) == '' && this.removeHTMLTags(this.selectedUpdate.bugs_info) == '') {
            this.notificationService.showErrorToastr(this.globals.translate('support.manutencao.cadastro-novidades.criacao.mensagens.falta-conteudo.corpo'));
            hasErrors = true;
        }

        if (hasErrors)
            return;

        this.ngxLoader.startLoader('loader-principal')

        this.supportCadastroNovidadesService.save(this.selectedUpdate).subscribe({
            next: (response: ApiResponseModel<boolean>) => {
                if(response.success)
                    this.close();

                this.notificationService.showSuccessToastr(this.globals.translate('support.manutencao.cadastro-novidades.criacao.mensagens.sucesso.corpo'));
                this.ngxLoader.stopLoader('loader-principal')
            },
            error: () => {
                this.notificationService.showErrorToastr(this.globals.translate('support.manutencao.cadastro-novidades.criacao.mensagens.desconhecido.corpo'));
                this.ngxLoader.stopLoader('loader-principal');
            }
        })
    }

    selectPublic($event) {
        var input = $($event.target);

        if (input.is(":checked"))
            this.selectedUpdate.role += Number(input.val())
        else
            this.selectedUpdate.role -= Number(input.val())
    }
}
