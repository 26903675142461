import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from 'app/_services/base.service';
import { SettingsService } from 'app/_services/settings.service';


@Injectable({ providedIn: 'root' })
export class SupportDiarioService extends BaseService {
    private _domain: string = '/support/reports';

    constructor(private config: SettingsService) {
        super();
    }

    public getDailyReport(role: number): Observable<any> {
        return this.config.executeGet({ url: `${this._domain}/${role}/daily` });
    }
}
