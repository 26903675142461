<div class="adicionar-multiusuario" ngxUiLoaderBlurred [loaderId]="'loader-principal'">
    <app-header
        [hasBackButton]="false"
        [title]="'support.multilogin.titulo' | translate"
        [isLoading]="loading"
    >
        <select class="form-select form-select-sm search search-type">
            <option></option>
            <option value="fornecedor">{{ 'support.multilogin.tipos.fornecedor' | translate }}</option>
            <option value="oficina">{{ 'support.multilogin.tipos.oficina' | translate }}</option>
        </select>
        <select class="form-select form-select-sm search search-holding" [disabled]="holdingsList.length == 0">
            <option></option>
            @for (holding of holdingsList; track holding) {
                <option value="{{holding.id}}">{{holding.name}}</option>
            }
        </select>
        <select class="form-select form-select-sm search search-company" [disabled]="companiesList.length == 0">
            <option></option>
            @for (company of companiesList; track company) {
                <option value="{{company.id}}">{{company.name}}</option>
            }
        </select>
        <select class="form-select form-select-sm search search-user" [disabled]="usersList.length == 0">
            <option value=""></option>
            @for (user of usersList; track user) {
                <option value="{{user.id}}" [disabled]="isDisabled(user)">{{user.name}}</option>
            }
        </select>
        <button class="btn btn-primary" [disabled]="!isUserSelected" (click)="addUserToMultilogin()">{{ 'support.multilogin.selecionar' | translate }}</button>
    </app-header>
    <div class="selected-users-container">
        <div class="card card-default selected-users text-center">
            <app-support-gridview [config]="config" (onRemove)="removeUserFromList($event)"></app-support-gridview>

            <div class="buttons-container">
                <button class="btn btn-primary" [disabled]="isSaveButtonDisabled || multiloginList.length <= 1" (click)="saveMultilogin()">
                    {{ 'support.multilogin.botao.salvar' | translate }}
                </button>
                <button class="btn btn-outline-danger" [disabled]="!isUserWithMultiloginSelected" (click)="deleteMultilogin()">
                    {{ 'support.multilogin.botao.apagar' | translate }}
                </button>
                <button class="btn btn-light" [disabled]="multiloginList.length < 1" (click)="cleanSelection()">
                    {{ 'support.multilogin.botao.limpar' | translate }}
                </button>
            </div>
        </div>
        <ngx-ui-loader [loaderId]="'loader-users-selected'"></ngx-ui-loader>
    </div>
</div>
<ngx-ui-loader [loaderId]="'loader-principal'"></ngx-ui-loader>
