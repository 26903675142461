import { Component, AfterViewInit, ElementRef, EventEmitter, Input, OnInit, Output, ViewChildren, ViewChild } from '@angular/core';
import { UntypedFormBuilder, FormControlName, UntypedFormGroup, Validators } from '@angular/forms';
;
import { NgxBrazilMASKS, NgxBrazilValidators } from 'ngx-brazil'
import { fromEvent, merge, Observable } from 'rxjs';

import { NgxUiLoaderService } from 'ngx-ui-loader';

import { NotificationService } from 'app/_services/notification.service';
import { SupportUsuarioInternoService } from '../../usuario-interno.service';

import { DisplayMessage, GenericValidator, ValidationMessages } from 'app/_utils/generic-form-validation';
import { Globals } from 'app/_models/globals';
import { NgScrollbar } from 'ngx-scrollbar';
import { InternalUser } from '../../_models/internalUser';
import { RolesIdsEnum } from 'app/_models/roles';
import { Repairer } from '../../_models/repairer';
import { ApiResponseModel } from 'app/_models/apiResponseModel';
import { ValidationErrors } from '../../_models/validationErrors';
import { EMAIL_REGEX } from 'app/_utils/validators/regex.validators';

declare const $: any;
declare const capitalizeTheFirstLetterOfEachWord: any;
declare const isEmpty: any;
declare const getOnlyNumbers: any;
declare const sort_by: any;

@Component({ selector: 'app-usuario-interno-edicao', templateUrl: './usuario-interno-edicao.component.html', styleUrls: ['./usuario-interno-edicao.component.scss'] })
export class SupportUsuarioInternoEdicaoComponent implements OnInit, AfterViewInit {
    @Output() onAlterarTela = new EventEmitter<any>();
    @Output() onBackToList = new EventEmitter<any>();
    @Input() repairers: Repairer[];
    @Input() user: InternalUser;
    @Input() isEditProfile: boolean;
    @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
    @ViewChild('scrollable') scrollable: NgScrollbar;

    cadastroForm: UntypedFormGroup;
    repairersFiltered: Repairer[];
    displayMessage: DisplayMessage = {};
    errors: any[] = [];
    genericValidator: GenericValidator;
    validationMessages: ValidationMessages;
    roles: typeof RolesIdsEnum = RolesIdsEnum;
    showPassword: boolean;
    showAssociations: boolean = false;
    isSaveButtonDisabled: boolean = false;
    public MASKS: any = NgxBrazilMASKS;
    private _searchString: string = '';

    constructor(
        private notificationService: NotificationService,
        private usuarioInternoService: SupportUsuarioInternoService,
        private globals: Globals,
        private fb: UntypedFormBuilder,
        private ngxLoader: NgxUiLoaderService) {
        this.validationMessages = {
            name: { required: this.globals.translate('support.manutencao.usuario.interno.edicao.campos.nome.obrigatorio'), maxLength: this.globals.translate('support.manutencao.usuario.interno.edicao.campos.nome.tamanho'), pattern: this.globals.translate('support.manutencao.usuario.interno.edicao.campos.nome.somente-letras') },
            email: { required: this.globals.translate('support.manutencao.usuario.interno.edicao.campos.email.obrigatorio'), pattern: this.globals.translate('support.manutencao.usuario.interno.edicao.campos.email.formato-invalido'), maxLength: this.globals.translate('support.manutencao.usuario.interno.edicao.campos.email.tamanho') },
            password: { required: this.globals.translate('support.manutencao.usuario.interno.edicao.campos.senha.obrigatorio'), maxLength: this.globals.translate('support.manutencao.usuario.interno.edicao.campos.senha.tamanho') },
            username: { required: this.globals.translate('support.manutencao.usuario.interno.edicao.campos.login.obrigatorio'), maxLength: this.globals.translate('support.manutencao.usuario.interno.edicao.campos.login.tamanho'), pattern: this.globals.translate('support.manutencao.usuario.interno.edicao.campos.login.somente-letras') },
            whatsApp: { required: this.globals.translate('support.manutencao.usuario.interno.edicao.campos.whatsapp.obrigatorio'), celular: this.globals.translate('support.manutencao.usuario.interno.edicao.campos.whatsapp.formato-invalido') }
        };

        this.genericValidator = new GenericValidator(this.validationMessages);
    }

    ngOnInit(): void {
        this.showPassword = false;
    
        this.cadastroForm = this.fb.group({
            name: ['', [Validators.required, Validators.maxLength(50), Validators.pattern('^[a-zA-ZçÇáãéíóôÁÃÉÍÓÔ ]*$')]],
            email: ['', [Validators.required, Validators.pattern(EMAIL_REGEX), Validators.maxLength(50)]],
            password: ['', [Validators.required, Validators.maxLength(20)]],
            username: ['', [Validators.required, Validators.maxLength(20), Validators.pattern('^[a-zA-Z0-9._]*$')]],
            whatsApp: ['', [Validators.required, NgxBrazilValidators.cellphone]],
            role: ['', Validators.required],
            isActive: [''],
        });
    
        if (!isEmpty(this.user)) {
            this.cadastroForm.patchValue({
                id: this.user.userId,
                name: this.user.name,
                email: this.user.email,
                username: this.user.username,
                password: this.user.password,
                whatsApp: this.user.whatsApp,
                role: this.user.role,
                isActive: this.user.isActive
            });
    
            if (this.repairers !== undefined && this.repairers !== null) {
                this.repairers.forEach(repairer => {
                    let index = this.user.buyerIds?.findIndex(x => x == repairer.buyerId);
                    repairer.selected = index > -1;
                });

                this.repairersFiltered = this.repairers.map(r => ({ ...r }));
            }
        }
    
        this.showAssociations = this.isEditProfile && this.user.role == RolesIdsEnum.Central;
    }

    ngAfterViewInit() {
        this.validate();
    }

    action_updateUser(user: InternalUser) {
        this.onAlterarTela.emit(user);
    }
    
    back() {
        this.onBackToList.emit();
    }

    disableSaveButton() {
        if (!this.repairers.some(r => r.selected))
            return true;

        return false;
    }

    filterRepairers() {
        if (isEmpty(this.repairers)) return [];
    
        const term: string = this._searchString.toLowerCase();
    
        if (!isEmpty(term)) {
            this.repairersFiltered = this.repairers.filter(({ buyerName, repairerName }) =>
                buyerName.toLowerCase().includes(term) || repairerName.toLowerCase().includes(term)
            );
        } else {
            this.repairersFiltered = this.repairers;
        }

        this.updateSaveButtonState();
    }
    
    get searchTerm(): string {
        return this._searchString;
    }
    
    onInputChange(event: Event) {
        const inputElement = event.target as HTMLInputElement;
        this.searchTerm = inputElement.value;
    }
    
    save() {
        if (this.cadastroForm.valid) {
            let user: InternalUser = Object.assign({}, this.user, this.cadastroForm.value);
    
            if (isEmpty(user)) {
                this.notificationService.showErrorToastr(this.globals.translate('support.manutencao.usuario.interno.edicao.mensagens.erro.salvar.corpo'));
                this.ngxLoader.stopLoader('loader-principal');
                return;
            }
    
            user.name = capitalizeTheFirstLetterOfEachWord(user.name);
            user.email = user.email.toLowerCase();
            user.username = user.username.toLowerCase();
            user.whatsApp = getOnlyNumbers(user.whatsApp);
            user.role = Number(user.role);
    
            this.ngxLoader.startLoader('loader-principal');
    
            if (this.isEditProfile) {
                let repairersToSave = this.repairers.filter(r => r.selected).map(r => r.buyerId);
                this.updateRepairers(user, repairersToSave);
            } else if (user.userId > 0) {
                this.updateInternalUser(user);
            } else {
                this.saveInternalUser(user);
            }
        }
    }
    
    saveInternalUser(user: InternalUser) {
        this.usuarioInternoService.create(user).subscribe({
            next: async (response: ApiResponseModel<InternalUser>) => {
                user.userId = response.result.userId;
                user.version = response.result.version;
                user.roleDescription = response.result.roleDescription;
                user.isActive = true;
    
                this.notificationService.showSuccessToastr(this.globals.translate('support.manutencao.usuario.interno.edicao.mensagens.sucesso.salvar.corpo'));
                this.ngxLoader.stopLoader('loader-principal');
                this.action_updateUser(user);
            },
            error: (errorResponse) => {
                this.ngxLoader.stopLoader('loader-principal');

                if (errorResponse.status === 409 && errorResponse.errorCodes.includes(ValidationErrors.UsernameAlreadyExists)) {
                    this.notificationService.showErrorToastr(this.globals.translate('support.manutencao.usuario.interno.edicao.mensagens.erro.salvar-mesmo-login.titulo'));
                } else {
                    this.notificationService.showErrorToastr(this.globals.translate('support.manutencao.usuario.interno.edicao.mensagens.erro.salvar.corpo'));
                }

                return;
            }
        });
    }
    
    scrollToPerfilCentral() {
        $('.corpo .ng-scroll-viewport').scrollTop(0);
    }
    
    selectRepairer(buyerId: number, $event: any) {
        let isChecked = $($event.target).is(":checked");

        this.repairers.find(x => x.buyerId == buyerId).selected = isChecked;    
        this.cadastroForm.markAsDirty();
        this.updateSaveButtonState();
    }
    
    selectAllRepairers($event: any) {
        let isChecked = $($event.target).is(":checked");
        this.repairers.map(c => c.selected = isChecked);
        this.repairersFiltered.map(c => c.selected = isChecked);
        this.updateSaveButtonState();
    }
    
    set searchTerm(value: string) {
        this._searchString = value;
        this.filterRepairers();
        this.sortHoldings();
    }
    
    sortHoldings() {
        if (isEmpty(this.repairersFiltered)) return [];
    
        this.repairersFiltered.sort(sort_by([{ name: 'oficina' }, { name: "name" }]));
    }
    
    updateInternalUser(user: InternalUser) {
        this.usuarioInternoService.update(user).subscribe({
            next: async (response: ApiResponseModel<InternalUser>) => {
                user.version = response.result.version;
                user.roleDescription = response.result.roleDescription;
    
                this.notificationService.showSuccessToastr(this.globals.translate('support.manutencao.usuario.interno.edicao.mensagens.sucesso.salvar.corpo'));
                this.ngxLoader.stopLoader('loader-principal');
                this.action_updateUser(user);
            },
            error: () => {
                this.notificationService.showErrorToastr(this.globals.translate('support.manutencao.usuario.interno.edicao.mensagens.erro.salvar.corpo'));
                this.ngxLoader.stopLoader('loader-principal');
                return;
            }
        });
    }
    
    updateRepairers(user: InternalUser, repairers: number[]) {
        this.usuarioInternoService.updateRepairers(user.userId, repairers).subscribe({
            next: async (response: ApiResponseModel<InternalUser>) => {
                user.buyerIds = repairers;
                user.version = response.result.version;                
                this.notificationService.showSuccessToastr(this.globals.translate('support.manutencao.usuario.interno.edicao.mensagens.sucesso.salvar.corpo'));
                this.ngxLoader.stopLoader('loader-principal');
                this.action_updateUser(user);
            },
            error: () => {
                this.notificationService.showErrorToastr(this.globals.translate('support.manutencao.usuario.interno.edicao.mensagens.erro.salvar.corpo'));
                this.ngxLoader.stopLoader('loader-principal');
                return;
            }
        });
    }

    updateSaveButtonState() {
        this.isSaveButtonDisabled = this.disableSaveButton();
    }
    
    validate(): void {
        let controlBlur: Observable<any>[] = this.formInputElements.map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));
        merge(...controlBlur).subscribe(() => { this.displayMessage = this.genericValidator.processarMensagens(this.cadastroForm); });
   }    
}
